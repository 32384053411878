import {createSelector} from 'reselect';
import {
    selectIsInProgressStatus,
    selectAsyncActionData,
    selectActionData,
} from 'core/createReducer';
import {getStateByContainerId} from 'core/selectors';
import {
    containerId,
    fetchAgentList,
    setAgentCurrentPage,
    setAgentPageInfo,
    setAgentSearchValue,
    setAgentTotalItemsCount,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsLoading = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchAgentList.type);

export const selectAgentData = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentList.type);

export const selectAgentList = createSelector(selectAgentData, agents => {
    if (!agents) {
        return [];
    }

    return agents.map(agent => ({
        id: agent.id,
        firstName: agent.firstName,
        surname: agent.surname,
        email: agent.email,
        isActive: agent.isActive,
        isInformationMissing: agent.informationIsMissing,
    }));
});

export const selectAgentCurrentPage = state =>
    selectActionData(getCurrentState(state), setAgentCurrentPage.type);

export const selectAgentSearchValue = state =>
    selectActionData(getCurrentState(state), setAgentSearchValue.type);

export const selectAgentTotalPages = state =>
    selectActionData(getCurrentState(state), setAgentPageInfo.type, 'totalPages');

export const selectAgentTotalItemsCount = state =>
    selectActionData(getCurrentState(state), setAgentPageInfo.type, 'totalElements');

export const selectAgentInitialTotalItemsCount = state =>
    selectActionData(getCurrentState(state), setAgentTotalItemsCount.type);

export const makeSelectIsSearchPerformed = () =>
    createSelector(
        [selectAgentTotalItemsCount, selectAgentInitialTotalItemsCount],
        (totalItemsCount, initialTotalItemsCount) => totalItemsCount !== initialTotalItemsCount
    );

export const makeSelectShowSearchField = () =>
    createSelector(selectAgentInitialTotalItemsCount, totalItemsCount => totalItemsCount !== 0);

export const makeSelectAgentShowSearchResults = () =>
    createSelector(
        [selectIsLoading, makeSelectIsSearchPerformed()],
        (isLoading, isSearchPerformed) => !isLoading && isSearchPerformed
    );

export const makeSelectAgentShowTableHeader = () =>
    createSelector(
        [selectIsLoading, selectAgentList, makeSelectIsSearchPerformed()],
        (isLoading, data, isSearchPerformed) => isLoading || data.length > 0 || isSearchPerformed
    );

export const selectShowCrossButton = createSelector(
    [selectIsLoading, selectAgentSearchValue],
    (isLoading, searchValue) => !isLoading && !!searchValue
);
