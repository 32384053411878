import {connectWithIntl} from 'core/services/connectWithIntl';
import {selectActiveAgentError, selectAgentIsActive} from 'features/Agent/common/selectors';
import {GroupEntityStatusPanel} from 'components/common/GroupEntityStatus/GroupEntityStatusPanel';

const mapStateToProps = state => ({
        isActive: selectAgentIsActive(state),
        activeAgentError: selectActiveAgentError(state),
    });

export const AgentEditStatus = connectWithIntl(mapStateToProps)(GroupEntityStatusPanel);
