import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {noop} from 'core/services/utils';
import 'react-table/react-table.css';
import {DEFAULT_TABLE_SIZE} from 'core/constants';
import {OverflowTooltip} from 'components/common/OverflowTooltip';

import './styles.scss';

export class TableComponent extends React.Component {
    static propTypes = {
        data: PropTypes.array,
        columns: PropTypes.array,
        page: PropTypes.number,
        totalPages: PropTypes.number,
        loading: PropTypes.bool,
        noDataComponent: PropTypes.func,
        fetchDataHandler: PropTypes.func,
        onPageChange: PropTypes.func,
        showPagination: PropTypes.bool,
        hideOnEmptyData: PropTypes.bool,
        defaultPageSize: PropTypes.number,
        onRowClick: PropTypes.func,
        shouldShowColumns: PropTypes.bool,
    };

    static defaultProps = {
        data: [],
        columns: [],
        page: null,
        totalPages: null,
        loading: false,
        noDataComponent: null,
        fetchDataHandler: null,
        onPageChange: null,
        showPagination: true,
        hideOnEmptyData: true,
        onRowClick: noop,
        defaultPageSize: DEFAULT_TABLE_SIZE,
        shouldShowColumns: true,
    };

    render() {
        const {
            data,
            page,
            totalPages,
            loading,
            noDataComponent,
            showPagination = true,
            hideOnEmptyData = true,
            defaultPageSize,
        } = this.props;

        const shouldShowPagination = !loading && data.length > 0;

        return (
            <ReactTable
                data={data}
                columns={this.resolveColumns()}
                loading={loading}
                page={page}
                defaultPageSize={defaultPageSize}
                showPagination={showPagination && shouldShowPagination}
                showPageSizeOptions={false}
                minRows={data.length}
                pages={totalPages}
                resizable={false}
                sortable={false}
                className="-highlight"
                getTdProps={this.handleRowClick}
                manual
                hideOnEmptyData={hideOnEmptyData}
                NoDataComponent={noDataComponent}
                onPageChange={this.handlePageChange}
                loadingText={<div className="loader" />}
                previousText={<FormattedMessage id="table.previousText" />}
                nextText={<FormattedMessage id="table.nextText" />}
                pageText={<FormattedMessage id="table.pageText" />}
                ofText={<FormattedMessage id="table.ofText" />}
            />
        );
    }

    handleRowClick = (state, {original: {id}}) => ({
        onClick: () => {
            const {onRowClick} = this.props;
            onRowClick(id);
        },
    });

    handlePageChange = pageIndex => {
        const {onPageChange, page} = this.props;
        if (Number.isInteger(pageIndex)) {
            return onPageChange(pageIndex);
        }
        onPageChange(page);
    };

    resolveColumns = () => {
        if (!this.props.shouldShowColumns) {
            return [];
        }

        const {columns} = this.props;

        return columns.map(column => {
            if (column.Cell) {
                return column;
            }

            return {...column, Cell: ({value}) => <OverflowTooltip>{value}</OverflowTooltip>};
        });
    };
}
