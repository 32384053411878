import get from 'lodash/get';
import {createSelector} from 'reselect';
import {
    selectIsInProgressStatus,
    selectAsyncActionData,
    selectActionData,
} from 'core/createReducer';
import {getStateByContainerId} from 'core/selectors';
import {
    containerId,
    fetchBrokerManagersList,
    setBrokerManagersCurrentPage,
    setBrokerManagersPageInfo,
    setBrokerManagersSearchValue,
    setBrokerManagersTotalItemsCount,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsLoading = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchBrokerManagersList.type);

export const selectBrokerManagersData = state =>
    selectAsyncActionData(getCurrentState(state), fetchBrokerManagersList.type);

export const selectBrokerManagersList = createSelector(selectBrokerManagersData, brokerManagers => {
    if (!brokerManagers) {
        return [];
    }

    return brokerManagers.map(brokerManager => ({
        id: brokerManager.id,
        enterpriseNumber: get(brokerManager, 'intermed.enterpriseNumber'),
        companyName: get(brokerManager, 'intermed.name'),
        firstName: brokerManager.firstName,
        surname: brokerManager.surname,
        email: brokerManager.email,
        isActive: brokerManager.isActive,
    }));
});

export const selectBrokerManagersCurrentPage = state =>
    selectActionData(getCurrentState(state), setBrokerManagersCurrentPage.type);

export const selectBrokerManagersSearchValue = state =>
    selectActionData(getCurrentState(state), setBrokerManagersSearchValue.type);

export const selectBrokerManagersTotalPages = state =>
    selectActionData(getCurrentState(state), setBrokerManagersPageInfo.type, 'totalPages');

export const selectBrokerManagersTotalItemsCount = state =>
    selectActionData(getCurrentState(state), setBrokerManagersPageInfo.type, 'totalElements');

export const selectBrokerManagersInitialTotalItemsCount = state =>
    selectActionData(getCurrentState(state), setBrokerManagersTotalItemsCount.type);

export const makeSelectIsSearchPerformed = () =>
    createSelector(
        [selectBrokerManagersTotalItemsCount, selectBrokerManagersInitialTotalItemsCount],
        (totalItemsCount, initialTotalItemsCount) => totalItemsCount !== initialTotalItemsCount
    );

export const makeSelectShowSearchField = () =>
    createSelector(
        selectBrokerManagersInitialTotalItemsCount,
        totalItemsCount => totalItemsCount !== 0
    );

export const makeSelectBrokerManagersShowSearchResults = () =>
    createSelector(
        [selectIsLoading, makeSelectIsSearchPerformed()],
        (isLoading, isSearchPerformed) => !isLoading && isSearchPerformed
    );

export const makeSelectBrokerManagersShowTableHeader = () =>
    createSelector(
        [selectIsLoading, selectBrokerManagersList, makeSelectIsSearchPerformed()],
        (isLoading, data, isSearchPerformed) => isLoading || data.length > 0 || isSearchPerformed
    );

export const selectShowCrossButton = createSelector(
    [selectIsLoading, selectBrokerManagersSearchValue],
    (isLoading, searchValue) => !isLoading && !!searchValue
);
