import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import {GroupEntityStatus} from '../../../GroupEntityStatus';
import './styles.scss';

export const defaultFieldWidthIndex = 4;
export const PageContentDetailsFormStatusItem = ({titleId, isActive, widthIndex}) => (
    <Row>
        <Col
            xs={widthIndex || defaultFieldWidthIndex}
            className="page-content-details-form-status-item"
        >
            <FormattedMessage id={titleId} />
        </Col>
        <Col xs={6}>
            <GroupEntityStatus
                className="page-content-details-form-status-item-value"
                isActive={isActive}
            />
        </Col>
    </Row>
);

PageContentDetailsFormStatusItem.propTypes = {
    titleId: PropTypes.string,
    isActive: PropTypes.bool,
    widthIndex: PropTypes.number,
};
