import {LocaleService} from 'core/services/localeService';
import {createReducer} from 'core/createReducer';
import {Action, CaughtAsyncAction} from 'core/actionHelpers';

export const containerId = 'App';

export const setLocale = new Action(`${containerId}/SET_LOCALE`);
export const updateLocale = new Action(`${containerId}/SET_LOCALE_INTERNAL`, LocaleService.locale);
export const fetchUserInformation = new CaughtAsyncAction(`${containerId}/FETCH_USER_INFORMATION`);

export const appReducer = createReducer([updateLocale, fetchUserInformation]);
