import React from 'react';
import upperFirst from 'lodash/upperFirst';
import isFunction from 'lodash/isFunction';

export const withEventOutsideCreator = eventName => WrappedComponent => {
    function getDisplayName() {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    return class extends React.Component {
        displayName() {
            const eventNameWithUpperFirst = upperFirst(eventName);
            return `with${eventNameWithUpperFirst}Outside(${getDisplayName()})`;
        }

        componentDidMount() {
            document.addEventListener(eventName, this._handleEventOutside, true);
        }

        componentWillUnmount() {
            document.removeEventListener(eventName, this._handleEventOutside, true);
        }

        render() {
            return <WrappedComponent {...this._getPropsToWrappedComponent()} />;
        }

        _getPropsToWrappedComponent() {
            const eventNameWithUpperFirst = upperFirst(eventName);
            const setOnEventOutsidePropName = `setOn${eventNameWithUpperFirst}Outside`;
            const setDomNodeOnEventOutsidePropName = `setDomNodeOn${eventNameWithUpperFirst}Outside`;

            return {
                ...this.props,
                [setOnEventOutsidePropName]: this._setOnEventOutside,
                [setDomNodeOnEventOutsidePropName]: this._setDomNodeOnEventOutside,
            };
        }

        _setOnEventOutside = handler => {
            this._handlerOfWrappedComponent = handler;
        };

        _setDomNodeOnEventOutside = domNode => {
            this._wrappedDomNode = domNode;
        };

        _handleEventOutside = event => {
            if (!this._wrappedDomNode) {
                return;
            }

            const isFocusedWrappedDomNode = this._wrappedDomNode.contains(event.target);
            const hasEventHandlerOutside = isFunction(this._handlerOfWrappedComponent);
            const shouldCallOnEventHandler = !isFocusedWrappedDomNode && hasEventHandlerOutside;

            if (!shouldCallOnEventHandler) {
                return;
            }

            this._handlerOfWrappedComponent(event);
        };
    };
};

export const withClickOutside = withEventOutsideCreator('click');
export const withFocusinOutside = withEventOutsideCreator('focusin');
