import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';

import {optionShape} from 'core/propTypes';

export const WithMappedDropdownOptions = WrappedComponent => {
    function getDisplayName() {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    const resultComponent = class extends React.Component {
        static propTypes = {
            intl: intlShape,
            options: PropTypes.arrayOf(optionShape),
        };

        static defaultProps = {
            options: [],
        };

        state = {
            options: [],
        };

        static getDerivedStateFromProps(props, state) {
            if (props.options === state.options) {
                return null;
            }

            const updatedOptions = props.options.map(item => ({
                value: item.value,
                label: props.intl.formatMessage({id: item.label}),
            }));

            return {
                options: updatedOptions,
            };
        }

        displayName() {
            return `WithMappedDropdownOptions(${getDisplayName()})`;
        }

        render() {
            const {options} = this.state;
            return <WrappedComponent {...this.props} options={options} />;
        }
    };

    return injectIntl(resultComponent);
};
