import React from 'react';
import {FormattedMessage} from 'react-intl';

import {TableComponent} from 'components/common/Table/TableComponent';
import {TableRoleCell} from 'components/common/Table/TableRoleCell';
import {TableProducerAccountCell} from 'components/common/Table/TableProducerAccountCell';
import {connectWithIntl} from 'core/services/connectWithIntl';

import {selectProducerAccounts} from '../../selectors';
import {BrokerManagerListNoResultData} from '../../../../BrokerManager/BrokerManagerList/components/BrokerManagerListTable/BrokerManagerListNoResultData';

const columns = [
    {
        Header: <FormattedMessage id={'agentDetails.table.producerAccounts'} />,
        accessor: row => <TableProducerAccountCell producerAccount={row} />,
        id: 'producerAccounts',
    },
    {
        Header: <FormattedMessage id={'agentDetails.table.role'} />,
        accessor: 'roles',
        Cell: row => <TableRoleCell roles={row.value} />,
    },
];

const mapStateToProps = () => {
    return state => ({
        data: selectProducerAccounts(state),
        columns,
        shouldShowColumns: true,
        showPagination: false,
        noDataComponent: BrokerManagerListNoResultData,
    });
};

export const AgentDetailsTable = connectWithIntl(mapStateToProps)(TableComponent);
