import React from 'react';
import {connectWithIntl} from 'core/services/connectWithIntl';

import {TableNoResultComponent} from 'components/common/Table/TableNoResultComponent';
import {history} from 'core/services/history';

import {makeSelectIsSearchPerformed, selectIsLoading} from '../../../selectors';

export const BrokerManagerListNoResultDataComponent = ({loading, isSearchPerformed}) => (
    <TableNoResultComponent
        messageId="brokerManagerList.table.noResultComponent.messageText"
        linkMessage="brokerManagerList.table.noResultComponent.linkText"
        handleLinkClick={() => history.goToBrokerManagersCreate()}
        loading={loading}
        isSearchPerformed={isSearchPerformed}
    />
);

const mapStateToProps = () => {
    const selectIsSearchPerformed = makeSelectIsSearchPerformed();

    return state => ({
        loading: selectIsLoading(state),
        isSearchPerformed: selectIsSearchPerformed(state),
    });
};

export const BrokerManagerListNoResultData = connectWithIntl(mapStateToProps)(
    BrokerManagerListNoResultDataComponent
);
