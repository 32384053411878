import moment from 'moment';
import {createSelector} from 'reselect';
import {containerId, setGeneralError} from './reducer';
import {getStateByContainerId} from 'core/selectors';
import {selectActionData} from 'core/createReducer';

export const DATE_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm:SS Z';
export const getCurrentState = getStateByContainerId(containerId);
export const selectServerError = state =>
    selectActionData(getCurrentState(state), setGeneralError.type);

export const selectTransformServerError = createSelector(selectServerError, serverErrors => {
    if (!serverErrors) {
        return null;
    }

    const {status, errors, userTime, userAgent} = serverErrors;

    return {
        status,
        errors,
        userAgent,
        userTime: moment(userTime).format(DATE_FORMAT_WITH_TIME),
    };
});
