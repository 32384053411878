import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/lib/Col';

import './styles.scss';
import classNames from 'classnames';

export const PageControls = ({children, title, prefix, postfix, className}) => {
    return (
        <div className={classNames('page-wrapper-controls', className)}>
            <Col xs={6} className="page-wrapper-controls-title">
                {prefix}
                {title}
                {postfix}
            </Col>
            <Col xs={6} className="page-wrapper-controls-buttons">
                {children}
            </Col>
        </div>
    );
};

PageControls.propTypes = {
    children: PropTypes.node,
    prefix: PropTypes.node,
    postfix: PropTypes.node,
    title: PropTypes.node,
    className: PropTypes.string,
};
