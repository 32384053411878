import {getStateByContainerId} from 'core/selectors';
import {selectIsInProgressStatus, selectAsyncActionData} from 'core/createReducer';
import {containerId, fetchAgent, sendInvitation} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsLoading = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchAgent.type);

export const selectIsSendingInvitation = state =>
    selectIsInProgressStatus(getCurrentState(state), sendInvitation.type);

export const selectAgentData = state => {
    const agent = selectAsyncActionData(getCurrentState(state), fetchAgent.type);
    if (!agent) {
        return {};
    }
    return {
        firstName: agent.firstName,
        surname: agent.surname,
        email: agent.email,
        isActive: agent.isActive,
        mobileNumber: agent.mobileNumber,
        dateOfBirth: agent.dateOfBirth,
        contactNumber: agent.contactNumber,
        contactLanguage: agent.contactLanguage,
        isAlreadyExistsInB2c: agent.isAlreadyExistsInB2c,
    };
};

export const selectAgentId = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgent.type, 'id');

export const selectProducerAccounts = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgent.type, 'producerAccounts', []);
