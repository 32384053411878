import React from 'react';
import {Router} from 'react-router-dom';
import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from 'react-intl';
import {ToastContainer, Zoom} from 'react-toastify';

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import nl from 'react-intl/locale-data/nl';

import {selectLocale, selectIsUserInformationLoaded, selectDocumentTitle} from './selectors';

import messages from 'translations/messages';
import {ApplicationHeader} from 'features/ApplicationHeader';
import {flattenMessages} from 'core/services/utils';
import {routes} from 'features/routes';
import {ErrorBoundary} from 'features/ErrorBoundary';
import {history} from 'core/services/history';
import {fetchUserInformation} from './reducer';
import {authService} from 'core/services/auth';

addLocaleData([...en, ...fr, ...nl]);

export class AppComponent extends React.PureComponent {
    componentDidMount() {
        document.title = this.props.documentTitle;
        authService.initialize();
        if (authService.isAuthenticated()) {
            this.props.fetchUserInformation();
            return;
        }
        authService.signIn();
    }

    render() {
        const {locale, isLoaded} = this.props;

        if (!isLoaded) {
            return null;
        }

        return (
            <IntlProvider
                textComponent={React.Fragment}
                locale={locale}
                messages={flattenMessages({
                    ...messages[locale],
                })}
            >
                <Router history={history.browserHistory}>
                    <div className="App">
                        <ToastContainer
                            className="toast-container"
                            position="top-center"
                            autoClose={false}
                            newestOnTop
                            closeOnClick={false}
                            transition={Zoom}
                            draggable
                            draggablePercent={40}
                            hideProgressBar
                            pauseOnHover={false}
                        />
                        <ApplicationHeader />
                        <ErrorBoundary>
                            <main>{routes}</main>
                        </ErrorBoundary>
                    </div>
                </Router>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    locale: selectLocale(state),
    documentTitle: selectDocumentTitle(state),
    isLoaded: selectIsUserInformationLoaded(state),
});

export const App = connect(mapStateToProps, {
    fetchUserInformation: fetchUserInformation.request,
})(AppComponent);
