import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';

import {DropdownOption} from '../DropdownOption';

export const DropdownOptionWrapperComponent = ({children, intl, loading, notFound}) => {
    if (loading) {
        return <DropdownOption disabled label={intl.formatMessage({id: 'misc.loading'})} />;
    }

    if (notFound) {
        return <DropdownOption disabled label={intl.formatMessage({id: 'notFound'})} />;
    }

    return children;
};

DropdownOptionWrapperComponent.propTypes = {
    children: PropTypes.node,

    intl: intlShape,

    loading: PropTypes.bool,
    notFound: PropTypes.bool,
};

export const DropdownOptionWrapper = injectIntl(DropdownOptionWrapperComponent);
