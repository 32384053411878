import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import {FormattedMessage} from 'react-intl';

import {GroupEntityStatus} from 'components/common/GroupEntityStatus/GroupEntityStatus';

import './styles.scss';

export const GroupEntityStatusPanel = ({isActive, activeAgentError}) => (
        <Row>
            <Col xs={12}>
                <div className={classNames('group-entity-status-panel', {'has-error': activeAgentError})}>
                    <span className="group-entity-status-panel-title">
                        <FormattedMessage id={'misc.status'} />:
                    </span>
                    <GroupEntityStatus isActive={isActive} />
                    {activeAgentError && (
                        <div className="group-entity-status-panel-validation-error">
                            <FormattedMessage id="validate.activeAgentError" />
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    );

GroupEntityStatusPanel.propTypes = {
    isActive: PropTypes.bool,
    activeAgentError: PropTypes.bool,
};
