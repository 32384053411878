import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {matchShape} from 'core/propTypes';
import {PageControls} from 'components/common/PageControls';
import {PageWrapper} from 'components/common/PageWrapper';
import {LoadingWrapper} from 'components/common/LoadingWrapper';
import {PageContent} from 'components/common/PageContent';

import {ProducerAccountRemoveModal} from 'features/Agent/common/components/ProducerAccountRemoveModal';
import {
    clearAgentData,
    selectIsFetchingAgentData,
    initializeAgentData,
    selectAgentIsDent,
} from 'features/Agent/common';

import {AgentEditHeader, AgentEditContent} from './components';

export class AgentEditComponent extends React.Component {
    static propTypes = {
        match: matchShape,
        isLoading: PropTypes.bool,

        initializeData: PropTypes.func.isRequired,
        clearData: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {
            initializeData,
            match: {
                params: {id},
            },
        } = this.props;

        initializeData(id);
    }

    componentWillUnmount() {
        const {clearData} = this.props;
        clearData();
    }

    render() {
        const {isLoading, isDentAgent} = this.props;

        return (
            <PageWrapper>
                <PageControls title={<FormattedMessage id="agentEdit.title" />}>
                    <LoadingWrapper isLoading={isLoading}>
                        <AgentEditHeader isDentAgent={isDentAgent} />
                    </LoadingWrapper>
                </PageControls>
                <PageContent isLoading={isLoading}>
                    <ProducerAccountRemoveModal />
                    <AgentEditContent isDentAgent={isDentAgent} />
                </PageContent>
                <PageControls className="bottom-placed">
                    <LoadingWrapper isLoading={isLoading}>
                        <AgentEditHeader isDentAgent={isDentAgent} />
                    </LoadingWrapper>
                </PageControls>
            </PageWrapper>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: selectIsFetchingAgentData(state),
    isDentAgent: selectAgentIsDent(state),
});

export const AgentEdit = connect(mapStateToProps, {
    initializeData: initializeAgentData.action,
    clearData: clearAgentData.action,
})(AgentEditComponent);
