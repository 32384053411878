import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import {MaintenanceNotification} from '../../features/common/MaintenanceNotification';

export const Header = ({children, className}) => (
    <>
        <header className={classNames('app-header', className)}>{children}</header>
        {/* ENABLE THE MAINTENANCE NOTIFICATION WHEN THE MAINTENANCE DATES ARE CLEAR */}
        <MaintenanceNotification />
    </>
);

Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
