import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {mappedFieldsLocalization} from 'core/services/localeService/fieldsConfig';
import {PageControls} from 'components/common/PageControls';
import {PageWrapper} from 'components/common/PageWrapper';
import {ProducerAccountRemoveModal, ProducerAccounts} from 'features/Agent/common';

import {AgentCreateActions, AgentForm} from 'features/Agent/AgentCreate/components';
import {clearAgentData, initializeAgentData} from 'features/Agent/AgentCreate/reducer';

export class AgentCreateComponent extends React.Component {
    static propTypes = {
        initializeAgentData: PropTypes.func.isRequired,
        clearAgentData: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {initializeAgentData} = this.props;
        initializeAgentData();
    }

    componentWillUnmount() {
        this.props.clearAgentData();
    }

    render() {
        return (
            <PageWrapper>
                <PageControls title={<FormattedMessage id="agentCreate.title" />}>
                    <AgentCreateActions />
                </PageControls>
                <ProducerAccountRemoveModal />
                <AgentForm
                    options={mappedFieldsLocalization.contactLanguage}
                    producerAccountsComponent={ProducerAccounts}
                />
                <PageControls className="bottom-placed">
                    <AgentCreateActions />
                </PageControls>
            </PageWrapper>
        );
    }
}

export const AgentCreate = connect(null, {
    initializeAgentData: initializeAgentData.action,
    clearAgentData: clearAgentData.action,
})(AgentCreateComponent);
