import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {formatProducerAccountValue} from 'core/services/utils';
import get from 'lodash/get';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import removeIcon from 'assets/images/remove-cross-icon.svg';
import {producerAccountRoles, producerAccountTypes} from 'core/constants';

import {SelectField} from 'components/common/Form';
import {WithMappedProducerAccountOptions} from 'core/hoc/ProducerAccountOptions';

import {RolesField} from './RolesField';

export class ProducerAccountFieldsComponent extends React.Component {
    static propTypes = {
        intl: intlShape,
        path: PropTypes.string,
        options: PropTypes.array,
        values: PropTypes.object,
        intermedId: PropTypes.number,
        index: PropTypes.number,
        isRemovable: PropTypes.bool,
        savedProducerAccounts: PropTypes.array,
        formError: PropTypes.shape({
            id: PropTypes.string,
        }),

        onProducerAccountChange: PropTypes.func,
        change: PropTypes.func,
        onRemoveButtonClick: PropTypes.func,
    };

    render() {
        const {options, path, values, intl, isRemovable, formError, change} = this.props;

        const producerAccountId = get(values, 'producerAccountId');
        return (
            <>
                <h4>
                    <FormattedMessage id="fields.agentCreate.producerAccount.label" />
                    {isRemovable && (
                        <button
                            type="button"
                            className="btn-remove"
                            onClick={this.handleRemoveButtonClick}
                        >
                            <img
                                src={removeIcon}
                                alt={intl.formatMessage({
                                    id: 'fields.agentCreate.producerAccount.remove',
                                })}
                            />
                        </button>
                    )}
                </h4>
                <Row>
                    <Col xs={4}>
                        <Field
                            name={`${path}.producerAccountId`}
                            options={options}
                            formatValue={formatProducerAccountValue}
                            component={SelectField}
                            className="producer-account-dropdown"
                            placeholder={intl.formatMessage({
                                id: 'fields.agentCreate.producerAccount.placeholder',
                            })}
                            blurOnChange
                            formError={formError}
                            onChange={this.handleProducerAccountChange}
                            filterOnChange
                            clearable
                        />
                    </Col>
                </Row>
                {producerAccountId && (
                    <Row>
                        <Col xs={9}>
                            <Field
                                name={`${path}.roles`}
                                component={RolesField}
                                producerAccountId={producerAccountId}
                                options={this.resolveRoles(producerAccountId)}
                                change={change}
                                intl={intl}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    }

    handleProducerAccountChange = (e, props, prevProps) => {
        if (props === prevProps) {
            return;
        }

        const {path, index, onProducerAccountChange} = this.props;
        onProducerAccountChange({path, index});
    };

    mapRoles = (roles, selectedRoles) =>
        roles.map(role => ({
            name: role,
            selected: selectedRoles.includes(role),
        }));

    resolveRoles = producerAccountId => {
        const {options, savedProducerAccounts} = this.props;

        let selectedRoles = [];

        const producerSiam = options.find(
            option =>
                option.value === producerAccountId && option.type === producerAccountTypes.SIAM
        );

        if (!producerSiam) {
            return this.mapRoles(Object.values(producerAccountRoles), selectedRoles);
        }

        const isSavedProducerAccount = savedProducerAccounts.find(
            savedProducerAccount => savedProducerAccount.producerAccountId === producerAccountId
        );
        const savedRoles = get(isSavedProducerAccount, 'roles', null);

        if (producerSiam.sharedProducerAccount) {
            selectedRoles = savedRoles || [producerAccountRoles.production];
            return this.mapRoles(
                [producerAccountRoles.production, producerAccountRoles.policyConsultation],
                selectedRoles
            );
        }

        selectedRoles = savedRoles || [producerAccountRoles.policyManagement];

        return this.mapRoles(
            [
                producerAccountRoles.policyManagement,
                producerAccountRoles.policyConsultation,
                producerAccountRoles.consultCommissions,
            ],
            selectedRoles
        );
    };

    handleRemoveButtonClick = () => {
        const {index, onRemoveButtonClick} = this.props;
        onRemoveButtonClick(index);
    };
}

export const ProducerAccountFields = injectIntl(
    WithMappedProducerAccountOptions(ProducerAccountFieldsComponent)
);
