// polyfills for ie
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import {store} from 'store';

import {App} from 'features/App';
import {UnsupportedBrowser} from 'features/UnsupportedBrowser';

import './styles.scss';

ReactDOM.render(
    <UnsupportedBrowser>
        <Provider store={store}>
            <App />
        </Provider>
    </UnsupportedBrowser>,
    document.getElementById('root')
);
