import React from 'react';

import './styles.scss';

export const CirclesLoader = () => (
    <div className="circles-loader">
        <span />
        <span />
        <span />
    </div>
);
