import get from 'lodash/get';
import {call, all, takeLatest, put} from 'redux-saga/effects';
import {agents} from 'core/services/apiClient';
import {fetchAgent, sendInvitation} from './reducer';
import {transformProducerAccountNumbers} from 'core/services/utils';
import {toastHandler} from 'core/services/toastHandler';

export function* fetchAgentSaga({payload: agentId}) {
    try {
        const agentData = yield call(agents.getAgent, agentId);
        const producerAccounts = get(agentData, 'producerAccounts');
        const intermed = get(agentData, 'intermed');

        const mappedProducerAccounts = producerAccounts.map(account => ({
            ...account.producerAccount,
            roles: account.roles,
        }));

        const updatedProducerAccounts = transformProducerAccountNumbers(
            mappedProducerAccounts,
            intermed
        );

        yield put(fetchAgent.success({...agentData, producerAccounts: updatedProducerAccounts}));
    } catch (err) {
        yield put(fetchAgent.failure(err));
    }
}

export function* sendInvitationSaga({payload: agentId}) {
    try {
        yield call(agents.invitation, agentId);
        yield put(sendInvitation.success());
        toastHandler.onSuccess(null, null, 'misc.activationEmailIsSent');
    } catch (error) {
        yield put(sendInvitation.failure(error));
    }
}

export const agentDetailsSagas = function* () {
    yield all([
        takeLatest(fetchAgent.REQUEST, fetchAgentSaga),
        takeLatest(sendInvitation.REQUEST, sendInvitationSaga),
    ]);
};
