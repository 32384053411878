import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {ButtonsGroup} from 'components/common/ButtonsGroup';
import {ActionButton} from 'components/common/ActionButton';
import {history} from 'core/services/history';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {selectBrokerManagerId} from '../../selectors';
import {FeatureToggling} from 'core/FeatureToggling';

export class BrokerManagerDetailsActionsComponent extends React.Component {
    static propTypes = {
        id: PropTypes.number,
    };
    render() {
        const {id} = this.props;
        return (
            <ButtonsGroup onCancel={() => history.goToBrokerManagers()}>
                {!FeatureToggling.crmIntegrationEnabled && (
                    <ActionButton
                        onClick={() => history.goToBrokerManagersEdit(id)}
                        className="button action"
                    >
                        <FormattedMessage id="misc.edit" />
                    </ActionButton>
                )}
            </ButtonsGroup>
        );
    }
}

const mapStateToProps = () => state => ({
    id: selectBrokerManagerId(state),
});

export const BrokerManagerDetailsActions = connectWithIntl(mapStateToProps)(
    BrokerManagerDetailsActionsComponent
);
