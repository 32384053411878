import React from 'react';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import omit from 'lodash/omit';

import {producerAccountTypes, ROOT_URI, PHONE_CODES} from 'core/constants';

export const getRedirectUri = (path = '') => ROOT_URI + path;

export const isEmptyValue = value =>
    value === undefined ||
    value === null ||
    value === false ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);

export function flattenMessages(nestedMessages, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        let value = nestedMessages[key];
        let prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}

export const isTrue = value => value === true || value === 'true';

export const withForwardRef = Component =>
    React.forwardRef((props, ref) => <Component {...props} forwardedRef={ref} />);

export const getNameInLowerCase = elem => (elem && elem.name ? elem.name.toLowerCase() : '');

export const resolveProducerAccountElem = (
    {type, linkedIntermed, linkedLeadGenerator},
    intermed
) => {
    switch (type) {
        case producerAccountTypes.OWN:
        case producerAccountTypes.ONLINE:
            return intermed;
        case producerAccountTypes.SIAM:
            return linkedIntermed;
        case producerAccountTypes.LEADGENERATOR:
            return linkedLeadGenerator;
        default:
            return null;
    }
};

export const resolveProducerAccountLinkedIntermedId = linkedIntermed => {
    if (!linkedIntermed) {
        return null;
    }
    return linkedIntermed.id;
};

export const resolveProducerAccountLabel = (producerAccount, intermed) => {
    const element = resolveProducerAccountElem(producerAccount, intermed);

    if (!element) {
        return '';
    }

    return producerAccount.type === producerAccountTypes.LEADGENERATOR
        ? isString(element.name)
            ? element.name
            : ''
        : `${element.name} ${element.legalForm}`;
};

export const transformProducerAccountNumbers = (producerAccountNumbers, intermed) => {
    return producerAccountNumbers
        .sort((next, prev) => {
            const nextElem = resolveProducerAccountElem(
                {...next, type: producerAccountTypes[next.type]},
                intermed
            );
            const prevElem = resolveProducerAccountElem(
                {...prev, type: producerAccountTypes[prev.type]},
                intermed
            );

            const nextElemName = getNameInLowerCase(nextElem);
            const prevElemName = getNameInLowerCase(prevElem);

            return nextElemName.localeCompare(prevElemName);
        })
        .map(({number, id, type: accountType, linkedLeadGenerator, linkedIntermed, roles}) => {
            const type = producerAccountTypes[accountType];

            return {
                id,
                value: number,
                label: resolveProducerAccountLabel(
                    {
                        type,
                        linkedLeadGenerator,
                        linkedIntermed,
                    },
                    intermed
                ),
                type,
                roles,
                linkedIntermedId: resolveProducerAccountLinkedIntermedId(linkedIntermed),
            };
        });
};

export const mapProducerNumberTypeToLocId = new Map([
    [producerAccountTypes.LEADGENERATOR, 'producerAccountTypes.leadGenerator'],
    [producerAccountTypes.ONLINE, 'producerAccountTypes.onlineChat'],
    [producerAccountTypes.OWN, 'producerAccountTypes.own'],
    [producerAccountTypes.SIAM, 'producerAccountTypes.siam'],
]);

export const getProducerAccountLabel = (intl, {label, value, type}) => {
    return `${label} \n(${value}) ${intl.formatMessage({
        id: mapProducerNumberTypeToLocId.get(type),
    })}`;
};

export const formatProducerAccountValue = value => {
    const index = value.indexOf('(');
    const subString = value.substring(0, index);

    if (!subString) {
        return value;
    }
    return `${subString}\n${value.substring(index)}`;
};

export const noop = () => {};

export const convertPhoneNumber = (payload, path) => {
    if (isEmpty(payload)) {
        return null;
    }
    const data = {...payload};
    const phoneNumber = get(data, path, false);
    const phoneNumberCodePath = `${path}Code`;

    if (phoneNumber) {
        let updatedPhoneNumber = phoneNumber.replace(/\s/g, '');
        if (updatedPhoneNumber.startsWith('0')) {
            updatedPhoneNumber = updatedPhoneNumber.slice(1);
        }
        const phoneNumberCode = get(data, phoneNumberCodePath);
        const modifiedPhoneNumber = phoneNumberCode + updatedPhoneNumber;
        set(data, path, modifiedPhoneNumber);
    } else {
        set(data, path, null);
    }
    return omit(data, phoneNumberCodePath);
};

export const parsePhoneNumbers = phoneNumber => {
    let phoneNumberCode = PHONE_CODES.BELGIUM;
    let modifiedPhoneNumber = phoneNumber;
    if (phoneNumber) {
        const phoneCodes = Object.values(PHONE_CODES);
        const currentCode = phoneCodes.find(code => phoneNumber.startsWith(code));

        if (currentCode) {
            phoneNumberCode = currentCode;
            modifiedPhoneNumber = phoneNumber.split(currentCode)[1];
        }
    }
    return {
        phoneNumberCode,
        phoneNumber: modifiedPhoneNumber,
    };
};

export const removeSpaces = value => value.replace(/\s/g, '');
