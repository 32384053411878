import {createReducer} from 'core/createReducer';
import {Action, AsyncAction, CaughtAsyncAction} from 'core/actionHelpers';

export const containerId = 'Agent';

export const fetchProducerAccounts = new AsyncAction(
    `${containerId}/AGENT_PRODUCER_ACCOUNTS_FETCH`,
    []
);
export const addProducerAccount = new Action(`${containerId}/AGENT_PRODUCER_ACCOUNT_ADD`);
export const removeProducerAccount = new Action(`${containerId}/AGENT_PRODUCER_ACCOUNT_REMOVE`);
export const showRemoveProducerAccountModal = new Action(
    `${containerId}/AGENT_PRODUCER_ACCOUNT_REMOVE_SHOW_MODAL`
);
export const changeProducerAccount = new Action(`${containerId}/AGENT_PRODUCER_ACCOUNT_CHANGE`);
export const clearAgentData = new Action(`${containerId}/AGENT_DATA_CLEAR`);

export const initializeAgentData = new Action(`${containerId}/AGENT_INITIALIZE_DATA`);
export const fetchAgentData = new AsyncAction(`${containerId}/AGENT_EDIT_FETCH_DATA`);
export const removeAgent = new AsyncAction(`${containerId}/AGENT_EDIT_REMOVE`);
export const showRemoveModal = new Action(`${containerId}/AGENT_EDIT_SHOW_REMOVE_MODAL`);

export const changeAgentStatus = new CaughtAsyncAction(`${containerId}/AGENT_EDIT_CHANGE_STATUS`);
export const showChangeStatusModal = new Action(
    `${containerId}/AGENT_EDIT_SHOW_CHANGE_STATUS_MODAL`
);
export const saveAgentData = new AsyncAction(`${containerId}/AGENT_EDIT_SAVE`);
export const saveDentAgentData = new AsyncAction(`${containerId}/AGENT_DENT_EDIT_SAVE`);
export const handleSaveAgent = new Action(`${containerId}/AGENT_HANDLE_EDIT_SAVE`);
export const handleSaveDentAgent = new Action(`${containerId}/AGENT_HANDLE_DENT_EDIT_SAVE`);
export const triggerAgentIsActive = new Action(`${containerId}/AGENT_TRIGGER_IS_ACTIVE`);
export const setProducerAccountFormErrors = new Action(
    `${containerId}/SET_PRODUCER_ACCOUNT_FORM_ERRORS`,
    []
);

export const agentsActions = [
    fetchProducerAccounts,
    showRemoveProducerAccountModal,
    fetchAgentData,
    removeAgent,
    changeAgentStatus,
    saveAgentData,
    saveDentAgentData,
    setProducerAccountFormErrors,
];

const actionToHandlerMap = new Map([
    [
        triggerAgentIsActive.ACTION,
        draft => {
            draft[fetchAgentData.type].data.isActive = !draft[fetchAgentData.type].data.isActive;
        },
    ],
]);

export const AgentReducer = createReducer(agentsActions, null, actionToHandlerMap);
