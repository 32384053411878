import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {optionShape, optionsShape} from 'core/propTypes';

import {DropdownOption} from '../DropdownOption';
import {DropdownOptionWrapper} from '../DropdownOptionWrapper';

import './styles.scss';

export class DropdownOptions extends React.Component {
    static propTypes = {
        options: optionsShape,
        show: PropTypes.bool,
        loading: PropTypes.bool,
        selectedValue: PropTypes.oneOfType([PropTypes.any, optionShape]),
        focusedIndex: PropTypes.number,

        onItemClick: PropTypes.func,
    };

    dropdownList = React.createRef();

    componentDidMount() {
        this._scrollToOption(this.props.focusedIndex);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.focusedIndex !== this.props.focusedIndex ||
            prevProps.options !== this.props.options ||
            (this.props.show === true && this.props.show !== prevProps.show)
        ) {
            this._scrollToOption(this.props.focusedIndex);
        }
    }

    render() {
        const {options, show, loading, selectedValue, focusedIndex, onItemClick} = this.props;

        if (!show) {
            return null;
        }

        const notFound = !options.length;

        return (
            <div ref={this.dropdownList} className="searchable-dropdown-options">
                <DropdownOptionWrapper loading={loading} notFound={notFound}>
                    {options.map((option, index) => (
                        <DropdownOption
                            key={option.value}
                            value={option.value}
                            label={option.label}
                            disabled={option.disabled}
                            selected={option.value === selectedValue}
                            focused={index === focusedIndex}
                            onClick={onItemClick}
                        />
                    ))}
                </DropdownOptionWrapper>
            </div>
        );
    }

    _scrollToOption = index => {
        const childrenOptions = get(this.dropdownList, 'current.children');
        if (!childrenOptions) {
            return;
        }

        if (index > childrenOptions.length - 1 || index < 0) {
            return;
        }
        const target = childrenOptions[index];
        const {height} = target.getBoundingClientRect();
        this.dropdownList.current.scrollTop = target.offsetTop - height;
    };
}
