import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {LoadingWrapper} from 'components/common/LoadingWrapper';
import {PageContent} from 'components/common/PageContent';
import {PageWrapper} from 'components/common/PageWrapper';
import {PageControls} from 'components/common/PageControls';
import {TableWrapper} from 'components/common/Table/TableWrapper';
import {matchShape} from 'core/propTypes';
import {AgentDetailsActions, AgentDetailsForm} from './components';
import {fetchAgent, sendInvitation} from './reducer';
import {selectIsLoading, selectIsSendingInvitation} from './selectors';
import {AgentDetailsTable} from './components/AgentDetailsTable/AgentDetailsTable';

import './styles.scss';

export class AgentDetailsComponent extends React.Component {
    static propTypes = {
        match: matchShape,
        isLoading: PropTypes.bool,
        fetchData: PropTypes.func.isRequired,
        clearData: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {id} = this.props.match.params;
        this.props.fetchData(id);
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    render() {
        const {
            isLoading,
            isSendingInvitation,
            sendInvitation,
            match: {
                params: {id},
            },
        } = this.props;

        return (
            <PageWrapper>
                <PageControls title={<FormattedMessage id="agentDetails.title" />}>
                    <LoadingWrapper isLoading={isLoading}>
                        <AgentDetailsActions />
                    </LoadingWrapper>
                </PageControls>
                <PageContent isLoading={isLoading}>
                    <AgentDetailsForm
                        isSendingInvitation={isSendingInvitation}
                        sendInvitation={() => sendInvitation(id)}
                    />
                    <TableWrapper
                        className="agent-details-table-wrapper"
                        headerLeft={<FormattedMessage id={'agentDetails.table.title'} />}
                    >
                        <AgentDetailsTable />
                    </TableWrapper>
                </PageContent>
            </PageWrapper>
        );
    }
}

const mapStateToProps = () => {
    return state => ({
        isLoading: selectIsLoading(state),
        isSendingInvitation: selectIsSendingInvitation(state),
    });
};

export const AgentDetails = connect(mapStateToProps, {
    fetchData: fetchAgent.request,
    clearData: fetchAgent.clear,
    sendInvitation: sendInvitation.request,
})(AgentDetailsComponent);
