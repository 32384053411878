import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {Modal} from 'features/common/Modal';
import {ActionButton} from 'components/common/ActionButton';
import {selectAgentIsActive} from 'features/Agent/common/selectors';
import {changeAgentStatus} from 'features/Agent/common/reducer';
import {connectWithIntl} from 'core/services/connectWithIntl';

export const AGENT_EDIT_STATUS_MODAL = 'AgentEditStatusModal';

export class AgentEditStatusModalComponent extends React.PureComponent {
    static propTypes = {
        isActive: PropTypes.bool,
        onChangeStatus: PropTypes.func,
    };

    render() {
        return (
            <Modal modalId={AGENT_EDIT_STATUS_MODAL} actionsPanel={this.resolveActionsPanel()}>
                <FormattedMessage id={this.resolveMessageId()} />
            </Modal>
        );
    }

    resolveMessageId = () => {
        const {isActive} = this.props;
        return `modals.misc.${isActive ? 'deactivateQuestion' : 'activateQuestion'}`;
    };

    resolveActionsPanel = () => {
        const {isActive, onChangeStatus} = this.props;

        const actionsMessageId = isActive ? 'misc.deactivate' : 'misc.activate';

        const activeClassName = isActive ? 'warning' : 'success';

        return (
            <ActionButton onClick={onChangeStatus} className={activeClassName}>
                <FormattedMessage id={actionsMessageId} />
            </ActionButton>
        );
    };
}

const mapStateToProps = state => ({
    isActive: selectAgentIsActive(state),
});

export const AgentEditStatusModal = connectWithIntl(mapStateToProps, {
    onChangeStatus: changeAgentStatus.request,
})(AgentEditStatusModalComponent);
