import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {matchShape} from 'core/propTypes';

import {PageWrapper} from 'components/common/PageWrapper';
import {PageControls} from 'components/common/PageControls';
import {PageContent} from 'components/common/PageContent';
import {LoadingWrapper} from 'components/common/LoadingWrapper';

import {
    fetchBrokerManagerData,
    clearBrokerManager,
} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {selectIsFetchingBrokerManagerData} from 'features/BrokerManager/BrokerManagerEdit/selectors';

import {
    BrokerManagerEditActions,
    BrokerManagerEditForm,
    BrokerManagerEditStatus,
    BrokerManagerEditStatusModal,
    BrokerManagerRemoveModal,
    BrokerManagerRemoveTooltip,
} from './components';
import {FeatureToggling} from '../../../core/FeatureToggling';

export class BrokerManagerEditComponent extends React.Component {
    static propTypes = {
        isFetching: PropTypes.bool,
        fetchInitialData: PropTypes.func.isRequired,
        clearBrokerManager: PropTypes.func.isRequired,
        match: matchShape,
    };

    componentDidMount() {
        const {
            fetchInitialData,
            match: {
                params: {id},
            },
        } = this.props;

        fetchInitialData(id);
    }

    componentWillUnmount() {
        const {clearBrokerManager} = this.props;
        clearBrokerManager();
    }

    render() {
        const {isFetching} = this.props;

        return (
            <PageWrapper>
                <PageControls
                    title={<FormattedMessage id="brokerManagerEdit.title" />}
                    postfix={<BrokerManagerRemoveTooltip />}
                >
                    <LoadingWrapper isLoading={isFetching}>
                        {!FeatureToggling.crmIntegrationEnabled && <BrokerManagerRemoveModal />}
                        <BrokerManagerEditStatusModal />
                        <BrokerManagerEditActions />
                    </LoadingWrapper>
                </PageControls>
                <PageContent isLoading={isFetching}>
                    <BrokerManagerEditStatus />
                    <BrokerManagerEditForm />
                </PageContent>
            </PageWrapper>
        );
    }
}

const mapStateToProps = () => {
    return state => ({
        isFetching: selectIsFetchingBrokerManagerData(state),
    });
};

export const BrokerManagerEdit = connect(mapStateToProps, {
    fetchInitialData: fetchBrokerManagerData.request,
    clearBrokerManager: clearBrokerManager.action,
})(BrokerManagerEditComponent);
