export const AuthErrorMessage = {
    passwordReset: {
        code: 'AADB2C90118',
    },
    interactionRequired: ['consent_required', 'interaction_required', 'login_required'],
};

export class AuthError {
    constructor(errorCode, errorMessage) {
        this.name = 'AuthError';
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }

    static isPasswordResetError(errorMessage) {
        if (!errorMessage || !errorMessage.length) {
            return false;
        }
        return errorMessage.indexOf(AuthErrorMessage.passwordReset.code) > -1;
    }

    static isInteractionRequired(errorMessage) {
        if (!errorMessage || !errorMessage.length) {
            return false;
        }
        return AuthErrorMessage.interactionRequired.some(code => errorMessage.indexOf(code) > -1);
    }
}
