import get from 'lodash/get';
import {select, call, all, takeLatest, put} from 'redux-saga/effects';

import {brokerManagers} from 'core/services/apiClient';
import {
    fetchBrokerManagerData,
    editBrokerManager,
    containerId,
    changeStatusBrokerManager,
    showChangeStatusModal,
    triggerBrokerManagerIsActive,
    removeBrokerManager,
    showRemoveBrokerManagerModal,
    disableRemoveBrokerManager,
    clearBrokerManager,
    brokerManagerActions,
    handleEditBrokerManager,
} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {clearActionsStateSaga, defaultSubmitFormIfValidSaga} from 'core/sagaHelpers';
import {
    selectAndPrepareFormData,
    selectChangeStatusRequestBody,
    selectBrokerManagerId,
} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {showModal, hideModal} from 'features/common/Modal';
import {setGeneralError} from 'features/ErrorBoundary';
import {
    BROKER_MANAGER_EDIT_STATUS_MODAL,
    BROKER_MANAGER_REMOVE_MODAL,
} from 'features/BrokerManager/BrokerManagerEdit/components';
import {history} from 'core/services/history';

export function* fetchBrokerManagerDataSaga({payload: brokerManagerId}) {
    try {
        const brokerManagerData = yield call(brokerManagers.getBrokerManager, brokerManagerId);
        yield put(fetchBrokerManagerData.success(brokerManagerData));
    } catch (err) {
        yield put(fetchBrokerManagerData.failure(err));
    }
}

export function* createEditBrokerManagerSaga() {
    yield call(defaultSubmitFormIfValidSaga, containerId, editBrokerManagerSaga);
}

export function* editBrokerManagerSaga() {
    try {
        const brokerManagerId = yield select(selectBrokerManagerId);
        const requestBody = yield select(selectAndPrepareFormData);
        yield put(editBrokerManager.request(requestBody));

        yield call(brokerManagers.edit, brokerManagerId, requestBody);
        yield put(editBrokerManager.success());

        history.goToBrokerManagersDetails(brokerManagerId);
    } catch (err) {
        yield put(editBrokerManager.failure(err));
    }
}

export function* changeStatusBrokerManagerSaga() {
    try {
        const brokerManagerId = yield select(selectBrokerManagerId);
        const requestBody = yield select(selectChangeStatusRequestBody);

        yield put(hideModal.action(BROKER_MANAGER_EDIT_STATUS_MODAL));
        yield call(brokerManagers.status, brokerManagerId, requestBody);

        yield put(changeStatusBrokerManager.success());
        yield put(triggerBrokerManagerIsActive.action());
    } catch (err) {
        yield put(changeStatusBrokerManager.failure(err));
    }
}

export function* showChangeStatusModalSaga() {
    yield put(showModal.action(BROKER_MANAGER_EDIT_STATUS_MODAL));
}

export function* showRemoveBrokerManagerModalSaga() {
    yield put(showModal.action(BROKER_MANAGER_REMOVE_MODAL));
}

const redirectToList = () => history.goToBrokerManagers();

export const createRemoveBrokerManagerSaga = ({redirectToList}) =>
    function* () {
        try {
            const brokerManagerId = yield select(selectBrokerManagerId);

            yield put(hideModal.action(BROKER_MANAGER_REMOVE_MODAL));
            yield call(brokerManagers.remove, brokerManagerId);
            redirectToList();

            yield put(removeBrokerManager.success());
        } catch (err) {
            yield call(handleRemoveBrokerManagerError, err);
            yield put(removeBrokerManager.failure(err));
        }
    };

const removeBrokerManagerSaga = createRemoveBrokerManagerSaga({redirectToList});

export const createHandleRemoveBrokerManagerError = ({redirectToList}) =>
    function* (error) {
        const status = get(error, 'status');

        if (status === 404) {
            return redirectToList();
        }
        if (status === 405) {
            return yield put(disableRemoveBrokerManager.action());
        }

        yield put(setGeneralError.action(error));
    };

export const handleRemoveBrokerManagerError = createHandleRemoveBrokerManagerError({
    redirectToList,
});

export function* clearBrokerManagerSaga() {
    yield call(clearActionsStateSaga, brokerManagerActions);
}

export const brokerManagerEditSagas = function* () {
    yield all([
        takeLatest(fetchBrokerManagerData.REQUEST, fetchBrokerManagerDataSaga),
        takeLatest(handleEditBrokerManager.ACTION, createEditBrokerManagerSaga),
        takeLatest(changeStatusBrokerManager.REQUEST, changeStatusBrokerManagerSaga),
        takeLatest(showRemoveBrokerManagerModal.ACTION, showRemoveBrokerManagerModalSaga),
        takeLatest(showChangeStatusModal.ACTION, showChangeStatusModalSaga),
        takeLatest(removeBrokerManager.REQUEST, removeBrokerManagerSaga),
        takeLatest(clearBrokerManager.ACTION, clearBrokerManagerSaga),
    ]);
};
