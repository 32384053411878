import classNames from 'classnames';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';
import {FormFieldWrapper} from '../FormFieldWrapper';

import './styles.scss';

export class CheckBoxField extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        info: PropTypes.string,
        name: PropTypes.string,
        hidden: PropTypes.bool,
        tabIndex: PropTypes.number,
        disabled: PropTypes.bool,
        input: PropTypes.object,
        meta: PropTypes.object,
    };

    static defaultProps = {
        meta: {},
        tabIndex: null,
    };

    inputName = get(this.props.input, 'name', this.props.name);
    id = `cb-${this.inputName}-${uniqueId()}`;

    render() {
        const {
            // own props
            className,
            label,
            info,
            hidden,
            onChange,

            // input props
            value,
            checked,
            disabled,

            // redux form props
            input,
            tabIndex,
            meta: {touched, error},
        } = this.props;

        return (
            <FormFieldWrapper
                className={classNames('input-checkbox-wrapper', className)}
                touched={touched}
                error={error}
                hidden={hidden}
                info={info}
            >
                <input
                    tabIndex={tabIndex}
                    id={this.id}
                    type="checkbox"
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    {...input}
                    onChange={input && input.onChange ? input.onChange : onChange}
                />
                <label htmlFor={this.id}>{label}</label>
            </FormFieldWrapper>
        );
    }
}
