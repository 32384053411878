import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {noop} from 'core/services/utils';

import {OverflowTooltip} from '../../../OverflowTooltip';

import './styles.scss';

export const DropdownOption = ({
    value,
    label,
    disabled,
    focused,
    selected,

    onClick,
}) => {
    const _onClick = useCallback(e => onClick(e, value), [onClick, value]);

    return (
        <div
            onMouseDown={_onClick}
            className={classNames('searchable-dropdown-option', {
                disabled,
                focused,
                selected,
            })}
        >
            <OverflowTooltip>{label}</OverflowTooltip>
        </div>
    );
};

DropdownOption.propTypes = {
    index: PropTypes.any,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    focused: PropTypes.bool,
    selected: PropTypes.bool,

    onClick: PropTypes.func,
};

DropdownOption.defaultProps = {
    onClick: noop,
};
