import React from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';

import {NoOptionsMessage, LoadingIndicator, LoadingMessage} from './components';

import './styles.scss';

export const DropDown = ({
    input,
    value,
    options = [],
    label,
    isClearable = true,
    hideSelectedOptions = false,
    backspaceRemovesValue = true,
    placeholder = '',
    required = false,
    disabled = false,
    className,
    onChange,
    isLoading = false,
    isSearchable = true,
    postfix,
    components,
    isAsync = false,
    loadOptions,
    hint,
}) => {
    const _onChange = e => {
        const value = e && !!e.value ? e.value : null;
        onChange && onChange(e);
        input && input.onChange && input.onChange(value);
    };

    const _onBlur = () => {
        const value = input && input.value ? input.value : null;
        input && input.onBlur && input.onBlur(value);
    };

    const inputLabel = label && (
        <label className={classNames({required})}>
            <span>{label}</span>
            {hint}
        </label>
    );

    const getValue = () => {
        const inputValue = value || get(input, 'value', null);
        if (isAsync) {
            return {value: inputValue, label: inputValue};
        } else {
            return options.find(option => option.value === inputValue) || null;
        }
    };

    const commonProps = {
        className: 'dropdown-input',
        classNamePrefix: 'dropdown-input',
        value: getValue(),
        placeholder,
        isClearable,
        isDisabled: disabled,
        isSearchable,
        onChange: _onChange,
        onBlur: _onBlur,
        blurInputOnSelect: false,
        backspaceRemovesValue,
        components: {
            ClearIndicator: null,
            NoOptionsMessage,
            LoadingIndicator,
            LoadingMessage,
            ...components,
        },
    };
    return (
        <div className={classNames('input-dropdown-wrapper', className)}>
            {inputLabel}
            {isAsync ? (
                <AsyncSelect
                    loadOptions={debounce(loadOptions, 500)}
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                    {...commonProps}
                />
            ) : (
                <Select
                    options={options}
                    isLoading={isLoading}
                    hideSelectedOptions={hideSelectedOptions}
                    isOptionDisabled={({disabled}) => disabled}
                    {...commonProps}
                />
            )}
            {postfix && <span className="postfix">{postfix}</span>}
        </div>
    );
};

DropDown.propTypes = {
    input: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    label: PropTypes.string,
    hint: PropTypes.node,
    isClearable: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    isSearchable: PropTypes.bool,
    postfix: PropTypes.string,
    components: PropTypes.object,
    isAsync: PropTypes.bool,
    loadOptions: PropTypes.func,
};
