import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {ActionButton} from 'components/common/ActionButton';

import './styles.scss';

const ButtonsGroupComponent = ({onCancel, children}) => (
    <div className="buttons-group">
        <ActionButton onClick={onCancel} className="button-action">
            <i className="icon icon-arrow-left" />
            <span>
                <FormattedMessage id="misc.back" />
            </span>
        </ActionButton>
        {children}
    </div>
);

ButtonsGroupComponent.propTypes = {
    children: PropTypes.node,
    onCancel: PropTypes.func,
};

export const ButtonsGroup = memo(ButtonsGroupComponent);
