import React, {Component} from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {injectIntl, FormattedMessage} from 'react-intl';

import {SelectField, TextField} from 'components/common/Form';
import {Tooltip} from 'components/common/Tooltip';
import {PHONE_CODES, phoneValueToCountry} from 'core/constants';

import belgiumIcon from 'assets/images/Belgium.svg';
import franceIcon from 'assets/images/France.svg';
import germanyIcon from 'assets/images/Germany.svg';
import luxembourgIcon from 'assets/images/Luxembourg.svg';
import netherlandsIcon from 'assets/images/Netherlands.svg';

import './styles.scss';

export const phoneValueToFlagIcon = {
    [PHONE_CODES.BELGIUM]: belgiumIcon,
    [PHONE_CODES.FRANCE]: franceIcon,
    [PHONE_CODES.GERMANY]: germanyIcon,
    [PHONE_CODES.LUXEMBOURG]: luxembourgIcon,
    [PHONE_CODES.NETHERLANDS]: netherlandsIcon,
};

export class PhoneNumberFieldComponent extends Component {
    maskPhone = value => {
        const {codeValue, mask} = this.props;
        if (!mask) {
            return;
        }
        return mask(codeValue, value);
    };

    render() {
        const {intl, labelId, infoId, disabled, phoneNumberError, name, codeValue, ...restProps} =
            this.props;

        const codeFieldName = `${name}Code`;

        return (
            <div className="phone">
                <div className="phone-title">
                    <span className="phone-title-label">{intl.formatMessage({id: labelId})}</span>
                    {infoId && (
                        <Tooltip>
                            <span className="phone-title-info">
                                {intl.formatMessage({id: infoId})}
                            </span>
                        </Tooltip>
                    )}
                </div>
                <div
                    className={classNames('phone-field', {
                        hasError: phoneNumberError,
                        disabled,
                    })}
                >
                    <Field
                        name={codeFieldName}
                        className="bright phone-field-dropdown"
                        options={this.resolvePhoneCodes()}
                        onChange={this.changeCode}
                        component={SelectField}
                        editable={false}
                        disabled={disabled}
                    />

                    <Field
                        name={name}
                        className="bright phone-field-input"
                        disabled={disabled}
                        component={TextField}
                        {...restProps}
                        maxLength={16}
                        mask={codeValue && this.maskPhone}
                    />
                </div>
            </div>
        );
    }

    changeCode = (e, codeValue) => {
        const {change, name} = this.props;
        const codeFieldName = `${name}Code`;

        change(codeFieldName, codeValue);
        change(name, null);
    };

    resolvePhoneCodes = () =>
        Object.values(PHONE_CODES).map(code => {
            const countryLabel = (
                <FormattedMessage id={`references.countries.${phoneValueToCountry[code]}`} />
            );
            const label = (
                <>
                    <img src={phoneValueToFlagIcon[code]} alt={countryLabel} />
                    <span className="phone-field-dropdown-option-name">{countryLabel}</span>
                    <span className="phone-field-dropdown-option-code">{code}</span>
                </>
            );
            return {
                value: code,
                label,
            };
        });
}

PhoneNumberFieldComponent.propTypes = {
    labelId: PropTypes.string.isRequired,
    infoId: PropTypes.string,
    name: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    mask: PropTypes.func,
    codeValue: PropTypes.string,
    phoneNumberError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export const PhoneNumberField = injectIntl(PhoneNumberFieldComponent);
