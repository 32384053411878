import React from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const LoadingMessage = () => (
    <div className="loading">
        <FormattedMessage id="addBrokerManagerForm.loadingText" />
    </div>
);
