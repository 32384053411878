import {initialize} from 'redux-form';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {clearActionsStateSaga, createSubmitFormIfValidSaga} from 'core/sagaHelpers';
import {agents} from 'core/services/apiClient';
import {history} from 'core/services/history';
import {toastHandler} from 'core/services/toastHandler';
import {PHONE_CODES} from 'core/constants';

import {
    createAgent,
    saveCreatedAgent,
    clearAgentData,
    agentsActions,
    initializeAgentData,
} from 'features/Agent/AgentCreate/reducer';

import {
    containerId,
    fetchProducerAccounts,
    clearAgentData as commonClearAgentData,
    setProducerAccountFormErrors,
} from 'features/Agent/common/reducer';

import {
    createProducerAccount,
    saveAgentDataCheckErrorSaga,
    checkProducerAccountValidationErrorSaga,
} from 'features/Agent/common/sagas';

import {selectAndPrepareFormData} from 'features/Agent/common/selectors';

export const submitAgentIfValidSaga = createSubmitFormIfValidSaga({
    checkFormErrorsSaga: saveAgentDataCheckErrorSaga,
});

export function* saveCreatedAgentSaga() {
    try {
        const requestBody = yield select(selectAndPrepareFormData);
        yield put(saveCreatedAgent.request(requestBody));
        const agentId = yield call(agents.create, requestBody);
        yield put(saveCreatedAgent.success());
        history.goToAgentDetails(agentId);
        toastHandler.onSuccess(null, null, 'notifications.agent.create');
    } catch (err) {
        yield put(saveCreatedAgent.failure(err));
    }
}

export function* createAgentSaga() {
    yield call(checkProducerAccountValidationErrorSaga);
    yield call(submitAgentIfValidSaga, containerId, saveCreatedAgentSaga);
}

export function* initializeDataSaga() {
    yield put(fetchProducerAccounts.request());
    yield put(
        initialize(containerId, {
            producerAccounts: [createProducerAccount()],
            dateOfBirth: null,
            contactNumberCode: PHONE_CODES.BELGIUM,
            mobileNumberCode: PHONE_CODES.BELGIUM,
        })
    );
}

export function* clearAgentDataSaga() {
    yield call(clearActionsStateSaga, agentsActions);
    yield put(commonClearAgentData.clear());
    yield put(setProducerAccountFormErrors.clear());
}

export const agentCreateSagas = function* () {
    yield all([
        takeLatest(createAgent.ACTION, createAgentSaga),
        takeLatest(clearAgentData.ACTION, clearAgentDataSaga),
        takeLatest(initializeAgentData.ACTION, initializeDataSaga),
    ]);
};
