export const Permissions = {
    // used when user must not see something while having other permissions set
    notSet: 'NOT_SET',
    agent: {
        create: 'AGENT_CREATE',
        read: 'AGENT_READ',
        update: 'AGENT_UPDATE',
        delete: 'AGENT_DELETE',
        list: 'AGENT_LIST',
    },
    brokerManager: {
        create: 'BROKER_MANAGER_CREATE',
        read: 'BROKER_MANAGER_READ',
        update: 'BROKER_MANAGER_UPDATE',
        delete: 'BROKER_MANAGER_DELETE',
        list: 'BROKER_MANAGER_LIST',
    },
};
