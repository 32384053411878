import React from 'react';
import PropTypes from 'prop-types';
import {FormFieldWrapper} from '../FormFieldWrapper';
import MaskedInput from 'react-text-mask';

import './styles.scss';

export const TextField = props => {
    const {
        // own props
        className,
        label,
        info,
        hidden,
        onChange,
        serverError,

        // input props
        disabled,
        placeholder,
        maxLength,
        forwardedRef,
        mask,

        // redux form props
        input,
        meta: {touched, error},
    } = props;

    const _onBlur = () => {
        const value =
            input && input.value !== '' ? input.value.toString().trim().replace(/\s+/g, ' ') : null;
        input && input.onBlur && input.onBlur(value);
    };

    const inputProps = {
        tabIndex: 1,
        type: 'text',
        className: 'text-input',
        placeholder: disabled ? undefined : placeholder,
        disabled,
        maxLength,
        ref: forwardedRef,
        ...input,
        onBlur: _onBlur,
        onChange: input && input.onChange ? input.onChange : onChange,
    };

    return (
        <FormFieldWrapper
            className={className}
            touched={touched}
            error={error}
            serverError={serverError}
            hidden={hidden}
            label={label}
            info={info}
        >
            {mask ? (
                <MaskedInput
                    guide
                    autoComplete="off"
                    placeholderChar={'\u2000'}
                    mask={mask}
                    {...inputProps}
                />
            ) : (
                <input {...inputProps} />
            )}
        </FormFieldWrapper>
    );
};

TextField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    info: PropTypes.string,
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    input: PropTypes.object,
    meta: PropTypes.object,
    mask: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])),
        PropTypes.func,
        PropTypes.object,
    ]),
    forwardedRef: PropTypes.object,
    onChange: PropTypes.func,
    serverError: PropTypes.object,
};

TextField.defaultProps = {
    meta: {},
};
