import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {injectIntl, FormattedMessage, intlShape} from 'react-intl';

import missingInfoIcon from 'assets/images/missing-data.svg';

import './styles.scss';

const TableStatusCellComponent = ({intl, isActive, isInformationMissing}) => {
    const statusId = isActive
        ? 'brokerManagerList.table.status.active'
        : 'brokerManagerList.table.status.inactive';
    const isMissingDataDisplayed = isActive && isInformationMissing;

    return (
        <div className="table-component-status-cell">
            <div
                className={classNames('table-component-status', {
                    'table-component-status-active': isActive,
                    'table-component-status-inactive': !isActive,
                })}
            >
                <FormattedMessage id={statusId} />
            </div>
            {isMissingDataDisplayed && (
                <div className={'table-component-status-cell-missing-data'}>
                    <img
                        src={missingInfoIcon}
                        alt={intl.formatMessage({
                            id: 'brokerManagerList.table.status.missingData',
                        })}
                    />
                    <FormattedMessage id={'brokerManagerList.table.status.missingData'} />
                </div>
            )}
        </div>
    );
};

TableStatusCellComponent.propTypes = {
    intl: intlShape,
    isActive: PropTypes.bool,
};

export const TableStatusCell = injectIntl(TableStatusCellComponent);
