import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {noop} from 'core/services/utils';

import {PageContentDetailsForm} from 'components/common/PageContent/PageContentDetailsForm';
import {PageContentDetailsFormStatusItem} from 'components/common/PageContent/PageContentDetailsForm/PageContentDetailsFormStatusItem';
import {PageContentDetailsFormItem} from 'components/common/PageContent/PageContentDetailsForm/PageContentDetailsFormItem';
import {ActionButton} from 'components/common/ActionButton';

const AgentDetailsComponent = ({
    isActive,
    surname,
    email,
    firstName,
    contactNumber,
    mobileNumber,
    contactLanguage,
    dateOfBirth,
    isDetailsForm,
    intl,
    isAlreadyExistsInB2c,
    isSendingInvitation = false,
    sendInvitation = noop,
}) => (
    <>
        <PageContentDetailsForm>
            <PageContentDetailsFormStatusItem titleId="agentDetails.status" isActive={isActive} />
            <PageContentDetailsFormItem titleId="agentDetails.email" value={email} />
            <PageContentDetailsFormItem titleId="agentDetails.firstName" value={firstName} />
            <PageContentDetailsFormItem titleId="agentDetails.surname" value={surname} />
            {isDetailsForm && (
                <PageContentDetailsFormItem
                    data-ut-id="contact-date-Of-birth"
                    titleId="agentDetails.dateOfBirth"
                    value={dateOfBirth}
                />
            )}
            <PageContentDetailsFormItem
                titleId="agentDetails.contactPhoneNumber"
                value={contactNumber}
            />
            {isDetailsForm && (
                <PageContentDetailsFormItem
                    titleId="agentDetails.mobilePhoneNumber"
                    value={mobileNumber}
                />
            )}
            {contactLanguage && (
                <PageContentDetailsFormItem
                    data-ut-id="contact-language"
                    titleId="agentDetails.contactLanguage"
                    value={intl.formatMessage({
                        id: `references.contactLanguages.${contactLanguage}`,
                    })}
                />
            )}
            {!isAlreadyExistsInB2c && (
                <ActionButton
                    className="button action"
                    action={isSendingInvitation}
                    disabled={!isActive}
                    onClick={sendInvitation}
                >
                    <FormattedMessage id="misc.resend" />
                </ActionButton>
            )}
        </PageContentDetailsForm>
    </>
);

AgentDetailsComponent.propTypes = {
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    dateOfBirth: PropTypes.string,
    intl: intlShape,
    contactNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    contactLanguage: PropTypes.string,
    isActive: PropTypes.bool,
    isDetailsForm: PropTypes.bool,
    isAlreadyExistsInB2c: PropTypes.bool,
    isSendingInvitation: PropTypes.bool,
    sendInvitation: PropTypes.func,
};

AgentDetailsComponent.defaultProps = {
    isDetailsForm: true,
};

export const AgentDetails = injectIntl(memo(AgentDetailsComponent));
