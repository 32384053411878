import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {ButtonsGroup} from 'components/common/ButtonsGroup';
import {ActionButton} from 'components/common/ActionButton';

const ButtonsGroupSaveEntityComponent = ({id, onSubmit, onCancel, isSaving}) => {
    const onCancelClick = useCallback(() => onCancel(id), [onCancel, id]);

    return (
        <ButtonsGroup onCancel={onCancelClick}>
            <ActionButton className="action" onClick={onSubmit} action={isSaving}>
                <FormattedMessage id="misc.save" />
            </ActionButton>
        </ButtonsGroup>
    );
};

ButtonsGroupSaveEntityComponent.propTypes = {
    id: PropTypes.number,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};

export const ButtonsGroupSaveEntity = memo(ButtonsGroupSaveEntityComponent);
