import {setGeneralError} from 'features/ErrorBoundary';
import {AsyncAction} from 'core/actionHelpers';

const failureActionNamePostfix = AsyncAction.getFailureActionNamePostfix();

export const serverErrorHandlerMiddleware = store => next => action => {
    if (action.type.endsWith(failureActionNamePostfix)) {
        store.dispatch(setGeneralError.action(action.payload));
    }
    return next(action);
};
