import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import './styles.scss';

const resolveText = value => {
    if (value) {
        return <span>{value}</span>;
    }
    return (
        <span className="page-content-details-form-item-empty">
            <FormattedMessage id="agentDetails.informationIsMissing" />
        </span>
    );
};

export const defaultFieldWidthIndex = 4;
export const PageContentDetailsFormItem = ({titleId, value, widthIndex}) => (
    <Row>
        <Col xs={widthIndex || defaultFieldWidthIndex} className="page-content-details-form-item">
            <FormattedMessage id={titleId} />
        </Col>
        <Col xs={6}>{resolveText(value)}</Col>
    </Row>
);

PageContentDetailsFormItem.propTypes = {
    titleId: PropTypes.string,
    value: PropTypes.string,
    widthIndex: PropTypes.number,
};
