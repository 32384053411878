import {reduxForm} from 'redux-form';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {WithMappedDropdownOptions} from 'core/hoc/MappedDropdownOptions';
import {AgentCreate} from 'components/Agent/AgentCreate';

import {containerId} from 'features/Agent/common/reducer';
import {
    selectContactNumberValue,
    selectMobileNumberValue,
    selectContactNumberError,
    selectMobileNumberError,
} from 'features/Agent/common/selectors';
import {makeSelectEmailRegisteredError} from 'features/Agent/AgentCreate/selectors';

export const AgentReduxForm = reduxForm({
    form: containerId,
})(AgentCreate);

const mapStateToProps = () => {
    const selectEmailRegisteredError = makeSelectEmailRegisteredError();

    return state => ({
        emailValidationError: selectEmailRegisteredError(state),
        contactNumberCode: selectContactNumberValue(state),
        contactNumberError: selectContactNumberError(state),
        mobileNumberCode: selectMobileNumberValue(state),
        mobileNumberError: selectMobileNumberError(state),
    });
};

export const AgentForm = connectWithIntl(mapStateToProps)(
    WithMappedDropdownOptions(AgentReduxForm)
);
