import produce from 'immer';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import {ACTION_STATUS} from 'core/constants';
import {BaseAction} from 'core/actionHelpers';

export const selectActionProperty = (
    containerState,
    propertyPath,
    path = null,
    defaultValue = null
) => {
    const propertyState = get(containerState, propertyPath);

    if (propertyState === undefined) {
        return defaultValue;
    }

    if (path === null) {
        return propertyState;
    }

    return get(propertyState, path, defaultValue);
};

export const selectActionData = (containerState, actionType, path, defaultValue) =>
    selectActionProperty(containerState, actionType, path, defaultValue);

export const selectAsyncActionData = (containerState, actionType, path, defaultValue) =>
    selectActionProperty(containerState, [actionType, 'data'], path, defaultValue);

export const selectAsyncActionCache = (containerState, actionType, path, defaultValue) =>
    selectActionProperty(containerState, [actionType, 'cache'], path, defaultValue);

export const selectAsyncActionError = (containerState, actionType, path, defaultValue) =>
    selectActionProperty(containerState, [actionType, 'error'], path, defaultValue);

export const selectAsyncActionStatus = (containerState, actionType) =>
    selectActionProperty(containerState, [actionType, 'status']);

export const selectIsInitialStatus = (containerState, actionType) =>
    selectAsyncActionStatus(containerState, actionType) === ACTION_STATUS.INITIAL;

export const selectIsInProgressStatus = (containerState, actionType) =>
    selectAsyncActionStatus(containerState, actionType) === ACTION_STATUS.IN_PROGRESS;

export const selectIsSuccessStatus = (containerState, actionType) =>
    selectAsyncActionStatus(containerState, actionType) === ACTION_STATUS.SUCCESS;

export const selectIsFailedStatus = (containerState, actionType) =>
    selectAsyncActionStatus(containerState, actionType) === ACTION_STATUS.FAILURE;

export const selectIsFinishedStatus = (containerState, actionType) => {
    const status = selectAsyncActionStatus(containerState, actionType);
    return status === ACTION_STATUS.FAILURE || status === ACTION_STATUS.SUCCESS;
};

export const createReducer = (
    actions = null,
    initialState = null,
    actionToHandlerMap = new Map()
) => {
    let _initialState = {};

    if (actions !== null) {
        actions.forEach(action => {
            _initialState[action.type] = action.initialState;
        });
    }

    _initialState = defaults({}, initialState, _initialState);

    return produce((draft, action) => {
        if (actionToHandlerMap.has(action.type)) {
            actionToHandlerMap.get(action.type)(draft, action);
            return;
        }

        if (actions === null) {
            return;
        }

        for (let item of actions) {
            if (!(item instanceof BaseAction)) {
                continue;
            }

            const _actionToHandlerMap = item.actionToHandlerMap;

            if (!_actionToHandlerMap.has(action.type)) {
                continue;
            }

            _actionToHandlerMap.get(action.type)(draft, action);
            return;
        }
    }, _initialState);
};
