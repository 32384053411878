import React from 'react';
import PropTypes from 'prop-types';

import {ProducerAccountFields} from './ProducerAccountFields';
import {ProducerAccountsWrapper} from './ProducerAccountsWrapper';

import './styles.scss';

export class ProducerAccounts extends React.Component {
    static propTypes = {
        fields: PropTypes.object,

        amount: PropTypes.number,
        options: PropTypes.array,
        savedProducerAccounts: PropTypes.array,
        addButtonDisabled: PropTypes.bool,
        formError: PropTypes.arrayOf({
            id: PropTypes.string,
        }),
        onProducerAccountChange: PropTypes.func,
        onAddButtonClick: PropTypes.func,
        change: PropTypes.func,
        onRemoveButtonClick: PropTypes.func,
    };

    render() {
        const {
            fields,
            change,
            amount,
            options,
            addButtonDisabled,
            onRemoveButtonClick,
            onProducerAccountChange,
            onAddButtonClick,
            formErrors,
            savedProducerAccounts,
        } = this.props;

        const isRemovable = fields.length > 1;

        return (
            <ProducerAccountsWrapper
                amount={amount}
                addButtonDisabled={addButtonDisabled}
                onAddButtonClick={onAddButtonClick}
            >
                {fields.map((path, index) => (
                    <ProducerAccountFields
                        key={index}
                        path={path}
                        change={change}
                        index={index}
                        options={options}
                        values={fields.get(index)}
                        isRemovable={isRemovable}
                        onProducerAccountChange={onProducerAccountChange}
                        onRemoveButtonClick={onRemoveButtonClick}
                        formError={formErrors[index]}
                        savedProducerAccounts={savedProducerAccounts}
                    />
                ))}
            </ProducerAccountsWrapper>
        );
    }
}
