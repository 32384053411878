import {TableSearch} from 'components/common/Table/TableSearch/TableSearch';
import {connectWithIntl} from 'core/services/connectWithIntl';

import {
    makeSelectAgentShowSearchResults,
    makeSelectShowSearchField,
    selectAgentSearchValue,
    selectAgentTotalItemsCount,
    selectShowCrossButton,
    selectIsLoading,
} from '../../selectors';
import {searchAgentList, setAgentSearchValue} from '../../reducer';

const mapStateToProps = () => {
    const selectAgentShowSearchResults = makeSelectAgentShowSearchResults();
    const selectShowSearchField = makeSelectShowSearchField();

    return state => ({
        value: selectAgentSearchValue(state),
        results: selectAgentTotalItemsCount(state),
        loading: selectIsLoading(state),
        showResults: selectAgentShowSearchResults(state),
        showSearchField: selectShowSearchField(state),
        showCrossButton: selectShowCrossButton(state),
    });
};

export const AgentListSearch = connectWithIntl(mapStateToProps, {
    onChange: setAgentSearchValue.action,
    onSubmit: searchAgentList.action,
})(TableSearch);
