import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Tooltip} from 'components/common/Tooltip';

import {CheckBoxField} from 'components/common/Form/CheckBoxField';
import {mappedFieldsLocalization} from 'core/services/localeService/fieldsConfig';
import {producerAccountRoles} from 'core/constants';

import './styles.scss';

export class RolesField extends React.PureComponent {
    static propTypes = {
        options: PropTypes.array,
        input: PropTypes.object,
        change: PropTypes.func,
        arrayPush: PropTypes.func,
    };

    componentDidMount() {
        this.resolveDefaultChecked();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.producerAccountId !== this.props.producerAccountId) {
            this.resolveDefaultChecked();
        }
    }

    render() {
        const {
            input: {value, name},
            options,
        } = this.props;
        return (
            <section className="roles">
                {options.map((role, index) => {
                    const roleName = role.name;
                    return (
                        <div className="roles-field" key={roleName}>
                            <CheckBoxField
                                tabIndex={-1}
                                name={`${name}[${index}]`}
                                value={roleName}
                                label={this.resolveLabel(roleName)}
                                checked={value.includes(roleName)}
                                onChange={event => this.handleChange(event, roleName)}
                            />
                            <Tooltip>
                                <span className="tooltip-title">{this.resolveLabel(roleName)}</span>
                                {mappedFieldsLocalization.rolesInfo[roleName].map(roleInfo => (
                                    <p key={roleInfo}>
                                        <span>- </span>
                                        <FormattedMessage id={roleInfo} />
                                    </p>
                                ))}
                            </Tooltip>
                            {this.resolveInfoMessage(roleName)}
                        </div>
                    );
                })}
            </section>
        );
    }

    resolveLabel = role => <FormattedMessage id={mappedFieldsLocalization.roles[role]} />;

    resolveDefaultChecked = () => {
        const {change, options, input} = this.props;

        if (!options.length) {
            return;
        }

        const role = options.map(option => option.selected && option.name).filter(option => option);

        if (role.length) {
            const isSameStr = input.value.some(str => str === role[0]);
            if (!isSameStr) {
                change(input.name, role);
            }
        }
    };

    resolveInfoMessage = role => {
        if (role !== producerAccountRoles.consultCommissions) {
            return null;
        }
        const {
            input: {value},
        } = this.props;

        if (!value.includes(producerAccountRoles.consultCommissions)) {
            return null;
        }
        return (
            <div className="info-message">
                <FormattedMessage
                    id="fields.agentCreate.producerAccount.roles.CONSULT_COMMISSIONS.infoMessage"
                    values={{
                        boldMessage: (
                            <span className="info-message-bold">
                                <FormattedMessage id="fields.agentCreate.producerAccount.roles.CONSULT_COMMISSIONS.boldInfoMessage" />
                            </span>
                        ),
                    }}
                />
            </div>
        );
    };

    handleChange = (event, role) => {
        const {onChange, value} = this.props.input;
        const valuesArr = [...value];
        if (event.target.checked) {
            valuesArr.push(role);
        } else {
            valuesArr.splice(valuesArr.indexOf(role), 1);
        }
        onChange(valuesArr);
    };
}
