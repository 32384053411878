import React from 'react';
import PropTypes from 'prop-types';

import {AgentEditDentActions} from 'features/Agent/AgentEdit/components/AgentEditDentActions';
import {AgentEditRemoveModal} from 'features/Agent/AgentEdit/components/AgentEditRemoveModal';
import {AgentEditStatusModal} from 'features/Agent/AgentEdit/components/AgentEditStatusModal';
import {AgentEditActions} from 'features/Agent/AgentEdit/components/AgentEditActions';
import {FeatureToggling} from '../../../../../core/FeatureToggling';

export const AgentEditHeader = ({isDentAgent}) => {
    if (isDentAgent) {
        return <AgentEditDentActions />;
    }

    return (
        <>
            {!FeatureToggling.crmIntegrationEnabled && <AgentEditRemoveModal />}
            <AgentEditStatusModal />
            <AgentEditActions />
        </>
    );
};

AgentEditHeader.propTypes = {
    isDentAgent: PropTypes.bool,
};
