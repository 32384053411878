import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';
import {getProducerAccountLabel} from 'core/services/utils';

export const TableProducerAccountCellComponent = ({intl, producerAccount}) => {
    if (!producerAccount) {
        return null;
    }

    return getProducerAccountLabel(intl, producerAccount);
};

TableProducerAccountCellComponent.propTypes = {
    intl: intlShape,
    producerAccount: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.number,
    }),
};

export const TableProducerAccountCell = injectIntl(TableProducerAccountCellComponent);
