import React from 'react';
import PropTypes from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

import {noop} from 'core/services/utils';
import {optionsShape, optionShape} from 'core/propTypes';
import {withClickOutside, withFocusinOutside} from 'core/hoc/WithEventOutside';

import {DropdownInput, DropdownOptions} from './components';

import './styles.scss';

const KeyCodes = {
    down: 40,
    up: 38,
    enter: 13,
    esc: 27,
};

export class DropdownCommonComponent extends React.Component {
    static propTypes = {
        options: optionsShape,
        value: PropTypes.oneOfType([PropTypes.any, optionShape]),
        name: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,

        loading: PropTypes.bool,
        creatable: PropTypes.bool,
        clearable: PropTypes.bool,
        searchable: PropTypes.bool,
        editable: PropTypes.bool,
        filterOnChange: PropTypes.bool,
        changeOnLoadingFinished: PropTypes.bool,

        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onInputChange: PropTypes.func,
        formatValue: PropTypes.func,
    };

    static defaultProps = {
        options: [],
        value: '',
        name: '',
        placeholder: '',
        disabled: false,

        loading: false,
        creatable: false,
        clearable: false,
        searchable: false,
        editable: true,
        filterOnChange: false,

        onFocus: noop,
        onBlur: noop,
        onChange: noop,
        onInputChange: noop,
        formatValue: null,
    };

    state = {
        inputValue: '',
        selectedValue: null,
        filteredOptions: [],
        showOptions: false,
        focusedIndex: -1,
        lastSubmittedValue: null,
        waitingForOptionsLoad: false,
    };

    dropdownInput = React.createRef();

    componentDidMount() {
        this.propsChanged.value();
        this.propsChanged.options();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.propsChanged.value();
        }
        if (prevProps.options !== this.props.options) {
            this.propsChanged.options();
        }
        if (prevProps.loading !== this.props.loading) {
            this.propsChanged.loading();
        }
    }

    render() {
        const {
            name,
            placeholder,
            disabled,
            loading,
            creatable,
            clearable,
            searchable,
            editable,
        } = this.props;

        const {filteredOptions, showOptions, selectedValue, focusedIndex, inputValue} = this.state;

        return (
            <div ref={this.withEventOutside._setHocRefsAndHandlers} className="searchable-dropdown">
                <DropdownInput
                    ref={this.dropdownInput}
                    name={name}
                    value={inputValue}
                    loading={loading}
                    placeholder={placeholder}
                    disabled={disabled}
                    open={showOptions}
                    clearable={clearable}
                    searchable={searchable}
                    onChange={this.input.onChange}
                    onKeyDown={this.input.onKeyDown}
                    onBlur={this.input.onBlur}
                    onFocus={this.input.onFocus}
                    onClear={this.input.onClear}
                    onArrowClick={this.input.onArrowClick}
                    onClick={this.input.onClick}
                    editable={editable}
                />
                <DropdownOptions
                    show={showOptions}
                    options={filteredOptions}
                    creatable={creatable}
                    loading={loading}
                    selectedValue={selectedValue}
                    focusedIndex={focusedIndex}
                    onItemClick={this.options.onItemClick}
                />
            </div>
        );
    }

    localState = {
        selectedValue: selectedValue => this.setState({selectedValue}),
        inputValue: inputValue => this.setState({inputValue}),
        filteredOptions: filteredOptions => this.setState({filteredOptions}),
        showOptions: showOptions => this.setState({showOptions}),
        focusedIndex: focusedIndex => this.setState({focusedIndex}),
        lastSubmittedValue: lastSubmittedValue => this.setState({lastSubmittedValue}),
        waitingForOptionsLoad: waitingForOptionsLoad => this.setState({waitingForOptionsLoad}),
    };

    editablePropsChangedHandlers = {
        value: () => {
            const {value, options} = this.props;

            if (
                value === this.state.lastSubmittedValue ||
                (this.state.lastSubmittedValue === null && value === '')
            ) {
                return;
            }

            let newSelectedValue = value;
            let newInputValue = String(value);

            if (isPlainObject(value)) {
                newSelectedValue = value.value;
                newInputValue = value.label;
            } else {
                const selectedOption = options.find(option => option.value === value);

                if (selectedOption) {
                    newInputValue = selectedOption.label;
                }
            }

            this.localState.lastSubmittedValue(newSelectedValue);
            this.localState.selectedValue(newSelectedValue);
            this.localState.inputValue(newInputValue);

            this._updateFilteredOptions(newInputValue, newSelectedValue);
        },
        options: () => {
            this._updateFilteredOptions(this.state.inputValue, this.state.selectedValue);
        },
        loading: () => {
            this._delayedOnChange();
        },
    };

    notEditablePropsChangedHandlers = {
        value: () => {
            const {value} = this.props;

            if (
                value === this.state.lastSubmittedValue ||
                (this.state.lastSubmittedValue === null && value === '')
            ) {
                return;
            }

            let newSelectedValue = value;
            let newInputValue = String(value);

            this.localState.selectedValue(newSelectedValue);
            this.localState.inputValue(newInputValue);
        },
        options: () => {
            const {options} = this.props;
            this.localState.filteredOptions(options);
        },
        loading: () => {
            return;
        },
    };

    propsChanged = this.props.editable
        ? this.editablePropsChangedHandlers
        : this.notEditablePropsChangedHandlers;

    editableInputHandlers = {
        onChange: event => {
            let {value} = event.target;
            const {formatValue} = this.props;
            if (formatValue) {
                value = formatValue(value);
            }

            this.localState.inputValue(value);
            this.localState.showOptions(true);

            this._updateFilteredOptions(value, this.state.selectedValue);

            this.props.onInputChange(value);
        },
        _setFocusedOptionValue: option => {
            this.localState.inputValue(option.label);
        },
        onBlur: () => {
            const {options, creatable, onBlur} = this.props;
            const {inputValue} = this.state;
            let newSelectedValue = null;

            const selectedOption = options.find(option => option.label === inputValue);
            if (selectedOption) {
                newSelectedValue = selectedOption.value;
            }

            if (newSelectedValue === null) {
                if (creatable) {
                    newSelectedValue = inputValue;
                }
            }

            this.localState.showOptions(false);
            this.localState.selectedValue(newSelectedValue);

            this._onChange(newSelectedValue);
            onBlur();
        },
        onClear: event => {
            event.preventDefault();

            const inputValue = '';

            this._updateFilteredOptions(inputValue, null);

            this.localState.showOptions(true);

            this.localState.inputValue(inputValue);
            this.props.onInputChange(inputValue);

            this._onChange(null);

            this.dropdownInput.current && this.dropdownInput.current.focus();
        },
        onClick: () => {
            this.localState.showOptions(true);
        },
        onFocus: () => {
            this.localState.showOptions(true);
            this.props.onFocus();
        },
    };

    notEditableInputHandlers = {
        onChange: () => {},
        _setFocusedOptionValue: option => {
            this.localState.inputValue(option.value);
        },
        onBlur: () => {
            const {onBlur} = this.props;
            this.localState.showOptions(false);
            onBlur();
        },
        onClear: event => {
            event.preventDefault();
        },
        onClick: () => {
            this.localState.showOptions(!this.state.showOptions);
        },
        onFocus: () => {
            this.props.onFocus();
        },
    };

    defaultInputHandlers = {
        onKeyDown: event => {
            const keyCode = event.keyCode;

            if (!this.state.showOptions) {
                if (keyCode === KeyCodes.down) {
                    event.preventDefault();
                    this.localState.showOptions(true);
                }
                return;
            }

            if (keyCode === KeyCodes.esc) {
                this.localState.showOptions(false);
                return;
            }

            if (keyCode === KeyCodes.down || keyCode === KeyCodes.up) {
                event.preventDefault();
                this.input._handleArrowNavigation(keyCode);
                return;
            }

            if (keyCode === KeyCodes.enter) {
                event.preventDefault();
                this.input._selectFocusedOption();
                this.localState.showOptions(false);
                return;
            }
        },
        _handleArrowNavigation: keyCode => {
            const {loading} = this.props;
            const {filteredOptions, focusedIndex} = this.state;

            if (loading || filteredOptions.length === 0) {
                return;
            }

            let nextIndex = 0;
            if (keyCode === KeyCodes.down) {
                nextIndex = focusedIndex + 1;
            } else if (keyCode === KeyCodes.up) {
                nextIndex = focusedIndex - 1;
            }

            const lastIndex = filteredOptions.length - 1;

            if (nextIndex > lastIndex) {
                nextIndex = 0;
            }

            if (nextIndex < 0) {
                nextIndex = lastIndex;
            }
            this.localState.focusedIndex(nextIndex);
        },
        _selectFocusedOption: () => {
            const {filteredOptions, focusedIndex, inputValue} = this.state;

            let selectedValue = null;

            if (focusedIndex > filteredOptions.length - 1 || focusedIndex < 0) {
                if (this.props.creatable) {
                    selectedValue = inputValue;
                }
            } else {
                const option = filteredOptions[focusedIndex];
                if (option.disabled && option.value !== this.state.selectedValue) {
                    return;
                }

                selectedValue = option.value;

                this.input._setFocusedOptionValue(option);
            }

            this.localState.selectedValue(selectedValue);
            this._onChange(selectedValue);
        },
        onArrowClick: event => {
            event.preventDefault();
            this.localState.showOptions(!this.state.showOptions);
        },
    };

    input = this.props.editable
        ? {...this.defaultInputHandlers, ...this.editableInputHandlers}
        : {...this.defaultInputHandlers, ...this.notEditableInputHandlers};

    editableOptionsHandlers = {
        onItemClick: (event, value) => {
            event.preventDefault();
            const selectedOption = this.state.filteredOptions.find(
                option => option.value === value
            );
            if (
                !selectedOption ||
                (selectedOption.disabled && selectedOption.value !== this.state.selectedValue)
            ) {
                return;
            }

            this.localState.showOptions(false);
            this.localState.selectedValue(value);

            this.localState.inputValue(selectedOption.label);
            this._updateFilteredOptions(selectedOption.label, value);

            this._onChange(value);
        },
    };

    notEditableOptionsHandlers = {
        onItemClick: (event, value) => {
            event.preventDefault();
            this.localState.showOptions(false);
            this.localState.selectedValue(value);
            this.localState.inputValue(value);
            this._onChange(value);
        },
    };

    options = this.props.editable ? this.editableOptionsHandlers : this.notEditableOptionsHandlers;

    withEventOutside = {
        _setHocRefsAndHandlers: ref => {
            const {
                setDomNodeOnClickOutside,
                setDomNodeOnFocusinOutside,

                setOnClickOutside,
                setOnFocusinOutside,
            } = this.props;

            setDomNodeOnClickOutside(ref);
            setDomNodeOnFocusinOutside(ref);

            setOnClickOutside(this.withEventOutside._hideOptions);
            setOnFocusinOutside(this.withEventOutside._hideOptions);
        },
        _hideOptions: () => {
            this.localState.showOptions(false);
        },
    };

    _updateFilteredOptions = (inputValue, selectedValue) => {
        const {options, filterOnChange, searchable} = this.props;

        let filteredOptions = options;

        if (!searchable && filterOnChange && isString(inputValue)) {
            filteredOptions = options.filter(option => {
                if (
                    isString(option.label) &&
                    !option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                ) {
                    return false;
                }
                return option;
            });

            if (filteredOptions.length === 1 && filteredOptions[0].label === inputValue) {
                filteredOptions = options;
            }
        }

        this.localState.filteredOptions(filteredOptions);

        const selectedOptionIndex = filteredOptions.findIndex(
            option => option.value === selectedValue
        );
        this.localState.focusedIndex(selectedOptionIndex);
    };

    _delayedOnChange = () => {
        const {loading, changeOnLoadingFinished, onChange} = this.props;

        if (loading || !changeOnLoadingFinished) {
            return;
        }

        const {waitingForOptionsLoad, lastSubmittedValue} = this.state;

        if (waitingForOptionsLoad) {
            this.localState.waitingForOptionsLoad(false);
            onChange(lastSubmittedValue);
        }
    };

    _onChange = value => {
        this.localState.lastSubmittedValue(value);

        const {changeOnLoadingFinished, loading, onChange} = this.props;

        if (changeOnLoadingFinished && loading) {
            this.localState.waitingForOptionsLoad(true);
            return;
        }
        onChange(value);
    };
}

export const DropdownCommon = withClickOutside(withFocusinOutside(DropdownCommonComponent));
