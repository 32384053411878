import React from 'react';
import PropTypes from 'prop-types';
import {intlShape, FormattedMessage} from 'react-intl';

import {ActionButton} from '../../ActionButton';
import {TextField} from '../../Form/TextField';
import {withForwardRef} from 'core/services/utils';

import './styles.scss';

const TextFieldWithRef = withForwardRef(TextField);

export class TableSearch extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        results: PropTypes.number,
        loading: PropTypes.bool,
        showCrossButton: PropTypes.bool,
        showResults: PropTypes.bool,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        intl: intlShape,
        showSearchField: PropTypes.bool,
        tableSearchPlaceholderId: PropTypes.string.isRequired,
    };

    static defaultProps = {
        showSearchField: true,
    };

    textInput = React.createRef();

    render() {
        const {
            showCrossButton,
            value,
            results,
            loading,
            showResults,
            intl,
            showSearchField,
            tableSearchPlaceholderId,
        } = this.props;

        if (!showSearchField) {
            return null;
        }

        return (
            <>
                <form className="table-search" onSubmit={this.handleSubmit}>
                    <div className="search-input">
                        <TextFieldWithRef
                            value={value}
                            onChange={this.handleSearchValueChange}
                            placeholder={intl.formatMessage({id: tableSearchPlaceholderId})}
                            ref={this.textInput}
                        />
                        {showCrossButton && (
                            <span className="cross-button" onClick={this.handleClear}>
                                +
                            </span>
                        )}
                    </div>
                    <ActionButton type="submit" className="form-action" disabled={loading}>
                        <FormattedMessage id="table.search.button" />
                    </ActionButton>
                </form>
                <div>
                    {showResults && (
                        <>
                            <FormattedMessage id="table.search.results" />: {results}
                        </>
                    )}
                </div>
            </>
        );
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.onSubmit();
    };

    handleClear = e => {
        const {onChange, showResults} = this.props;
        onChange('');

        if (this.textInput.current) {
            this.textInput.current.value = '';
            this.textInput.current.focus();
        }

        if (showResults) {
            this.handleSubmit(e);
        }
    };

    handleSearchValueChange = e => this.props.onChange(e.target.value);
}
