import {createBrowserHistory} from 'history';

import {ROUTES} from './routes';
export * from './routes';

class History {
    browserHistory = createBrowserHistory();

    goTo = path => {
        this.browserHistory.push(path);
    };

    goToBrokerManagers = () => {
        this.goTo(ROUTES.brokerManagers);
    };

    goToBrokerManagersDetails = brokerManagerId => {
        this.goTo(`${ROUTES.brokerManagers}/${brokerManagerId}`);
    };

    goToBrokerManagersEdit = brokerManagerId => {
        this.goTo(`${ROUTES.brokerManagers}/${brokerManagerId}/edit`);
    };

    goToBrokerManagersCreate = () => {
        this.goTo(`${ROUTES.brokerManagers}/create`);
    };

    goToAgents = () => {
        this.goTo(ROUTES.agents);
    };

    goToAgentDetails = agentId => {
        this.goTo(`${ROUTES.agents}/${agentId}`);
    };

    goToAgentEdit = agentId => {
        this.goTo(`${ROUTES.agents}/${agentId}/edit`);
    };

    goToAgentCreate = () => {
        this.goTo(`${ROUTES.agents}/create`);
    };

    goToNotFound = () => {
        this.goTo(ROUTES.notFound);
    };

    goToRoot = () => {
        this.goTo(ROUTES.default);
    };
}

export const history = new History();
