import {makeSelectIsActive} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {GroupEntityStatusPanel} from 'components/common/GroupEntityStatus/GroupEntityStatusPanel';

const mapStateToProps = () => {
    const selectIsActive = makeSelectIsActive();

    return state => ({isActive: selectIsActive(state)});
};

export const BrokerManagerEditStatus = connectWithIntl(mapStateToProps)(GroupEntityStatusPanel);
