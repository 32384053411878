import * as Browser from 'bowser';
import {isTrue} from 'core/services/utils';
import isUndefined from 'lodash/isUndefined';

const browser = Browser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();

const supportedBrowser = () =>
    browserName === 'Chrome' || browserName === 'Microsoft Edge' || browserName === 'Safari';

const resolveFeatureVisibility = (featureName, defaultValue = true) => {
    // Should show features in local environment. In this case environment variable will be undefined
    if (isUndefined(featureName)) {
        return defaultValue;
    }

    return isTrue(featureName);
};

// Toggle features depending on environment variables
export const FeatureToggling = {
    supportedBrowser: supportedBrowser(),
    crmIntegrationEnabled: resolveFeatureVisibility(process.env.REACT_APP_FEATURES_CRM_INTEGRATION_ENABLED),
};
