import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {noop} from 'core/services/utils';

import {CirclesLoader} from 'components/common/CirclesLoader';

import './styles.scss';

export const ActionButton = ({
    type = 'button',
    className,
    action = false,
    disabled,
    onClick = noop,
    children,
}) => (
    <button
        type={type}
        className={classNames('button', className, {handling: action})}
        disabled={action || disabled}
        onClick={onClick}
    >
        {action && <CirclesLoader />}
        {children}
    </button>
);

ActionButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    action: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
};
