import {Action, CaughtCachedAsyncAction} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'AgentCreate';

export const initializeAgentData = new Action(`${containerId}/AGENT_DATA_INITIALIZE`);
export const clearAgentData = new Action(`${containerId}/AGENT_DATA_CLEAR`);
export const createAgent = new Action(`${containerId}/AGENT_CREATE`);
export const saveCreatedAgent = new CaughtCachedAsyncAction(`${containerId}/AGENT_CREATED_SAVE`);

export const agentsActions = [saveCreatedAgent];

export const AgentCreateReducer = createReducer(agentsActions);
