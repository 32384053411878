import {
    emailIsValid,
    required,
    dateIsValid,
    ageIsValid,
    checkSuspiciousSymbolsFirstname,
    checkSuspiciousSymbolsSurname,
} from 'core/services/validators';

export const text = {maxLength: 50};

export const requiredText = {
    ...text,
    validate: [required],
};

export const dateOfBirth = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    validate: [dateIsValid, ageIsValid],
};

export const firstNameField = {
    ...text,
    validate: [required, checkSuspiciousSymbolsFirstname],
};

export const surnameField = {
    ...text,
    validate: [required, checkSuspiciousSymbolsSurname],
};

export const requiredEmail = {validate: [required, emailIsValid]};
