import {CryptoUtils, UserAgentApplication} from 'msal';
import {ROOT_URI} from 'core/constants';

export function getHashFromUrl(urlStringOrFragment) {
    const hashIndex1 = urlStringOrFragment.indexOf('#');
    const hashIndex2 = urlStringOrFragment.indexOf('#/');
    if (hashIndex2 > -1) {
        return urlStringOrFragment.substring(hashIndex2 + 2);
    }
    if (hashIndex1 > -1) {
        return urlStringOrFragment.substring(hashIndex1 + 1);
    }
    return '';
}

function deserializeHash(urlFragment) {
    const hash = getHashFromUrl(urlFragment);
    return CryptoUtils.deserialize(hash);
}

export function initializeMsalProvider({authority, clientId}) {
    const hashObject = deserializeHash(window.location.hash);
    //TODO Workaround to solve https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1207
    window.opener = null;
    const msalProvider = new UserAgentApplication({
        auth: {
            authority,
            clientId,
            redirectUri: ROOT_URI,
            postLogoutRedirectUri: ROOT_URI,
            validateAuthority: false,
            navigateToLoginRequestUrl: false,
        },
        cache: {cacheLocation: 'localStorage', storeAuthStateInCookie: false},
        system: {loadFrameTimeout: 0},
    });
    msalProvider.handleRedirectCallback(() => {});
    return {provider: msalProvider, hashObject};
}
