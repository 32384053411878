import {AsyncAction} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'AgentDetails';

export const fetchAgent = new AsyncAction(`${containerId}/FETCH_DATA`);

export const sendInvitation = new AsyncAction(`${containerId}/SEND_INVITATION`);

export const AgentDetailsReducer = createReducer([fetchAgent, sendInvitation]);
