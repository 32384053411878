import {connectWithIntl} from 'core/services/connectWithIntl';
import {ButtonsGroupSaveEntity} from 'components/common/ButtonsGroup/ButtonsGroupSaveEntity';
import {selectAgentId, handleSaveDentAgent, selectIsSavingDentAgent} from 'features/Agent/common';
import {history} from 'core/services/history';

const mapStateToProps = state => ({
    id: selectAgentId(state),
    isSaving: selectIsSavingDentAgent(state),
    onCancel: history.goToAgentDetails,
});

export const AgentEditDentActions = connectWithIntl(mapStateToProps, {
    onSubmit: handleSaveDentAgent.action,
})(ButtonsGroupSaveEntity);
