import React from 'react';
import {Header} from 'components/Header';
import {SupportedLocales} from 'core/constants';
import {FeatureToggling} from 'core/FeatureToggling';
import errorIcon from 'assets/images/unsupport.svg';
import logoIcon from 'assets/images/logo.svg';
import messages from 'translations/messages.json';
import './styles.scss';

const fr = 'fr';
const nl = 'nl';
export const frenchDocumentTitle = 'Gestion des utilisateurs';
const BROWSER = {
    CHROME: 'chrome',
    EDGE: 'edge',
    SAFARI: 'safari',
};
const BROWSER_LINK_LOCALE = {
    [BROWSER.CHROME]: {
        [SupportedLocales.nlBE]: nl,
        [SupportedLocales.frFR]: fr,
    },
    [BROWSER.SAFARI]: {
        [SupportedLocales.nlBE]: 'nl_NL',
        [SupportedLocales.frFR]: 'fr_FR',
    },
};

export const isFrench = value => value.toLowerCase().startsWith(fr);

export const resolveBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;

    if (isFrench(language)) {
        return SupportedLocales.frFR;
    }

    return SupportedLocales.nlBE;
};
export const resolveSupportedBrowsersLinks = language => {
    return {
        edge: `https://www.microsoft.com/en-us/edge`,
        chrome: `https://www.google.com/intl/${
            BROWSER_LINK_LOCALE[BROWSER.CHROME][language]
        }/chrome/`,
        safari: `https://support.apple.com/${
            BROWSER_LINK_LOCALE[BROWSER.SAFARI][language]
        }/downloads/safari`,
    };
};

export class UnsupportedBrowser extends React.Component {
    language = resolveBrowserLanguage();

    componentDidMount() {
        if (isFrench(this.language)) {
            document.title = frenchDocumentTitle;
        }
    }

    render() {
        if (FeatureToggling.supportedBrowser) {
            return this.props.children;
        }

        const {chrome, edge, safari} = resolveSupportedBrowsersLinks(this.language);

        return (
            <>
                <Header className="header-app">
                    <div className="header-app-left">
                        <div className="header-app-logo">
                            <img src={logoIcon} alt="logo" />
                        </div>
                    </div>
                </Header>
                <div className="unsupported-browser">
                    <img className="unsupported-browser-img" src={errorIcon} alt="unsupported" />
                    <h2 className="unsupported-browser-title">
                        {messages[this.language].unsupportedBrowser.warn}
                    </h2>
                    <p className="unsupported-browser-info">
                        {messages[this.language].unsupportedBrowser.apologies}
                    </p>
                    <p>{messages[this.language].unsupportedBrowser.recommend}</p>
                    <p>
                        <a rel="noopener noreferrer" target="_blank" href={edge}>
                            Edge
                        </a>
                        <span>, </span>
                        <a rel="noopener noreferrer" target="_blank" href={chrome}>
                            Google Chrome
                        </a>
                        <span> </span>
                        <span>{messages[this.language].unsupportedBrowser.or}</span>
                        <span> </span>
                        <a rel="noopener noreferrer" target="_blank" href={safari}>
                            Safari
                        </a>
                        <span>, </span>
                    </p>
                    <p>{messages[this.language].unsupportedBrowser.open}.</p>
                </div>
            </>
        );
    }
}
