import {AsyncAction} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'BrokerManagerDetails';

export const fetchBrokerManager = new AsyncAction(`${containerId}/FETCH_DATA`);

export const sendInvitation = new AsyncAction(`${containerId}/SEND_INVITATION`);

export const BrokerManagerDetailsReducer = createReducer([fetchBrokerManager, sendInvitation]);
