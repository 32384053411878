import {entityStatus, contactLanguage, producerAccountRoles} from 'core/constants';

export const mappedFieldsLocalization = {
    activeInactiveStatuses: [
        {value: entityStatus.active, label: 'misc.active'},
        {value: entityStatus.inactive, label: 'misc.inactive'},
    ],
    contactLanguage: [
        {
            value: contactLanguage.dutch,
            label: 'fields.agentCreate.contactLanguage.dutch',
        },
        {
            value: contactLanguage.french,
            label: 'fields.agentCreate.contactLanguage.french',
        },
    ],
    roles: {
        [producerAccountRoles.production]:
            'fields.agentCreate.producerAccount.roles.PRODUCTION.label',
        [producerAccountRoles.policyConsultation]:
            'fields.agentCreate.producerAccount.roles.POLICY_CONSULTATION.label',
        [producerAccountRoles.policyManagement]:
            'fields.agentCreate.producerAccount.roles.POLICY_MANAGEMENT.label',
        [producerAccountRoles.consultCommissions]:
            'fields.agentCreate.producerAccount.roles.CONSULT_COMMISSIONS.label',
    },

    rolesInfo: {
        [producerAccountRoles.production]: [
            'fields.agentCreate.producerAccount.roles.PRODUCTION.create',
            'fields.agentCreate.producerAccount.roles.PRODUCTION.submit',
            'fields.agentCreate.producerAccount.roles.PRODUCTION.viewDetails',
            'fields.agentCreate.producerAccount.roles.PRODUCTION.viewDocuments',
        ],
        [producerAccountRoles.policyConsultation]: [
            'fields.agentCreate.producerAccount.roles.POLICY_CONSULTATION.viewDetails',
            'fields.agentCreate.producerAccount.roles.POLICY_CONSULTATION.viewDocuments',
        ],

        [producerAccountRoles.policyManagement]: [
            'fields.agentCreate.producerAccount.roles.POLICY_MANAGEMENT.viewDetails',
            'fields.agentCreate.producerAccount.roles.POLICY_MANAGEMENT.viewDocuments',
            'fields.agentCreate.producerAccount.roles.POLICY_MANAGEMENT.requestChanges',
        ],

        [producerAccountRoles.consultCommissions]: [
            'fields.agentCreate.producerAccount.roles.CONSULT_COMMISSIONS.viewCommission',
        ],
    },
};
