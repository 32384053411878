import get from 'lodash/get';
import {getStateByContainerId} from 'core/selectors';
import {selectIsInProgressStatus, selectAsyncActionData} from 'core/createReducer';
import {containerId, fetchBrokerManager, sendInvitation} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsLoading = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchBrokerManager.type);

export const selectIsSendingInvitation = state =>
    selectIsInProgressStatus(getCurrentState(state), sendInvitation.type);

export const selectBrokerManagerData = state => {
    const brokerManager = selectAsyncActionData(getCurrentState(state), fetchBrokerManager.type);
    if (!brokerManager) {
        return {};
    }

    return {
        enterpriseNumber: get(brokerManager, 'intermed.enterpriseNumber'),
        companyName: get(brokerManager, 'intermed.name'),
        firstName: brokerManager.firstName,
        surname: brokerManager.surname,
        email: brokerManager.email,
        isActive: brokerManager.isActive,
        isAlreadyExistsInB2c: brokerManager.isAlreadyExistsInB2c,
    };
};

export const selectBrokerManagerId = state =>
    selectAsyncActionData(getCurrentState(state), fetchBrokerManager.type, 'id');
