import {getFormMeta, getFormSyncErrors, getFormValues} from 'redux-form';
import {createSelector} from 'reselect';
import get from 'lodash/get';

import {getStateByContainerId} from 'core/selectors';
import {
    selectActionData,
    selectAsyncActionData,
    selectAsyncActionError,
    selectIsInProgressStatus,
} from 'core/createReducer';
import {entityStatus} from 'core/constants';
import {convertPhoneNumber} from 'core/services/utils';
import {selectUserIntermedId} from 'features/App/selectors';

import {
    containerId,
    showRemoveProducerAccountModal,
    fetchProducerAccounts,
    fetchAgentData,
    removeAgent,
    changeAgentStatus,
    saveAgentData,
    saveDentAgentData,
    setProducerAccountFormErrors,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = getFormValues(containerId);
const selectFormErrorsValues = getFormSyncErrors(containerId);
const getMeta = getFormMeta(containerId);
export const selectFormErrors = state => selectFormErrorsValues(state);

export const selectChosenProducerAccountIndex = state =>
    selectActionData(getCurrentState(state), showRemoveProducerAccountModal.type);

export const selectProducerAccounts = state =>
    selectAsyncActionData(getCurrentState(state), fetchProducerAccounts.type);

export const selectIsFetchingAgentData = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchAgentData.type);

export const selectIsSavingAgent = state =>
    selectIsInProgressStatus(getCurrentState(state), saveAgentData.type);

export const selectIsSavingDentAgent = state =>
    selectIsInProgressStatus(getCurrentState(state), saveDentAgentData.type);

export const selectIsRemovingAgent = state =>
    selectIsInProgressStatus(getCurrentState(state), removeAgent.type);

export const selectIsChangingAgentStatus = state =>
    selectIsInProgressStatus(getCurrentState(state), changeAgentStatus.type);

export const selectAgentData = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentData.type);

export const selectAgentId = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentData.type, 'id', null);

export const selectAgentIsActive = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentData.type, 'isActive', false);

export const selectAgentIsDent = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentData.type, 'isDelaAgent', false);

export const selectChosenProducerAccounts = state =>
    selectAsyncActionData(getCurrentState(state), fetchAgentData.type, 'producerAccounts', []);

export const selectProducerAccountsFormValues = state =>
    get(selectFormValues(state), 'producerAccounts', []);

export const selectProducerAccountsFormErrors = state =>
    selectActionData(getCurrentState(state), setProducerAccountFormErrors.type);

export const makeSelectProducerAccountOptions = () => {
    return createSelector(
        [selectProducerAccounts, selectProducerAccountsFormValues, selectUserIntermedId],
        (producerAccounts, selectedProducerAccounts, userIntermedId) => {
            return producerAccounts.map(({label, type, value, id, linkedIntermedId}) => {
                const isSelected = selectedProducerAccounts.some(
                    ({producerAccountId}) => id === producerAccountId
                );
                const sharedProducerAccount = linkedIntermedId === userIntermedId;

                return {
                    label,
                    type,
                    value,
                    id,
                    disabled: isSelected,
                    sharedProducerAccount,
                };
            });
        }
    );
};

export const makeSelectIsAddProducerAccountDisabled = () => {
    return createSelector(
        [selectProducerAccounts, selectProducerAccountsAmount],
        (producerAccounts, amount) => !producerAccounts.length || amount === producerAccounts.length
    );
};

export const selectProducerAccountsAmount = state => selectProducerAccountsFormValues(state).length;

export const makeSelectSelectedProducerAccountsAmount = () => {
    return createSelector(
        [selectProducerAccountsFormValues],
        producerAccounts =>
            producerAccounts.filter(producerAccount => producerAccount.producerAccountId).length
    );
};

export const selectChangeStatusRequestBody = state => {
    const isActive = selectAgentIsActive(state);
    return {status: isActive ? entityStatus.inactive : entityStatus.active};
};

export const selectChangeStatusAgentError = state =>
    selectAsyncActionError(getCurrentState(state), changeAgentStatus.type);

export const selectActiveAgentError = createSelector(selectChangeStatusAgentError, error => {
    const status = get(error, 'status');
    return status === 409;
});

export const selectAndPrepareFormData = state => {
    const values = selectFormValues(state);
    let data = convertPhoneNumber(values, 'mobileNumber');
    data = convertPhoneNumber(data, 'contactNumber');
    return data;
};

export const selectAndPrepareDentFormData = state => {
    const values = selectFormValues(state);
    const data = convertPhoneNumber(values, 'mobileNumber');
    const {producerAccounts, dateOfBirth, mobileNumber} = data;
    return {producerAccounts, dateOfBirth, mobileNumber};
};

export const makeSelectInitialValues = () =>
    createSelector(selectAgentData, agent => {
        if (!agent) {
            return null;
        }

        return {
            activeDirectoryUserId: agent.id,
            firstName: agent.firstName,
            surname: agent.surname,
            email: agent.email,
            dateOfBirth: agent.dateOfBirth,
            mobileNumber: agent.mobileNumber,
            contactNumber: agent.contactNumber,
            mobileNumberCode: agent.mobileNumberCode,
            contactNumberCode: agent.contactNumberCode,
            contactLanguage: agent.contactLanguage,
            status: agent.isActive,
            isAlreadyExistsInB2c: agent.isAlreadyExistsInB2c,
            producerAccounts: agent.producerAccounts,
        };
    });

export const makeSelectAgentData = () =>
    createSelector(selectAgentData, agent => {
        if (!agent) {
            return null;
        }
        const mobileNumber = agent.mobileNumber
            ? `${agent.mobileNumberCode}${agent.mobileNumber}`
            : null;
        const contactNumber = agent.contactNumber
            ? `${agent.contactNumberCode}${agent.contactNumber}`
            : null;

        return {
            firstName: agent.firstName,
            surname: agent.surname,
            email: agent.email,
            isActive: agent.isActive,
            dateOfBirth: agent.dateOfBirth,
            mobileNumber,
            contactNumber,
            contactLanguage: agent.contactLanguage,
        };
    });

export const selectContactNumberValue = state => get(selectFormValues(state), 'contactNumberCode');
export const selectMobileNumberValue = state => get(selectFormValues(state), 'mobileNumberCode');

const selectIsFieldTouched = fieldPath =>
    createSelector(getMeta, meta => get(meta, `${fieldPath}.touched`, false));

export const selectContactNumberError = createSelector(
    [selectFormErrors, selectIsFieldTouched('contactNumber')],
    (formErrors, isTouched) => isTouched && get(formErrors, 'contactNumber', false)
);

export const selectMobileNumberError = createSelector(
    [selectFormErrors, selectIsFieldTouched('mobileNumber')],
    (formErrors, isTouched) => isTouched && get(formErrors, 'mobileNumber', false)
);
