import {ButtonsGroupEditEntity} from 'components/common/ButtonsGroup/ButtonsGroupEditEntity';
import {
    selectAgentId,
    selectAgentIsActive,
    selectIsChangingAgentStatus,
    selectIsRemovingAgent,
    selectIsSavingAgent,
} from 'features/Agent/common/selectors';
import {
    showRemoveModal,
    showChangeStatusModal,
    handleSaveAgent,
} from 'features/Agent/common/reducer';
import {history} from 'core/services/history';
import {connectWithIntl} from 'core/services/connectWithIntl';

const mapStateToProps = state => ({
    id: selectAgentId(state),
    isActive: selectAgentIsActive(state),
    onCancel: history.goToAgentDetails,
    isChangingStatus: selectIsChangingAgentStatus(state),
    isRemoving: selectIsRemovingAgent(state),
    isSaving: selectIsSavingAgent(state),
});

export const AgentEditActions = connectWithIntl(mapStateToProps, {
    onRemove: showRemoveModal.action,
    onChangeStatus: showChangeStatusModal.action,
    onSubmit: handleSaveAgent.action,
})(ButtonsGroupEditEntity);
