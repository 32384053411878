import {AsyncAction, Action} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'BrokerManagerList';

export const fetchBrokerManagersData = new Action(`${containerId}/FETCH_DATA`);
export const fetchBrokerManagersList = new AsyncAction(`${containerId}/FETCH_LIST`);
export const searchBrokerManagersList = new Action(`${containerId}SEARCH_LIST`);
export const clearBrokerManagersData = new Action(`${containerId}/CLEAR_DATA`);
export const setBrokerManagersPageInfo = new Action(`${containerId}/SET_PAGE_INFO`);
export const setBrokerManagersTotalItemsCount = new Action(
    `${containerId}/SET_TOTAL_ITEMS_COUNT`,
    0
);
export const setBrokerManagersSearchValue = new Action(`${containerId}/SET_SEARCH_VALUE`, '');
export const setBrokerManagersCurrentPage = new Action(`${containerId}/SET_CURRENT_PAGE`, 0);

export const brokerManagersListStateActions = [
    fetchBrokerManagersData,
    fetchBrokerManagersList,
    setBrokerManagersPageInfo,
    setBrokerManagersSearchValue,
    setBrokerManagersCurrentPage,
    setBrokerManagersTotalItemsCount,
];

export const BrokerManagerListReducer = createReducer(brokerManagersListStateActions);
