import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import find from 'lodash/find';
import {noop} from 'core/services/utils';

import {FormFieldWrapper} from 'components/common/Form/FormFieldWrapper';
import {Spinner} from 'components/common/Spinner';
import {optionsShape} from 'core/propTypes';

import {LoadingMessage, NoOptionsMessage} from './components';

import './styles.scss';

// can be removed?
export class AutosuggestField extends React.PureComponent {
    static propTypes = {
        options: optionsShape,
        isLoading: PropTypes.bool,

        label: PropTypes.string,
        disabled: PropTypes.bool,
        creatable: PropTypes.bool,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        serverError: PropTypes.object,

        meta: PropTypes.shape({
            touched: PropTypes.bool.isRequired,
            error: PropTypes.object,
        }),
        input: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
            onBlur: PropTypes.func.isRequired,
            value: PropTypes.any.isRequired,
        }),

        onInputChange: PropTypes.func,
        onSelectChange: PropTypes.func,
    };

    static defaultProps = {
        meta: {},
        onInputChange: noop,
        onSelectChange: noop,
    };

    render() {
        const {
            // own props
            options,
            isLoading,
            creatable,

            // input props
            label,
            disabled,
            className,
            placeholder,
            serverError,

            // redux form props
            meta: {touched, error},
        } = this.props;

        const components = {
            IndicatorSeparator: null,
            ClearIndicator: null,
            DropdownIndicator: null,
            LoadingMessage,
            NoOptionsMessage,
            LoadingIndicator: Spinner,
        };

        const commonProps = {
            className: 'autosuggest-input',
            classNamePrefix: 'autosuggest-input',
            value: this._getValue(),
            onInputChange: this._onInputChange,
            onBlur: this._onBlur,
            onChange: this._onChange,
            isLoading,
            options,
            placeholder: placeholder && !disabled ? placeholder : null,
            isDisabled: disabled,
            openMenuOnClick: false,
            openMenuOnFocus: false,
            isClearable: true,
            components,
        };

        return (
            <FormFieldWrapper
                touched={touched}
                error={error}
                label={label}
                className={className}
                serverError={serverError}
            >
                {creatable ? <CreatableSelect {...commonProps} /> : <Select {...commonProps} />}
            </FormFieldWrapper>
        );
    }

    _getValue = () => {
        const {
            options,
            input: {value},
            creatable,
        } = this.props;

        let result = find(options, {value}) || null;

        if (creatable && !result && value) {
            result = {value, label: value};
        }

        return result;
    };

    _onChange = option => {
        if (!option) {
            return;
        }

        const {
            input: {onChange},
            onSelectChange,
        } = this.props;
        onChange(option.value);

        if (onSelectChange) {
            onSelectChange(option);
        }
    };

    _onInputChange = (value, {action}) => {
        if (action !== 'input-change') {
            return;
        }

        const {onInputChange} = this.props;
        onInputChange(value);
    };

    _onBlur = () => {
        const {input} = this.props;
        input.onBlur(input.value);
    };
}
