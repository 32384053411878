import React from 'react';
import PropTypes from 'prop-types';
import {intlShape, injectIntl, FormattedMessage} from 'react-intl';
import {Field, FieldArray} from 'redux-form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import {RadioGroupField, SelectField, TextField} from 'components/common/Form';
import {PhoneNumberField} from 'components/common/PhoneNumberField';
import {Tooltip} from 'components/common/Tooltip';
import {
    requiredEmail,
    dateOfBirth,
    contactPhoneNumber,
    mobilePhoneNumber,
    firstNameField,
    surnameField,
} from 'core/fields';
import {required} from 'core/services/validators';
import {mappedFieldsLocalization} from 'core/services/localeService/fieldsConfig';

class AgentCreateComponent extends React.PureComponent {
    static propTypes = {
        intl: intlShape,
        options: PropTypes.array,
        emailValidationError: PropTypes.shape({
            id: PropTypes.string,
        }),
        contactNumberCode: PropTypes.string,
        contactNumberError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        mobileNumberCode: PropTypes.string,
        mobileNumberError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        emailFieldDisabled: PropTypes.bool,
        statusFieldHidden: PropTypes.bool,
        change: PropTypes.func,
        producerAccountsComponent: PropTypes.func,
    };

    render() {
        const {
            intl,
            options,
            emailValidationError,
            contactNumberCode,
            contactNumberError,
            mobileNumberCode,
            mobileNumberError,
            emailFieldDisabled,
            statusFieldHidden,
            change,
            producerAccountsComponent: ProducerAccountsComponent,
        } = this.props;

        return (
            <>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="status"
                            // TODO: because Field implements custom componentShouldUpdate and intl uses context API
                            // `component` does not update when we have same localization value
                            // consider writing custom Field component that will handle this case in a better way
                            label={<FormattedMessage id={'fields.agentCreate.status.label'} />}
                            component={RadioGroupField}
                            options={mappedFieldsLocalization.activeInactiveStatuses}
                            optionsLocalizable
                            validate={required}
                            hidden={statusFieldHidden}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="firstName"
                            label={intl.formatMessage({
                                id: 'fields.agentCreate.firstName',
                            })}
                            component={TextField}
                            {...firstNameField}
                        />
                    </Col>
                    <Col xs={4}>
                        <Field
                            name="surname"
                            label={intl.formatMessage({
                                id: 'fields.agentCreate.surname',
                            })}
                            component={TextField}
                            {...surnameField}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="email"
                            // TODO: because Field implements custom componentShouldUpdate and intl uses context API
                            // `component` does not update when we have same localization value
                            // consider writing custom Field component that will handle this case in a better way
                            label={
                                <>
                                    <FormattedMessage id={'fields.agentCreate.email.title'} />
                                    {!emailFieldDisabled && (
                                        <Tooltip>
                                            <FormattedMessage
                                                id={'fields.agentCreate.email.description'}
                                                values={{br: <br />}}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            }
                            component={TextField}
                            disabled={emailFieldDisabled}
                            serverError={emailValidationError}
                            {...requiredEmail}
                        />
                    </Col>
                    <Col xs={4}>
                        <Field
                            name="dateOfBirth"
                            label={intl.formatMessage({
                                id: 'fields.agentCreate.dateOfBirth.label',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'fields.agentCreate.dateOfBirth.placeholder',
                            })}
                            component={TextField}
                            {...dateOfBirth}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <PhoneNumberField
                            name="contactNumber"
                            labelId="fields.agentCreate.contactPhoneNumber.label"
                            infoId="fields.agentCreate.contactPhoneNumber.info"
                            change={change}
                            codeValue={contactNumberCode}
                            phoneNumberError={contactNumberError}
                            {...contactPhoneNumber}
                        />
                    </Col>

                    <Col xs={4}>
                        <PhoneNumberField
                            name="mobileNumber"
                            labelId="fields.agentCreate.mobilePhoneNumber.label"
                            change={change}
                            codeValue={mobileNumberCode}
                            phoneNumberError={mobileNumberError}
                            {...mobilePhoneNumber}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="contactLanguage"
                            label={intl.formatMessage({
                                id: 'fields.agentCreate.contactLanguage.label',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'fields.agentCreate.contactLanguage.placeholder',
                            })}
                            component={SelectField}
                            options={options}
                            validate={required}
                            filterOnChange
                            clearable
                        />
                    </Col>
                </Row>
                <FieldArray
                    name="producerAccounts"
                    change={change}
                    component={ProducerAccountsComponent}
                />
            </>
        );
    }
}

export const AgentCreate = injectIntl(AgentCreateComponent);
