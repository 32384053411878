import PropTypes from 'prop-types';

export const LoadingWrapper = ({isLoading, loadingNode, children}) => {
    if (loadingNode && isLoading) {
        return loadingNode;
    }

    return isLoading ? null : children;
};

LoadingWrapper.propTypes = {
    loadingNode: PropTypes.node,
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};
