import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {producerAccountRoles} from 'core/constants';
import {OverflowTooltip} from 'components/common/OverflowTooltip';

const mapUserRoleToLocId = new Map([
    [producerAccountRoles.production, 'userRoles.production'],
    [producerAccountRoles.policyConsultation, 'userRoles.policyConsultation'],
    [producerAccountRoles.policyManagement, 'userRoles.policyManagement'],
    [producerAccountRoles.consultCommissions, 'userRoles.consultCommissions'],
]);

export const TableRoleCell = ({roles}) => {
    if (!roles) {
        return null;
    }

    return (
        <OverflowTooltip>
            {roles.map((role, index) => {
                const locId = mapUserRoleToLocId.get(role);

                return (
                    <Fragment key={locId}>
                        {index !== 0 && ' | '}
                        <FormattedMessage id={locId} />
                    </Fragment>
                );
            })}
        </OverflowTooltip>
    );
};

TableRoleCell.propTypes = {
    roles: PropTypes.array,
};
