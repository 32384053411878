import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {PageControls} from '../PageControls';
import './styles.scss';

export const PageControlsListComponent = ({intl, titleId, totalCount, headerIcon, children}) => (
    <PageControls
        title={<FormattedMessage id={titleId} />}
        prefix={
            <img
                className="page-header-list-prefix-icon"
                src={headerIcon}
                alt={intl.formatMessage({id: titleId})}
            />
        }
        postfix={<div className="page-header-list-postfix">{`(${totalCount})`}</div>}
    >
        {children}
    </PageControls>
);
PageControlsListComponent.propTypes = {
    titleId: PropTypes.string.isRequired,
    totalCount: PropTypes.number,
    headerIcon: PropTypes.string,
    children: PropTypes.node,
};

export const PageControlsList = injectIntl(PageControlsListComponent);
