import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {NotFoundPage} from 'components/NotFoundPage';

import {PermissionsService} from './PermissionsService';

export const createPermissionRoute = ({PermissionsService}) =>
    class extends React.Component {
        static propTypes = {
            permissions: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
            ]),
            redirectToDefault: PropTypes.bool,
        };

        render() {
            const {permissions, redirectToDefault, ...restProps} = this.props;

            if (redirectToDefault) {
                const defaultPath = PermissionsService._getDefaultPath();
                if (defaultPath) {
                    return <Redirect to={defaultPath} />;
                }
            }

            if (!PermissionsService.isPermissionSet(permissions)) {
                return <Route {...restProps} component={NotFoundPage} />;
            }

            return <Route {...restProps} />;
        }
    };

export const PermissionRoute = createPermissionRoute({PermissionsService});
