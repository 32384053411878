import {reduxForm} from 'redux-form';

import {connectWithIntl} from 'core/services/connectWithIntl';
import {BrokerManagerCreate} from 'components/BrokerManager/BrokerManagerCreate';
import {
    selectIsFetchingCompanies,
    makeSelectIntermedCompanies,
    selectIsFetchingBrokerManagerAccounts,
    makeSelectEmailList,
    selectIsPersonalInfoDiasbled,
    makeSelectEmailRegisteredError,
} from 'features/BrokerManager/BrokerManagerCreate/selectors';
import {
    containerId,
    createFetchBrokerCompanies,
    fetchBrokerManagerAccounts,
    selectBrokerManagerEmail,
} from 'features/BrokerManager/BrokerManagerCreate/reducer';

export const BrokerManagerReduxForm = reduxForm({
    form: containerId,
})(BrokerManagerCreate);

const mapStateToProps = () => {
    const selectIntermedCompanies = makeSelectIntermedCompanies();
    const selectEmailList = makeSelectEmailList();
    const selectEmailRegisteredError = makeSelectEmailRegisteredError();

    return state => ({
        isFetchingCompanies: selectIsFetchingCompanies(state),
        companiesList: selectIntermedCompanies(state),
        isFetchingEmails: selectIsFetchingBrokerManagerAccounts(state),
        emailList: selectEmailList(state),
        personalInfoFieldsDisabled: selectIsPersonalInfoDiasbled(state),
        emailValidationError: selectEmailRegisteredError(state),
    });
};

export const BrokerManagerForm = connectWithIntl(mapStateToProps, {
    onSelectEmail: selectBrokerManagerEmail.action,
    onEmailChange: fetchBrokerManagerAccounts.request,
    onCompanyNameChange: createFetchBrokerCompanies.action,
})(BrokerManagerReduxForm);
