import {connectWithIntl} from 'core/services/connectWithIntl';
import {ButtonsGroupCreateEntity} from 'components/common/ButtonsGroup/ButtonsGroupCreateEntity';
import {history} from 'core/services/history';

import {createAgent} from 'features/Agent/AgentCreate/reducer';
import {selectIsSaving} from 'features/Agent/AgentCreate/selectors';

const mapStateToProps = state => {
    return {
        isSaving: selectIsSaving(state),
        onCancel: history.goToAgents,
    };
};

export const AgentCreateActions = connectWithIntl(mapStateToProps, {
    onSubmit: createAgent.action,
})(ButtonsGroupCreateEntity);
