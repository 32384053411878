import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {PageWrapper} from 'components/common/PageWrapper';
import {PageControls} from 'components/common/PageControls';

import {BrokerManagerCreateActions, BrokerManagerForm} from './components';
import {clearBrokerManager} from 'features/BrokerManager/BrokerManagerCreate/reducer';

export class BrokerManagerCreateComponent extends React.Component {
    static propTypes = {
        clearData: PropTypes.func,
    };

    componentWillUnmount() {
        const {clearData} = this.props;
        clearData();
    }

    render() {
        return (
            <PageWrapper>
                <PageControls title={<FormattedMessage id="brokerManagerCreate.title" />}>
                    <BrokerManagerCreateActions />
                </PageControls>
                <BrokerManagerForm />
            </PageWrapper>
        );
    }
}

export const BrokerManagerCreate = connect(null, {clearData: clearBrokerManager.action})(
    BrokerManagerCreateComponent
);
