import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import './styles.scss';

export const TableWrapper = ({children, headerLeft, headerRight, className}) => {
    return (
        <div className={classNames('table-wrapper', className)}>
            <Row className="table-wrapper-header">
                <Col xs={6} className="table-wrapper-header-left">
                    {headerLeft}
                </Col>
                <Col xs={6} className="table-wrapper-header-right">
                    {headerRight}
                </Col>
            </Row>
            <Row className="table-wrapper-content">
                <Col xs={12}>{children}</Col>
            </Row>
        </div>
    );
};

TableWrapper.propTypes = {
    children: PropTypes.node,
    headerLeft: PropTypes.node,
    headerRight: PropTypes.node,
    className: PropTypes.string,
};
