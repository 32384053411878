import {
    selectIsSaving,
    makeSelectIsActive,
    selectIsChangingStatus,
    selectBrokerManagerId,
    selectIsRemovingBrokerManager,
    selectIsBrokerManagerRemoveDisabled,
} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {
    handleEditBrokerManager,
    showChangeStatusModal,
    showRemoveBrokerManagerModal,
} from 'features/BrokerManager/BrokerManagerEdit/reducer';

import {BrokerManagerEditActions as BrokerManagerEditActionsComponent} from 'components/BrokerManager/BrokerManagerEdit';
import {history} from 'core/services/history';
import {connectWithIntl} from 'core/services/connectWithIntl';

const mapStateToProps = () => {
    const selectIsActive = makeSelectIsActive();

    return state => ({
        id: selectBrokerManagerId(state),
        isRemoveDisabled: selectIsBrokerManagerRemoveDisabled(state),
        isRemoving: selectIsRemovingBrokerManager(state),
        isSaving: selectIsSaving(state),
        isActive: selectIsActive(state),
        isChangingStatus: selectIsChangingStatus(state),
        onCancel: history.goToBrokerManagersDetails,
    });
};

export const BrokerManagerEditActions = connectWithIntl(mapStateToProps, {
    onRemove: showRemoveBrokerManagerModal.action,
    onChangeStatus: showChangeStatusModal.action,
    onSubmit: handleEditBrokerManager.action,
})(BrokerManagerEditActionsComponent);
