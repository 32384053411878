import isEmpty from 'lodash/isEmpty';
import {select, put, call} from 'redux-saga/effects';
import {getFormSyncErrors, hasSubmitFailed, setSubmitFailed, setSubmitSucceeded} from 'redux-form';

const defaultCheckFormErrorsCondition = formValidationError => !isEmpty(formValidationError);

export const createCheckFormErrorsSaga = (
    {getFormSyncErrors},
    checkFormErrorsCondition = defaultCheckFormErrorsCondition
) =>
    function* (containerId) {
        const formValidationError = yield select(getFormSyncErrors(containerId));

        const isFormErrorExist = yield call(checkFormErrorsCondition, formValidationError);

        if (isFormErrorExist) {
            yield put(setSubmitFailed(containerId, ...Object.keys(formValidationError)));
        } else {
            yield put(setSubmitSucceeded(containerId));
        }
    };

export const defaultCheckFormErrorsSaga = createCheckFormErrorsSaga({getFormSyncErrors});

export function* clearActionsStateSaga(actions = []) {
    for (let action of actions) {
        yield put(action.clear());
    }
}

export const createSubmitFormIfValidSaga = ({checkFormErrorsSaga}) =>
    function* (containerId, submitSaga) {
        yield call(checkFormErrorsSaga, containerId);
        yield call(createHandleSubmissionStatusSaga(containerId, submitSaga));
    };

export const createHandleSubmissionStatusSaga = (containerId, submitSaga) =>
    function* (submissionFailedStatusSelector = hasSubmitFailed(containerId)) {
        const failed = yield select(submissionFailedStatusSelector);
        if (failed) {
            return;
        }
        yield call(submitSaga);
    };

export const defaultSubmitFormIfValidSaga = createSubmitFormIfValidSaga({
    checkFormErrorsSaga: defaultCheckFormErrorsSaga,
});
