import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import {ROUTES} from 'core/services/history/routes';
import {Permissions} from './Permissions';

const permissionToDefaultPath = new Map([
    [Permissions.brokerManager.list, ROUTES.brokerManagers],
    [Permissions.agent.list, ROUTES.agents],
]);

export class PermissionsServiceClass {
    _permissions = new Set();
    _defaultPath;

    isPermissionSet(permissions) {
        if (permissions === undefined) {
            return true;
        }

        if (isString(permissions)) {
            return this._permissions.has(permissions);
        }

        if (isArray(permissions)) {
            return permissions.some(permission => {
                return this._permissions.has(permission);
            });
        }

        return false;
    }

    isAllPermissionsSet(permissions) {
        if (permissions === undefined) {
            return true;
        }

        if (isArray(permissions)) {
            return permissions.every(permission => {
                return this._permissions.has(permission);
            });
        }

        return false;
    }

    _add(permissions) {
        if (isString(permissions)) {
            this._permissions.add(permissions);
        }

        if (isArray(permissions)) {
            permissions.forEach(permission => {
                this._permissions.add(permission);
            });
        }

        this._setDefaultPath();
    }

    _clear() {
        this._permissions.clear();
    }

    _setDefaultPath = () => {
        for (const permissionPair of permissionToDefaultPath) {
            if (this.isPermissionSet(permissionPair[0])) {
                this._defaultPath = permissionPair[1];
                break;
            }
        }
    };

    _getDefaultPath = () => this._defaultPath;
}

export const PermissionsService = new PermissionsServiceClass();
