import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const OverflowTooltip = ({tooltip, children, className}) => {
    const showTooltip = e => {
        const el = e.currentTarget;
        if (el.offsetWidth < el.scrollWidth) {
            el.title = tooltip || el.textContent;
        } else {
            el.title = '';
        }
    };
    return (
        <div className={classNames('overflow-tooltip', className)} onMouseEnter={showTooltip}>
            {children}
        </div>
    );
};
