import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {Field} from 'redux-form';
import {injectIntl, intlShape} from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import {TextField, SelectField} from 'components/common/Form';
import {requiredText, requiredEmail, firstNameField, surnameField} from 'core/fields';
import {optionsShape} from 'core/propTypes';

class BrokerManagerCreateComponent extends React.PureComponent {
    static propTypes = {
        intl: intlShape,
        companiesList: optionsShape,
        emailList: optionsShape,
        isFetchingCompanies: PropTypes.bool,
        isFetchingEmails: PropTypes.bool,
        personalInfoFieldsDisabled: PropTypes.bool,
        emailValidationError: PropTypes.shape({id: PropTypes.string}),

        onCompanyNameChange: PropTypes.func,
        onSelectEmail: PropTypes.func,
        onEmailChange: PropTypes.func,
    };

    render() {
        const {
            intl,
            companiesList,
            isFetchingCompanies,
            onCompanyNameChange,
            emailList,
            isFetchingEmails,
            onEmailChange,
            onSelectEmail,
            personalInfoFieldsDisabled,
            emailValidationError,
        } = this.props;
        return (
            <>
                <Row>
                    <Col xs={4}>
                        <Field name="tenantId" component={TextField} hidden />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="email"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.email',
                            })}
                            options={emailList}
                            loading={isFetchingEmails}
                            onInputChange={debounce(onEmailChange, 500)}
                            onSelectChange={onSelectEmail}
                            serverError={emailValidationError}
                            {...requiredEmail}
                            creatable
                            component={SelectField}
                            clearable
                            searchable
                            changeOnLoadingFinished
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="firstName"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.firstName',
                            })}
                            component={TextField}
                            {...firstNameField}
                            disabled={personalInfoFieldsDisabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="surname"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.surname',
                            })}
                            component={TextField}
                            {...surnameField}
                            disabled={personalInfoFieldsDisabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="companyName"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.companyName.label',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.companyName.placeholder',
                            })}
                            {...requiredText}
                            onInputChange={onCompanyNameChange}
                            loading={isFetchingCompanies}
                            options={companiesList}
                            component={SelectField}
                            clearable
                            searchable
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export const BrokerManagerCreate = injectIntl(BrokerManagerCreateComponent);
