import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {ButtonsGroup} from 'components/common/ButtonsGroup';
import {ActionButton} from 'components/common/ActionButton';
import {history} from 'core/services/history';
import {selectAgentId} from '../../selectors';

export class AgentDetailsActionsComponent extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    render() {
        const {id} = this.props;

        return (
            <ButtonsGroup onCancel={() => history.goToAgents()}>
                <ActionButton onClick={() => history.goToAgentEdit(id)} className="button action">
                    <FormattedMessage id="misc.edit" />
                </ActionButton>
            </ButtonsGroup>
        );
    }
}

const mapStateToProps = state => ({
    id: selectAgentId(state),
});

export const AgentDetailsActions = connectWithIntl(mapStateToProps)(AgentDetailsActionsComponent);
