import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import './styles.scss';

export const FormFieldWrapper = ({
    children,
    className,
    touched,
    submitFailed,
    error,
    serverError,
    formError,
    hidden,
    label,
    info,
}) => {
    const hasReduxFormError = (touched || submitFailed) && error;
    const hasError = hasReduxFormError || serverError || formError;
    let shownError;

    if (hasReduxFormError) {
        shownError = hasReduxFormError;
    } else if (serverError) {
        shownError = serverError;
    } else {
        shownError = formError;
    }

    if (hidden) {
        return null;
    }

    return (
        <div className={classNames('input-text-wrapper bright', className, {hasError})}>
            {label && (
                <label>
                    <span>{label}</span>
                </label>
            )}
            {children}
            <div className="info-area">
                {!hasError && <span className="info">{info}</span>}
                {hasError && (
                    <span className="validation-error">
                        {shownError.id ? (
                            <FormattedMessage
                                id={`validate.${shownError.id}`}
                                values={{
                                    ...shownError.values,
                                }}
                            />
                        ) : (
                            shownError
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

FormFieldWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    serverError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    formError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hidden: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    info: PropTypes.string,
};
