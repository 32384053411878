import React from 'react';
import PropTypes from 'prop-types';

import {mappedFieldsLocalization} from 'core/services/localeService/fieldsConfig';
import {ProducerAccounts} from 'features/Agent/common/components/ProducerAccounts';
import {AgentEditStatus} from 'features/Agent/AgentEdit/components/AgentEditStatus';
import {AgentEditForm} from 'features/Agent/AgentEdit/components/AgentEditForm';
import {AgentEditDentForm} from 'features/Agent/AgentEdit/components/AgentEditDentForm';

export const AgentEditContent = ({isDentAgent}) => {
    if (isDentAgent) {
        return <AgentEditDentForm />;
    }

    return (
        <>
            <AgentEditStatus />
            <AgentEditForm
                options={mappedFieldsLocalization.contactLanguage}
                emailFieldDisabled
                statusFieldHidden
                idFieldHidden
                producerAccountsComponent={ProducerAccounts}
            />
        </>
    );
};

AgentEditContent.propTypes = {
    isDentAgent: PropTypes.bool,
};
