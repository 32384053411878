import {AsyncAction, Action, CaughtAsyncAction} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'BrokerManagerEdit';

export const fetchBrokerManagerData = new AsyncAction(`${containerId}/BROKER_MANAGER_FETCH_DATA`);
export const editBrokerManager = new AsyncAction(`${containerId}/BROKER_MANAGER_EDIT`);
export const handleEditBrokerManager = new Action(`${containerId}/BROKER_MANAGER_HANDLE_EDIT`);
export const changeStatusBrokerManager = new AsyncAction(
    `${containerId}/BROKER_MANAGER_CHANGE_STATUS`
);
export const removeBrokerManager = new CaughtAsyncAction(`${containerId}/BROKER_MANAGER_REMOVE`);

export const clearBrokerManager = new Action(`${containerId}/BROKER_MANAGER_CLEAR`);
export const disableRemoveBrokerManager = new Action(
    `${containerId}/BROKER_MANAGER_REMOVE_DISABLE`
);

export const showRemoveBrokerManagerModal = new Action(
    `${containerId}/REMOVE_BROKER_MANAGER_MODAL_SHOW`
);
export const showChangeStatusModal = new Action(`${containerId}/CHANGE_STATUS_MODAL_SHOW`);
export const triggerBrokerManagerIsActive = new Action(
    `${containerId}/TRIGGER_BROKER_MANAGER_IS_ACTIVE`
);

const actionToHandlerMap = new Map([
    [
        triggerBrokerManagerIsActive.ACTION,
        draft => {
            draft[fetchBrokerManagerData.type].data.isActive = !draft[fetchBrokerManagerData.type]
                .data.isActive;
        },
    ],
    [
        disableRemoveBrokerManager.ACTION,
        draft => {
            draft[fetchBrokerManagerData.type].data.isAllowedToDelete = false;
        },
    ],
]);

export const brokerManagerActions = [
    removeBrokerManager,
    editBrokerManager,
    fetchBrokerManagerData,
    changeStatusBrokerManager,
];

export const BrokerManagerEditReducer = createReducer(
    brokerManagerActions,
    null,
    actionToHandlerMap
);
