import {AsyncAction, Action} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'AgentList';

export const fetchAgentData = new Action(`${containerId}/FETCH_DATA`);
export const fetchAgentList = new AsyncAction(`${containerId}/FETCH_LIST`);
export const searchAgentList = new Action(`${containerId}SEARCH_LIST`);
export const clearAgentData = new Action(`${containerId}/CLEAR_DATA`);
export const setAgentPageInfo = new Action(`${containerId}/SET_PAGE_INFO`);
export const setAgentTotalItemsCount = new Action(`${containerId}/SET_TOTAL_ITEMS_COUNT`, 0);
export const setAgentSearchValue = new Action(`${containerId}/SET_SEARCH_VALUE`, '');
export const setAgentCurrentPage = new Action(`${containerId}/SET_CURRENT_PAGE`, 0);

export const agentListStateActions = [
    fetchAgentData,
    fetchAgentList,
    setAgentPageInfo,
    setAgentSearchValue,
    setAgentCurrentPage,
    setAgentTotalItemsCount,
];

export const AgentListReducer = createReducer(agentListStateActions);
