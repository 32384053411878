import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {PermissionsService, Permissions} from 'core/services/permissions';
import {PageWrapper} from 'components/common/PageWrapper';
import {TableWrapper} from 'components/common/Table/TableWrapper';
import headerIcon from 'assets/images/group-agent.svg';

import {selectUserCompanyName} from 'features/App';

import {AgentListButtons, AgentListTable, AgentListSearch, AgentListHeader} from './components';
import {clearAgentData, fetchAgentData} from './reducer';

export const createAgentList = ({PermissionsService}) =>
    class extends React.Component {
        static propTypes = {
            fetchData: PropTypes.func,
            clearData: PropTypes.func,
            companyName: PropTypes.string,
        };

        componentDidMount() {
            this.props.fetchData();
        }

        componentWillUnmount() {
            this.props.clearData();
        }

        render() {
            return (
                <PageWrapper>
                    <AgentListHeader titleId={'agentList.title'} headerIcon={headerIcon}>
                        <span>{this.props.companyName}</span>
                    </AgentListHeader>
                    <TableWrapper
                        headerLeft={
                            <AgentListSearch tableSearchPlaceholderId="agentList.table.searchPlaceholder" />
                        }
                        headerRight={
                            PermissionsService.isPermissionSet(Permissions.agent.create) && (
                                <AgentListButtons />
                            )
                        }
                    >
                        <AgentListTable />
                    </TableWrapper>
                </PageWrapper>
            );
        }
    };

const mapStateToProps = () => {
    return state => ({
        companyName: selectUserCompanyName(state),
    });
};

export const AgentListComponent = createAgentList({
    PermissionsService,
});

export const AgentList = connect(mapStateToProps, {
    fetchData: fetchAgentData.action,
    clearData: clearAgentData.action,
})(AgentListComponent);
