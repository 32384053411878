import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {connectWithIntl} from 'core/services/connectWithIntl';
import {Modal} from 'features/common/Modal';
import {ActionButton} from 'components/common/ActionButton';
import {removeProducerAccount} from 'features/Agent/common/reducer';

export const AGENT_CREATE_REMOVE_MODAL = 'AgentRemoveProducerAccountModal';

export class ProducerAccountRemoveModalComponent extends React.PureComponent {
    static propTypes = {
        onRemove: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Modal modalId={AGENT_CREATE_REMOVE_MODAL} actionsPanel={this.resolveActionsPanel()}>
                <FormattedMessage id="agentCreate.removeProducerAccount" />
            </Modal>
        );
    }

    resolveActionsPanel = () => {
        const {onRemove} = this.props;

        return (
            <ActionButton onClick={onRemove} className="warning">
                <FormattedMessage id="misc.remove" />
            </ActionButton>
        );
    };
}

export const ProducerAccountRemoveModal = connectWithIntl(null, {
    onRemove: removeProducerAccount.action,
})(ProducerAccountRemoveModalComponent);
