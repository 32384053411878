import get from 'lodash/get';
import {createSelector} from 'reselect';

import {containerId, fetchUserInformation, updateLocale} from './reducer';
import {getStateByContainerId} from 'core/selectors';
import {selectActionData, selectAsyncActionData, selectIsFinishedStatus} from 'core/createReducer';
import {documentTitleToLocalesMap} from 'core/constants';

export const getCurrentState = getStateByContainerId(containerId);

export const selectLocale = state => selectActionData(getCurrentState(state), updateLocale.type);

export const selectIsUserInformationLoaded = state =>
    selectIsFinishedStatus(getCurrentState(state), fetchUserInformation.type);

const selectUserInformation = state =>
    selectAsyncActionData(getCurrentState(state), fetchUserInformation.type);

export const selectUserFullName = createSelector([selectUserInformation], userInformation => {
    if (!userInformation) {
        return '';
    }
    return `${userInformation.firstName} ${userInformation.surname}`;
});

export const selectUserRole = createSelector([selectUserInformation], userInformation => {
    return get(userInformation, 'role', null);
});

export const selectUserActiveDirectoryId = state =>
    get(
        selectAsyncActionData(getCurrentState(state), fetchUserInformation.type),
        'activeDirectoryUserId'
    );

export const selectUserIntermed = state =>
    get(selectAsyncActionData(getCurrentState(state), fetchUserInformation.type), 'intermed');

export const selectUserIntermedId = state =>
    get(selectAsyncActionData(getCurrentState(state), fetchUserInformation.type), 'intermed.id');

export const selectUserIntermedFsmaNumber = state =>
    get(
        selectAsyncActionData(getCurrentState(state), fetchUserInformation.type),
        'intermed.fsmaNumber'
    );

export const selectUserCompanyName = state =>
    get(selectAsyncActionData(getCurrentState(state), fetchUserInformation.type), 'intermed.name');

export const selectDocumentTitle = state => {
    const locale = selectActionData(getCurrentState(state), updateLocale.type);
    return documentTitleToLocalesMap.get(locale);
};
