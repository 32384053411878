import React from 'react';
import {FormattedMessage} from 'react-intl';
import {history} from 'core/services/history';

export const AgentListButtons = () => (
    <button
        type="button"
        onClick={() => history.goToAgentCreate()}
        className="btn btn-default btn-redirect"
    >
        <FormattedMessage id="misc.createAgent" />
    </button>
);
