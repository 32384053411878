import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import notFoundIcon from 'assets/images/404-copy.svg';
import {history} from 'core/services/history';

import './styles.scss';

const NotFoundPageComponent = ({intl}) => (
    <div className="error-page">
        <img
            src={notFoundIcon}
            alt={intl.formatMessage({
                id: 'notFoundPage.iconTitle',
            })}
        />
        <div className="error-page-title">
            <FormattedMessage id="notFoundPage.title" />
        </div>

        <div className="error-page-info">
            <FormattedMessage id="notFoundPage.info" />
            <div className="error-page-info-suggestion">
                <FormattedMessage id="notFoundPage.suggestion" />
            </div>
        </div>

        <div className="error-page-button">
            <button type="button" onClick={() => history.goToRoot()} className="btn btn-default">
                <span>
                    <FormattedMessage id={'notFoundPage.buttonText'} />
                </span>
            </button>
        </div>
    </div>
);

export const NotFoundPage = injectIntl(NotFoundPageComponent);
