import isString from 'lodash/isString';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import {authService} from 'core/services/auth';
import {PermissionsService} from 'core/services/permissions';

export const isJsonContentType = type => type && type.indexOf('json') >= 0;

export const isTextContentType = type => type && type.indexOf('text') >= 0;

class ApiClient {
    async get(url, body, options) {
        return this.request(url, body, options, 'GET');
    }

    async post(url, body, options) {
        return this.request(url, body, options, 'POST');
    }

    async put(url, body, options) {
        return this.request(url, body, options, 'PUT');
    }

    async delete(url, body, options) {
        return this.request(url, body, options, 'DELETE');
    }

    async patch(url, body, options) {
        return this.request(url, body, options, 'PATCH');
    }

    async request(url, body, options, method) {
        if (body) {
            body = body instanceof FormData || isString(body) ? body : JSON.stringify(body);
        }

        const headers = new Headers({
            pragma: 'no-cache',
            'Cache-Control': 'no-cache',
        });

        !(body instanceof FormData) &&
            headers.append('Content-type', 'application/json; charset=UTF-8');

        const token = await authService.getToken();
        headers.append('Authorization', `Bearer ${token.accessToken}`);

        return makeRequest(url, body, method, headers, options);
    }
}

const makeRequest = (url, body, method, headers, options) => {
    const permissions = get(options, 'permissions');

    if (!PermissionsService.isPermissionSet(permissions)) {
        throw new Error('method is not allowed!');
    }

    return fetch(url.toString(), {
        body,
        method,
        headers,
        ...options,
    }).then(response => {
        return new Promise((resolve, reject) => {
            const type = response.headers.get('content-type');

            let promise;
            if (isJsonContentType(type)) {
                promise = response.json();
            } else if (isTextContentType(type)) {
                promise = response.text();
            } else {
                promise = response.blob();
            }

            promise.then(data => {
                if (response.status < 400) {
                    resolve(
                        data !== null &&
                            typeof data === 'object' &&
                            !(data instanceof Array) &&
                            !(data instanceof Blob)
                            ? pickBy(data, (value, key) => key !== '_links')
                            : data
                    );
                    return;
                }
                reject(response);
            });
        });
    });
};

export const apiClient = new ApiClient();
