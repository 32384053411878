import React from 'react';
import {FormattedMessage} from 'react-intl';

import {TableComponent} from 'components/common/Table/TableComponent';
import {TableStatusCell} from 'components/common/Table/TableStatusCell';
import {BrokerManagerListNoResultData} from './BrokerManagerListNoResultData';
import {
    makeSelectBrokerManagersShowTableHeader,
    selectBrokerManagersCurrentPage,
    selectBrokerManagersList,
    selectBrokerManagersTotalPages,
    selectIsLoading,
} from '../../selectors';
import {fetchBrokerManagersList, setBrokerManagersCurrentPage} from '../../reducer';

import {history} from 'core/services/history';
import {connectWithIntl} from 'core/services/connectWithIntl';

const columns = [
    {
        Header: <FormattedMessage id={'brokerManagerList.table.enterpriseNumber'} />,
        accessor: 'enterpriseNumber',
        width: 200,
    },
    {
        Header: <FormattedMessage id={'brokerManagerList.table.companyName'} />,
        accessor: 'companyName',
    },
    {
        Header: <FormattedMessage id={'brokerManagerList.table.name'} />,
        accessor: row => `${row.surname} ${row.firstName}`,
        id: 'fullName',
    },
    {
        Header: <FormattedMessage id={'brokerManagerList.table.email'} />,
        accessor: 'email',
    },
    {
        Header: <FormattedMessage id={'brokerManagerList.table.status.title'} />,
        accessor: 'isActive',
        Cell: row => <TableStatusCell isActive={row.value} />,
        width: 100,
    },
];

const mapStateToProps = () => {
    const selectShouldShowColumns = makeSelectBrokerManagersShowTableHeader();

    return state => ({
        data: selectBrokerManagersList(state),
        columns,
        page: selectBrokerManagersCurrentPage(state),
        totalPages: selectBrokerManagersTotalPages(state),
        loading: selectIsLoading(state),
        noDataComponent: BrokerManagerListNoResultData,
        onRowClick: history.goToBrokerManagersDetails,
        shouldShowColumns: selectShouldShowColumns(state),
    });
};

export const BrokerManagerListTable = connectWithIntl(mapStateToProps, {
    fetchDataHandler: fetchBrokerManagersList.request,
    onPageChange: setBrokerManagersCurrentPage.action,
})(TableComponent);
