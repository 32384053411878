import {contactNumberFormat, mobileNumberFormat, required} from 'core/services/validators';
import {removeSpaces} from 'core//services/utils';
import {phoneValueToCountryCodes} from 'core/constants';
import {PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil} from 'google-libphonenumber';
import {PHONE_CODES} from '../constants';

export const phoneUtil = PhoneNumberUtil.getInstance();
export const phoneNumberFormat = (value = '') => /^\d{0,16}$/g.test(value);

export const normalizePhoneNumber = (value, prev) => {
    if (!value) {
        return value;
    }

    const updatedValue = removeSpaces(value);

    if (!phoneNumberFormat(updatedValue)) {
        return prev;
    }

    return updatedValue;
};

const createPhoneMask = phoneNumber => {
    const result = [];
    for (let i = 0; i < phoneNumber.length; i++) {
        phoneNumber[i] === ' ' ? result.push(' ') : result.push(/\d/);
    }

    return result;
};

export const maskPhoneNumber = isMobile => (codeValue, value) => {
    if (!value || !codeValue) {
        return [];
    }
    if (value.length < 2 || value.length > 17 || !phoneNumberFormat(value)) {
        return createPhoneMask(value);
    }

    const phoneNumber = codeValue + value;

    const countryCode = phoneValueToCountryCodes[codeValue];
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

    if (isMobile && phoneUtil.getNumberType(number) !== PhoneNumberType.MOBILE) {
        return createPhoneMask(value);
    }

    if (!phoneUtil.isValidNumberForRegion(number, countryCode)) {
        return createPhoneMask(value);
    }

    if (codeValue === PHONE_CODES.BELGIUM && value.startsWith('0')) {
        const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
        return createPhoneMask(formattedNumber);
    }

    const internationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
    const sliceIndex = codeValue.length + 1;
    return createPhoneMask(internationalPhoneNumber.slice(sliceIndex));
};

const maskMobilePhoneNumber = maskPhoneNumber(true);
const maskLandlinePhoneNumber = maskPhoneNumber(false);

export const contactPhoneNumber = {
    mask: maskLandlinePhoneNumber,
    normalize: normalizePhoneNumber,
    validate: [required, contactNumberFormat],
};

export const mobilePhoneNumber = {
    mask: maskMobilePhoneNumber,
    normalize: normalizePhoneNumber,
    validate: [mobileNumberFormat],
};
