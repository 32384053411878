import {connectWithIntl} from 'core/services/connectWithIntl';
import {selectIsSaving} from 'features/BrokerManager/BrokerManagerCreate/selectors';
import {createBrokerManager} from 'features/BrokerManager/BrokerManagerCreate/reducer';
import {ButtonsGroupCreateEntity} from 'components/common/ButtonsGroup/ButtonsGroupCreateEntity';
import {history} from 'core/services/history';

const mapStateToProps = () => {
    return state => ({
        isSaving: selectIsSaving(state),
        onCancel: history.goToBrokerManagers,
    });
};

export const BrokerManagerCreateActions = connectWithIntl(mapStateToProps, {
    onSubmit: createBrokerManager.action,
})(ButtonsGroupCreateEntity);
