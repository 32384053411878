export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ROOT_URI =
    window.location.origin ||
    window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');

export const ACTION_STATUS = {
    INITIAL: 'initial',
    IN_PROGRESS: 'inProgress',
    SUCCESS: 'success',
    FAILURE: 'failure',
};

export const SIMPLE_ANSWER = {
    YES: 'YES',
    NO: 'NO',
};

export const DEFAULT_TABLE_SIZE = 20;

export const entityStatus = {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
};

export const producerAccountRoles = {
    production: 'PRODUCTION',
    policyConsultation: 'POLICY_CONSULTATION',
    policyManagement: 'POLICY_MANAGEMENT',
    consultCommissions: 'CONSULT_COMMISSIONS',
};

export const contactLanguage = {
    dutch: 'DUTCH',
    french: 'FRENCH',
};

export const producerAccountTypes = {
    OWN: 'Own',
    SIAM: 'SIAM',
    LEADGENERATOR: 'Lead Generator',
    ONLINE: 'Online - Chat',
};

export const userRoles = {
    brokerManager: 'BROKER_MANAGER_ROLE',
    dentBrokerManager: 'DENT_BROKER_MANAGER_ROLE',
    productAdmin: 'PRODUCT_ADMIN_ROLE',
};

// note that order for this enum is important
// first locale that is not ignored will be picked as a default one
export const SupportedLocales = Object.freeze({
    enUS: 'en-US',
    nlBE: 'nl-BE',
    frFR: 'fr-FR',
});

export const documentTitleToLocalesMap = new Map([
    [SupportedLocales.frFR, 'Gestion des utilisateurs'],
    [SupportedLocales.enUS, 'User Management'],
    [SupportedLocales.nlBE, 'Gebruikersbeheer'],
]);

export const PHONE_CODES = {
    BELGIUM: '+32',
    FRANCE: '+33',
    GERMANY: '+49',
    LUXEMBOURG: '+352',
    NETHERLANDS: '+31',
};

export const phoneValueToCountry = {
    [PHONE_CODES.BELGIUM]: 'Belgium',
    [PHONE_CODES.FRANCE]: 'France',
    [PHONE_CODES.GERMANY]: 'Germany',
    [PHONE_CODES.LUXEMBOURG]: 'Luxembourg',
    [PHONE_CODES.NETHERLANDS]: 'Netherlands',
};

export const phoneValueToCountryCodes = {
    [PHONE_CODES.BELGIUM]: 'BE',
    [PHONE_CODES.FRANCE]: 'FR',
    [PHONE_CODES.GERMANY]: 'DE',
    [PHONE_CODES.LUXEMBOURG]: 'LU',
    [PHONE_CODES.NETHERLANDS]: 'NL',
};
