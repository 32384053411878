import {createSelector} from 'reselect';
import {getFormValues} from 'redux-form';
import find from 'lodash/find';
import get from 'lodash/get';

import {
    selectIsInProgressStatus,
    selectAsyncActionData,
    selectActionData,
    selectAsyncActionError,
    selectAsyncActionCache,
} from 'core/createReducer';
import {
    containerId,
    fetchBrokerCompanies,
    fetchBrokerManagerAccounts,
    setIsPersonalInfoDisabled,
    saveCreatedBrokerManager,
} from 'features/BrokerManager/BrokerManagerCreate/reducer';
import {getStateByContainerId} from 'core/selectors';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = getFormValues(containerId);

export const selectIsSaving = state =>
    selectIsInProgressStatus(getCurrentState(state), saveCreatedBrokerManager.type);

export const selectIsFetchingCompanies = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchBrokerCompanies.type);

export const selectIntermedCompanies = state =>
    selectAsyncActionData(getCurrentState(state), fetchBrokerCompanies.type);

export const selectIsFetchingBrokerManagerAccounts = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchBrokerManagerAccounts.type);

export const selectBrokerManagerAccounts = state =>
    selectAsyncActionData(getCurrentState(state), fetchBrokerManagerAccounts.type);

export const selectIsPersonalInfoDiasbled = state =>
    selectActionData(getCurrentState(state), setIsPersonalInfoDisabled.type);

const selectCreateBrokerManagerError = state =>
    selectAsyncActionError(getCurrentState(state), saveCreatedBrokerManager.type);

const selectSavedBrokerManagerEmail = state =>
    selectAsyncActionCache(getCurrentState(state), saveCreatedBrokerManager.type, 'email');

export const makeSelectEmailRegisteredError = () =>
    createSelector(
        [selectCreateBrokerManagerError, selectSavedBrokerManagerEmail, selectEmailValue],
        (error, savedBrokerManagerEmail, emailValue) => {
            const status = get(error, 'status');
            if (status !== 409) {
                return null;
            }

            if (savedBrokerManagerEmail && savedBrokerManagerEmail !== emailValue) {
                return null;
            }

            return {
                id: 'registeredEmailError',
            };
        }
    );

export const selectBrokerManagerAccountByEmail = (state, email) => {
    const brokerManagerAccounts = selectBrokerManagerAccounts(state);
    return find(brokerManagerAccounts, {email});
};

export const selectAndPrepareFormData = state => {
    const {tenantId, companyName: intermedId, firstName, surname, email} = selectFormValues(state);

    return {
        tenantId,
        intermedId,
        firstName,
        surname,
        email,
    };
};

export const makeSelectIntermedCompanies = () =>
    createSelector(selectIntermedCompanies, companies =>
        companies.map(company => ({
            value: company.id,
            label: `${company.enterpriseNumber} ${company.name}`,
        }))
    );

export const makeSelectEmailList = () =>
    createSelector(selectBrokerManagerAccounts, accounts =>
        accounts.map(account => ({
            value: account.email,
            label: account.email,
        }))
    );

const selectEmailValue = state => get(selectFormValues(state), 'email');

export const makeSelectIsNewEmail = value =>
    createSelector([selectBrokerManagerAccounts], accounts => {
        if (!value) {
            return false;
        }
        return accounts.every(account => account.email !== value);
    });
