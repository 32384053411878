import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

export const DropdownInputWrapper = ({
    children,
    className,

    loading,
    open,
    clearable,
    valueEmpty,
    searchable,
    disabled,

    onArrowClick,
    onClear,
}) => {
    if (disabled) {
        return (
            <div className={classNames('searchable-dropdown-input-wrapper', className)}>
                {children}
            </div>
        );
    }

    let arrowOrLoading = null;

    if (loading) {
        arrowOrLoading = (
            <div className="input-spinner-wrapper">
                <div className="input-spinner" />
            </div>
        );
    } else {
        if (open) {
            arrowOrLoading = (
                <button
                    onMouseDown={onArrowClick}
                    className="icon icon-arrow-up-drop-down"
                    tabIndex="-1"
                />
            );
        } else {
            arrowOrLoading = (
                <button
                    onMouseDown={onArrowClick}
                    className="icon icon-arrow-down-drop-down"
                    tabIndex="-1"
                />
            );
        }
    }

    let searchOrRemove = null;

    if (valueEmpty) {
        if (searchable) {
            searchOrRemove = <span className="icon icon-search" />;
        }
    } else {
        if (clearable) {
            searchOrRemove = (
                <button onMouseDown={onClear} className="icon icon-cross" tabIndex="-1" />
            );
        }
    }

    return (
        <div className={classNames('searchable-dropdown-input-wrapper', className)}>
            {children}
            {searchOrRemove}
            {arrowOrLoading}
        </div>
    );
};

DropdownInputWrapper.propTypes = {
    children: PropTypes.node,

    loading: PropTypes.bool,
    open: PropTypes.bool,
    clearable: PropTypes.bool,
    valueEmpty: PropTypes.bool,
    searchable: PropTypes.bool,
    disabled: PropTypes.bool,

    onArrowClick: PropTypes.func,
    onClear: PropTypes.func,
};
