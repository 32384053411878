import {connectWithIntl} from 'core/services/connectWithIntl';
import {PageControlsList} from 'components/common/PageControls/PageControlsList';
import {selectBrokerManagersInitialTotalItemsCount} from '../../../selectors';

const mapStateToProps = () => {
    return state => ({
        totalCount: selectBrokerManagersInitialTotalItemsCount(state),
    });
};

export const BrokerManagerListHeader = connectWithIntl(mapStateToProps)(PageControlsList);
