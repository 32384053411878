// TODO should be removed when maintenance work is done
import React, {Component} from 'react';

import './styles.scss';
import {injectIntl} from 'react-intl';

// UPDATE ME WHEN THE DATES ARE CHANGED (see messages.json > maintenanceNotification.message)
const START_DATE_MAINTENANCE = '2024-09-06';
const END_DATE_MAINTENANCE = '2024-09-23';

const currentDate = new Date();

export class MaintenanceNotificationComponent extends Component {

    render() {
        if (currentDate <= new Date(END_DATE_MAINTENANCE) && currentDate >= new Date(START_DATE_MAINTENANCE)) {
            return (
                <div
                    className="maintenance-notification">
                    {this.props.intl.formatMessage({
                        id: `maintenanceNotification.message`,
                    })}
                </div>
            );
        }
        return null;
    }
};

export const MaintenanceNotification = injectIntl(MaintenanceNotificationComponent);
