import React from 'react';
import PropTypes from 'prop-types';

import {LoadingWrapper} from 'components/common/LoadingWrapper/LoadingWrapper';
import {Spinner} from 'components/common/Spinner';

import {Wrapper} from './styles.module.scss';

export const SpinnerLoadingWrapper = ({isLoading, children}) => (
    <LoadingWrapper
        isLoading={isLoading}
        loadingNode={
            <div className={Wrapper}>
                <Spinner />
            </div>
        }
    >
        {children}
    </LoadingWrapper>
);

LoadingWrapper.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};
