import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import {CirclesLoader} from 'components/common/CirclesLoader';
import {BrokerManagerCreate} from 'features/BrokerManager/BrokerManagerCreate';
import {BrokerManagerList} from 'features/BrokerManager/BrokerManagerList';
import {BrokerManagerDetails} from 'features/BrokerManager/BrokerManagerDetails';
import {BrokerManagerEdit} from 'features/BrokerManager/BrokerManagerEdit';
import {AgentList} from 'features/Agent/AgentList';
import {AgentCreate} from 'features/Agent/AgentCreate';
import {AgentEdit} from 'features/Agent/AgentEdit';
import {AgentDetails} from 'features/Agent/AgentDetails';
import {Permissions} from 'core/services/permissions/Permissions';
import {PermissionRoute} from 'core/services/permissions';
import {NotFoundPage} from '../../components/NotFoundPage/NotFoundPage';
import {FeatureToggling} from 'core/FeatureToggling';

export const routes = (
    <Suspense fallback={<CirclesLoader />}>
        <Switch>
            <PermissionRoute
                exact
                path={'/'}
                redirectToDefault
                permissions={[Permissions.brokerManager.list, Permissions.agent.list]}
            />
            <PermissionRoute
                permissions={[Permissions.agent.list]}
                exact
                path="/agents"
                component={AgentList}
            />
            <PermissionRoute
                permissions={[Permissions.agent.create]}
                exact
                path="/agents/create"
                component={AgentCreate}
            />
            <PermissionRoute
                permissions={[Permissions.agent.update]}
                exact
                path="/agents/:id/edit"
                component={AgentEdit}
            />
            <PermissionRoute
                permissions={[Permissions.agent.read]}
                exact
                path="/agents/:id"
                component={AgentDetails}
            />

            <PermissionRoute
                permissions={[Permissions.brokerManager.list]}
                exact
                path="/broker-managers"
                component={BrokerManagerList}
            />
            {!FeatureToggling.crmIntegrationEnabled && (
                <PermissionRoute
                    permissions={[Permissions.brokerManager.create]}
                    exact
                    path="/broker-managers/create"
                    component={BrokerManagerCreate}
                />
            )}
            {!FeatureToggling.crmIntegrationEnabled && (
                <PermissionRoute
                    permissions={[Permissions.brokerManager.update]}
                    exact
                    path="/broker-managers/:id/edit"
                    component={BrokerManagerEdit}
                />
            )}
            <PermissionRoute
                permissions={[Permissions.brokerManager.read]}
                exact
                path="/broker-managers/:id"
                component={BrokerManagerDetails}
            />
            <Route component={NotFoundPage} />
        </Switch>
    </Suspense>
);
