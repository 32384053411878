import React from 'react';
import PropTypes from 'prop-types';

import {noop} from 'core/services/utils';
import {optionsShape} from 'core/propTypes';

import {FormFieldWrapper} from 'components/common/Form/FormFieldWrapper';
import {DropdownCommon} from 'components/common/DropdownCommon/DropdownCommon';

export class SelectField extends React.Component {
    static propTypes = {
        options: optionsShape,
        searchAfterSymbols: PropTypes.number,
        loading: PropTypes.bool,
        clearable: PropTypes.bool,
        creatable: PropTypes.bool,
        searchable: PropTypes.bool,
        editable: PropTypes.bool,
        filterOnChange: PropTypes.bool,
        formError: PropTypes.shape({
            id: PropTypes.string,
        }),
        label: PropTypes.string,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        serverError: PropTypes.shape({
            id: PropTypes.string,
        }),
        meta: PropTypes.shape({
            touched: PropTypes.bool.isRequired,
            error: PropTypes.object,
        }),
        input: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
            onBlur: PropTypes.func.isRequired,
            value: PropTypes.any.isRequired,
        }),

        onInputChange: PropTypes.func,
        formatValue: PropTypes.func,
        onSelectChange: PropTypes.func,
    };

    static defaultProps = {
        meta: {},
        onInputChange: noop,
        onSelectChange: noop,
        formatValue: null,
    };

    render() {
        const {
            // own props
            options,
            loading,
            searchable,
            clearable,
            creatable,
            editable,
            searchAfterSymbols,
            filterOnChange,
            serverError,
            onInputChange,
            changeOnLoadingFinished,
            onSelectChange,
            formError,

            // input props
            label,
            disabled,
            className,
            placeholder,

            // redux form props
            meta: {touched, error},
            input,
            formatValue,
        } = this.props;

        const hasError = error || serverError;

        return (
            <FormFieldWrapper
                touched={touched}
                formError={formError}
                error={hasError}
                label={label}
                className={className}
            >
                <DropdownCommon
                    {...input}
                    formatValue={formatValue}
                    changeOnLoadingFinished={changeOnLoadingFinished}
                    onChange={value => {
                        onSelectChange(value);
                        input.onChange(value);
                    }}
                    loading={loading}
                    options={options}
                    placeholder={placeholder}
                    disabled={disabled}
                    creatable={creatable}
                    onInputChange={onInputChange}
                    searchable={searchable}
                    clearable={clearable}
                    editable={editable}
                    startSearchAfterSymbols={searchAfterSymbols}
                    filterOnChange={filterOnChange}
                />
            </FormFieldWrapper>
        );
    }
}
