import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {NotFoundPage} from 'components/NotFoundPage';
import {GeneralErrorPage} from 'components/GeneralErrorPage';
import {selectTransformServerError} from './selectors';
import {setGeneralError} from './reducer';

export class ErrorBoundaryComponent extends Component {
    state = {hasError: false, location: null};

    static propTypes = {
        error: PropTypes.shape({
            status: PropTypes.number,
            errors: PropTypes.arrayOf(
                PropTypes.shape({
                    errorCode: PropTypes.string,
                    reason: PropTypes.string,
                    errorUUID: PropTypes.string,
                })
            ),
        }),
        clearServerError: PropTypes.func,
    };

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    static getDerivedStateFromProps(props, state) {
        const {location, hasError} = state;

        if (props.location === location) {
            return state;
        }

        let updatedState = {...state, location: props.location};

        if (hasError) {
            updatedState = {...updatedState, hasError: false};
        }

        if (props.error) {
            props.clearServerError();
        }
        return updatedState;
    }

    componentDidCatch(error, info) {
        console.error({error, info});
    }

    render() {
        const {error, children} = this.props;
        const {hasError} = this.state;
        const status = get(error, 'status');

        if (!hasError && !error) {
            return children;
        }

        return status === 404 ? <NotFoundPage /> : <GeneralErrorPage />;
    }
}

const mapStateToProps = state => ({
    error: selectTransformServerError(state),
});

export const ErrorBoundary = withRouter(
    connect(mapStateToProps, {
        clearServerError: setGeneralError.clear,
    })(ErrorBoundaryComponent)
);
