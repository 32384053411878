import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import {noop} from 'core/services/utils';

export const NotificationMessageComponent = ({
    messageLocalizationKey,
    link,
    onButtonClick,
    label,
    buttonLocKey,
}) => {
    if (!messageLocalizationKey) {
        return null;
    }
    return (
        <FormattedMessage
            id={messageLocalizationKey}
            values={{
                link: link && (
                    <Link to={link.path}>
                        <FormattedMessage id={link.label} values={{processId: link.processId}} />
                    </Link>
                ),
                label: label,
                button: buttonLocKey && (
                    <button type="button" onClick={onButtonClick}>
                        <FormattedMessage id={buttonLocKey} />
                    </button>
                ),
            }}
        />
    );
};

NotificationMessageComponent.propTypes = {
    messageLocalizationKey: PropTypes.string,
    link: PropTypes.shape({
        link: PropTypes.string,
        path: PropTypes.string,
    }),
    onButtonClick: PropTypes.func,
    label: PropTypes.string,
    buttonLocKey: PropTypes.string,
};

NotificationMessageComponent.defaultProps = {
    messageLocalizationKey: null,
    onButtonClick: noop,
};

export const NotificationMessage = injectIntl(NotificationMessageComponent);
