import {reduxForm} from 'redux-form';

import {containerId} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {BrokerManagerEdit} from 'components/BrokerManager/BrokerManagerEdit';
import {makeSelectInitialValues} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {connectWithIntl} from 'core/services/connectWithIntl';

const BrokerManagerReduxForm = reduxForm({
    form: containerId,
})(BrokerManagerEdit);

const mapStateToProps = () => {
    const selectInitialValues = makeSelectInitialValues();

    return state => ({
        initialValues: selectInitialValues(state),
    });
};

export const BrokerManagerEditForm = connectWithIntl(mapStateToProps)(BrokerManagerReduxForm);
