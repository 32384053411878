import React from 'react';
import PropTypes from 'prop-types';

import {DropDown} from 'components/common/DropDown';
import {DropdownIndicator} from 'components/common/DropDown/components/DropdownIndicator';

import './styles.scss';

export const LocaleSwitcher = ({locales, locale, onLocaleChange}) => (
    <div className="locale-switch">
        <DropDown
            className="bright"
            value={locale}
            onChange={onLocaleChange}
            options={locales}
            isSearchable={false}
            hideSelectedOptions
            components={{DropdownIndicator, IndicatorSeparator: null}}
        />
    </div>
);

LocaleSwitcher.propTypes = {
    onLocaleChange: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    locales: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};
