import isNil from 'lodash/isNil';
import get from 'lodash/get';
import {PhoneNumberType, PhoneNumberUtil, PhoneNumberFormat} from 'google-libphonenumber';
import moment from 'moment';

import {EMAIL_REGEX, phoneValueToCountryCodes, PHONE_CODES} from 'core/constants';
import {removeSpaces} from 'core/services/utils';

export const phoneUtil = PhoneNumberUtil.getInstance();

const DATE_FORMAT = 'DD/MM/YYYY';
const minAge = 18;

export const emailIsValid = value => {
    if (!value) {
        return;
    }
    if (!EMAIL_REGEX.test(value)) {
        return {id: 'incorrectFormat'};
    }
    const [userName, serverName] = value.split('@');
    if (value.length > 100 || userName.length > 64 || serverName.length > 48) {
        return {id: 'tooLong'};
    }
};

export const required = value => (isNil(value) || value === '' ? {id: 'required'} : undefined);

export const dateIsValid = value => {
    if (value) {
        const date = moment(value, DATE_FORMAT, true);
        if (!date.isValid() || !date.isSameOrBefore(moment())) {
            return {id: 'incorrectDate'};
        }
    }
    return undefined;
};

export const ageIsValid = value => {
    if (value && moment().diff(moment(value, DATE_FORMAT, true), 'years') < minAge) {
        return {id: 'incorrectAge'};
    }
    return undefined;
};

export const validatePhoneNumber = (value, codeValue) => {
    if (!value) {
        return {isValid: true};
    }

    if (value.length < 2 || value.length > 17) {
        return {isValid: false};
    }

    const phoneNumber = codeValue + value;

    const countryCode = phoneValueToCountryCodes[codeValue];
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    const internationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

    return {
        number,
        isValid:
            phoneUtil.isValidNumberForRegion(number, countryCode) &&
            (codeValue === PHONE_CODES.BELGIUM ||
                phoneNumber === removeSpaces(internationalPhoneNumber)),
    };
};

export const validateIsMobileNumber = (value, codeValue) => {
    const {isValid, number} = validatePhoneNumber(value, codeValue);

    if (!isValid) {
        return false;
    }

    if (!number) {
        return true;
    }

    return phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE;
};

export const mobileNumberFormat = (value, allValues) => {
    const error = {id: 'incorrectMobileNumber'};
    const codeValue = get(allValues, 'mobileNumberCode');

    const isValidMobileNumber = validateIsMobileNumber(value, codeValue);
    return isValidMobileNumber ? undefined : error;
};

export const contactNumberFormat = (value, allValues) => {
    const error = {id: 'incorrectContactNumber'};
    const codeValue = get(allValues, 'contactNumberCode');
    const {isValid} = validatePhoneNumber(value, codeValue);
    return isValid ? undefined : error;
};

// ' - . cannot be start and end of the string
// one special symbol cannot be followed by another special symbol
const specialSymbolsRegexpFirstname = /^['.-]+.*$|^.*['.-]+$|^.+(['.-])\1+|(\.'|\.-|'\.|'-|-\.|-').+$/u; //NOSONAR

const specialSymbolsRegexpSurname = /^[.-]+.*$|^'{2,}|^.*['.-]+$|^.+(['.-])\1+|(\.'|\.-|'\.|'-|-\.|-').+$/u; //NOSONAR

const checkSuspiciousSymbols = (value, regex) => {
    if (!value) {
        return;
    }
    const words = value.split(/\s+/);

    // ensure no presence of any non letter symbols except ' - .
    const suspiciousSymbolsRegexp = /^.*[^\p{L}\s\-.'].*$/u;

    return suspiciousSymbolsRegexp.test(value) || words.some(word => regex.test(word))
        ? {id: 'invalidCharacter'}
        : undefined;
};

export const checkSuspiciousSymbolsFirstname = value =>
    checkSuspiciousSymbols(value, specialSymbolsRegexpFirstname);

export const checkSuspiciousSymbolsSurname = value =>
    checkSuspiciousSymbols(value, specialSymbolsRegexpSurname);
