import get from 'lodash/get';
import {all, call, put, select, takeLatest, take} from 'redux-saga/effects';
import {agents} from 'core/services/apiClient';
import {DEFAULT_TABLE_SIZE} from 'core/constants';
import {
    agentListStateActions,
    fetchAgentData,
    fetchAgentList,
    setAgentPageInfo,
    setAgentTotalItemsCount,
    clearAgentData,
    searchAgentList,
    setAgentCurrentPage,
} from './reducer';

import {
    selectAgentCurrentPage,
    selectAgentSearchValue,
    selectAgentTotalItemsCount,
} from './selectors';

import {clearActionsStateSaga} from 'core/sagaHelpers';

export function* clearAgentDataSaga() {
    yield call(clearActionsStateSaga, agentListStateActions);
}

export function* fetchAgentDataSaga() {
    yield put(fetchAgentList.request());

    yield take(fetchAgentList.SUCCESS);
    const totalItemsCount = yield select(selectAgentTotalItemsCount);
    yield put(setAgentTotalItemsCount.action(totalItemsCount));
}

export function* fetchAgentListSaga() {
    try {
        const currentPage = yield select(selectAgentCurrentPage);
        const searchValue = yield select(selectAgentSearchValue);

        const result = yield call(agents.get, {
            search: encodeURIComponent(searchValue),
            page: currentPage,
            size: DEFAULT_TABLE_SIZE,
        });

        const agentData = get(result, '_embedded.agents', []);
        const pageInfo = get(result, 'page');

        yield put(setAgentPageInfo.action(pageInfo));

        yield put(fetchAgentList.success(agentData));
    } catch (err) {
        yield put(fetchAgentList.failure(err));
    }
}

export function* searchAgentListSaga() {
    yield put(setAgentCurrentPage.clear());
    yield put(fetchAgentList.request());
}

export function* setAgentCurrentPageSaga() {
    yield put(fetchAgentList.request());
}

export const agentListSagas = function* () {
    yield all([takeLatest(clearAgentData.ACTION, clearAgentDataSaga)]);
    yield all([takeLatest(fetchAgentData.ACTION, fetchAgentDataSaga)]);
    yield all([takeLatest(fetchAgentList.REQUEST, fetchAgentListSaga)]);
    yield all([takeLatest(searchAgentList.ACTION, searchAgentListSaga)]);
    yield all([takeLatest(setAgentCurrentPage.ACTION, setAgentCurrentPageSaga)]);
};
