import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import {FormFieldWrapper} from 'components/common/Form/FormFieldWrapper';

import './styles.scss';

export class RadioGroupField extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        hidden: PropTypes.bool,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                label: PropTypes.string,
            })
        ),
        disabled: PropTypes.bool,
        optionsLocalizable: PropTypes.bool,
        input: PropTypes.object,
        meta: PropTypes.object,
    };

    static defaultProps = {
        meta: {},
    };

    render() {
        const {
            // own props
            className,
            label,
            hidden,

            // input props
            options,
            disabled,

            // redux form props
            input,
            meta: {touched, error},
        } = this.props;

        return (
            <FormFieldWrapper
                className={classNames('input-radio-wrapper', className)}
                touched={touched}
                error={error}
                hidden={hidden}
                label={label}
            >
                {options.map(opt => (
                    <div className="input-option" key={opt.value}>
                        <input
                            id={`rg-${input.name}-${opt.value}`}
                            type="radio"
                            name={input.name}
                            {...input}
                            disabled={disabled}
                            value={opt.value}
                            checked={opt.value === input.value}
                        />
                        <label htmlFor={`rg-${input.name}-${opt.value}`}>
                            <span>{this.resolveLabel(opt.label)}</span>
                        </label>
                    </div>
                ))}
            </FormFieldWrapper>
        );
    }

    resolveLabel = label => {
        if (!this.props.optionsLocalizable) {
            return label;
        }

        return <FormattedMessage id={label} />;
    };
}
