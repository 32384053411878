import {PageControlsList} from 'components/common/PageControls/PageControlsList';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {selectAgentInitialTotalItemsCount} from '../../../selectors';

const mapStateToProps = () => {
    return state => ({
        totalCount: selectAgentInitialTotalItemsCount(state),
    });
};

export const AgentListHeader = connectWithIntl(mapStateToProps)(PageControlsList);
