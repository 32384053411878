import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import {withForwardRef} from 'core/services/utils';
import {DropdownInputWrapper} from '../DropdownInputWrapper';

import './styles.scss';

export class DropdownInputComponent extends React.Component {
    static propTypes = {
        forwardedRef: PropTypes.object,
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,

        open: PropTypes.bool,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        clearable: PropTypes.bool,
        searchable: PropTypes.bool,
        editable: PropTypes.bool,

        onChange: PropTypes.func,
        onKeyDown: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onClear: PropTypes.func,
        onArrowClick: PropTypes.func,
        onClick: PropTypes.func,
    };

    state = {
        focused: false,
    };

    spanRef = React.createRef();

    render() {
        const {
            name,
            value,
            placeholder,

            open,
            loading,
            disabled,
            clearable,
            searchable,
            editable,

            onChange,
            onKeyDown,
            onClear,
            onClick,
        } = this.props;

        const {focused} = this.state;

        const inputPlaceholder = disabled ? null : placeholder;

        const defaultChildrenProps = {
            tabIndex: 1,
            className: classNames('searchable-dropdown-input', {
                searchable,
                clearable,
            }),
            placeholder: inputPlaceholder,
            disabled,
            onBlur: this.handleOnBlur,
            onFocus: this.handleOnFocus,
            onClick,
            onKeyDown,
        };

        return (
            <DropdownInputWrapper
                className={classNames({focused, disabled, editable})}
                loading={loading}
                open={open}
                searchable={searchable}
                clearable={clearable}
                valueEmpty={!value}
                disabled={disabled}
                onClear={onClear}
                onArrowClick={this.handleOnArrowClick}
            >
                {editable && (open || focused) ? (
                    <input
                        ref={this._setInputRefs}
                        type="text"
                        autoComplete="off"
                        name={name}
                        title={value}
                        value={value}
                        onChange={onChange}
                        {...defaultChildrenProps}
                    />
                ) : (
                    <span ref={this.spanRef} {...defaultChildrenProps}>
                        {value}
                    </span>
                )}
            </DropdownInputWrapper>
        );
    }

    handleOnFocus = event => {
        this.setState({focused: true});
        this.props.onFocus(event);
    };

    handleOnBlur = () => {
        this.setState({focused: false});
        this.props.onBlur();
    };

    handleOnArrowClick = e => {
        const {editable, onArrowClick} = this.props;
        if (!editable) {
            const ref = get(this.spanRef, 'current');
            this._setInputRefs(ref);
        }
        onArrowClick(e);
    };

    _setInputRefs = element => {
        if (element) {
            this.props.forwardedRef.current = element;
            this.props.forwardedRef.current.focus();
        }
    };
}

export const DropdownInput = withForwardRef(DropdownInputComponent);
