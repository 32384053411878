import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const GroupEntityStatus = ({isActive, className}) => {
    const messageId = `brokerManagerEdit.status.${isActive ? 'active' : 'inactive'}`;

    return (
        <span className={classNames('group-entity-status-value', className, {active: isActive})}>
            <FormattedMessage id={messageId} />
        </span>
    );
};

GroupEntityStatus.propTypes = {
    isActive: PropTypes.bool,
    className: PropTypes.string,
};
