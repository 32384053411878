import React from 'react';
import get from 'lodash/get';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedMessage, intlShape} from 'react-intl';

import {connectWithIntl} from 'core/services/connectWithIntl';
import internalError from 'assets/images/500.svg';
import {selectTransformServerError} from 'features/ErrorBoundary/selectors';
import {selectUserActiveDirectoryId, selectUserIntermedFsmaNumber} from 'features/App';
import './styles.scss';

const carry = {
    single: '%0D%0A%0D%0A',
    big: '%0D%0A%0D%0A%0D%0A',
};

export const MAIL_INFO = {
    ADDRESS: 'salessupport@dela.be',
    PHONE: '+32 2 800 87 88',
};

export const GeneralErrorPageComponent = ({intl, error = {}, fsmaNumber, userId, history}) => {
    const errorUUID = get(error, 'errors[0].errorUUID', false);
    const errorMessage = errorUUID || intl.formatMessage({id: 'errorPage.error.emptyErrorId'});
    const time = get(error, 'userTime', false);
    const userAgent = get(error, 'userAgent', false);
    const linkToMail = `mailto:${MAIL_INFO.ADDRESS}?subject=${intl.formatMessage({
        id: `errorPage.email.subject`,
    })}&body=${intl.formatMessage(
        {
            id: `errorPage.email.body`,
        },
        {
            br: carry.single,
            bigBr: carry.big,
            userId,
            fsmaNumber,
            errorSource: history.location.pathname,
            errorId: errorMessage,
            time: time,
            browser: userAgent,
        }
    )}`;

    return (
        <div className="error-page">
            <div className="error-page-container">
                <img
                    src={internalError}
                    alt={intl.formatMessage({
                        id: `errorPage.error.title`,
                    })}
                    draggable={false}
                />
            </div>
            <h2 className="error-page-title">
                <FormattedMessage id="errorPage.error.title" />
            </h2>
            <p>
                <FormattedMessage id="errorPage.error.description" values={{br: <br />}} />
            </p>
            <section className="sales-support">
                <p>
                    <FormattedMessage id="errorPage.error.supportInfo" />{' '}
                    <a href={linkToMail}>{MAIL_INFO.ADDRESS}</a>
                </p>
                <p>
                    <FormattedMessage id="errorPage.error.or" />{' '}
                    <a href={`tel: ${MAIL_INFO.PHONE}`}>{MAIL_INFO.PHONE}.</a>{' '}
                    <FormattedMessage id="errorPage.error.workingHours" />
                </p>
            </section>
            <section className="sales-error">
                <p className="error-description">
                    <FormattedMessage id="errorPage.error.errorInfo" />
                </p>
                <p className="error-uuid">
                    <FormattedMessage id="errorPage.error.errorId" />: <span>{errorMessage}</span>
                </p>
                <p>
                    <FormattedMessage id="errorPage.error.time" />: <span>{time}</span>
                </p>
            </section>
            <a className="report-button btn btn-redirect" href={linkToMail}>
                <FormattedMessage id="errorPage.error.reportIssue" />
            </a>
        </div>
    );
};

GeneralErrorPageComponent.propTypes = {
    error: PropTypes.shape({
        status: PropTypes.number,
        errors: PropTypes.arrayOf(
            PropTypes.shape({
                errorCode: PropTypes.string,
                reason: PropTypes.string,
                errorUUID: PropTypes.string,
            })
        ),
    }),
    intl: intlShape,
};

const mapStateToProps = state => ({
    error: selectTransformServerError(state),
    fsmaNumber: selectUserIntermedFsmaNumber(state),
    userId: selectUserActiveDirectoryId(state),
});

export const GeneralErrorPage = withRouter(
    connectWithIntl(mapStateToProps)(GeneralErrorPageComponent)
);
