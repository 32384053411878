import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const TableNoResultComponent = ({
    messageId,
    linkMessage,
    handleLinkClick,
    loading,
    isSearchPerformed,
}) => {
    if (loading) {
        return null;
    }

    if (!isSearchPerformed) {
        return (
            <div className="table-no-result-component">
                <FormattedMessage id={messageId} />
                <div onClick={handleLinkClick} className="table-no-result-component-link">
                    <FormattedMessage id={linkMessage} />
                </div>
            </div>
        );
    }

    return (
        <div className="no-results-container">
            <span className="no-results">
                <FormattedMessage id="table.search.noResults" />
            </span>
        </div>
    );
};

TableNoResultComponent.propTypes = {
    messageId: PropTypes.string,
    linkMessage: PropTypes.string,
    handleLinkClick: PropTypes.func,
    loading: PropTypes.bool,
    isSearchPerformed: PropTypes.bool,
};
