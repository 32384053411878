import {call, all, takeLatest, put, select} from 'redux-saga/effects';
import {change} from 'redux-form';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import {
    containerId,
    createBrokerManager,
    fetchBrokerCompanies,
    createFetchBrokerCompanies,
    clearBrokerManager,
    brokerManagerCreateActions,
    fetchBrokerManagerAccounts,
    selectBrokerManagerEmail,
    setIsPersonalInfoDisabled,
    saveCreatedBrokerManager,
} from 'features/BrokerManager/BrokerManagerCreate/reducer';
import {
    selectAndPrepareFormData,
    selectBrokerManagerAccountByEmail,
    makeSelectIsNewEmail,
} from 'features/BrokerManager/BrokerManagerCreate/selectors';

import {brokerManagers} from 'core/services/apiClient';
import {clearActionsStateSaga, defaultSubmitFormIfValidSaga} from 'core/sagaHelpers';
import {history} from 'core/services/history';
import {toastHandler} from 'core/services/toastHandler';

export function* createBrokerManagerSaga() {
    yield call(defaultSubmitFormIfValidSaga, containerId, saveCreatedBrokerManagerSaga);
}

export function* saveCreatedBrokerManagerSaga() {
    try {
        const requestBody = yield select(selectAndPrepareFormData);
        yield put(saveCreatedBrokerManager.request(requestBody));

        const brokerManagerId = yield call(brokerManagers.post, requestBody);
        yield put(saveCreatedBrokerManager.success());

        history.goToBrokerManagersDetails(brokerManagerId);
        toastHandler.onSuccess(null, null, 'notifications.brokerManager.create');
    } catch (err) {
        yield put(saveCreatedBrokerManager.failure(err));
    }
}

export function* fetchBrokerCompaniesSaga({payload: enterpriseNumber}) {
    // TODO: get rid of api call try/catch constructions
    try {
        const intermedCompanies = yield call(brokerManagers.getIntermedCompanies, enterpriseNumber);
        yield put(fetchBrokerCompanies.success(intermedCompanies));
    } catch (err) {
        yield put(fetchBrokerCompanies.failure(err));
    }
}

export function* createFetchBrokerCompaniesSaga({payload: enterpriseNumber}) {
    if (enterpriseNumber.length < 3) {
        yield put(fetchBrokerCompanies.success([]));
        return;
    }

    yield put(fetchBrokerCompanies.request(enterpriseNumber));
}

export function* clearBrokerManagerSaga() {
    yield call(clearActionsStateSaga, brokerManagerCreateActions);
}

export function* setIsPersonalInfoDisabledSaga(email) {
    if (!email.length) {
        yield put(setIsPersonalInfoDisabled.action(true));
        yield call(changeBrokerManagerFields);
        return;
    }

    const accountData = yield select(selectBrokerManagerAccountByEmail, email);

    if (accountData) {
        yield put(setIsPersonalInfoDisabled.action(true));
        yield call(changeBrokerManagerFields, accountData);
        return;
    }

    yield put(setIsPersonalInfoDisabled.action(false));
    yield call(changeBrokerManagerFields);
}

export function* getBrokerManagerEmail(option, selectIsNewEmail) {
    const isEmailNew = yield select(selectIsNewEmail);

    if (isEmailNew || !option) {
        yield call(changeBrokerManagerFields);
        return;
    }

    yield call(setIsPersonalInfoDisabledSaga, option);
}

export function* selectBrokerManagerEmailSaga({payload: option}) {
    const selectIsNewEmail = makeSelectIsNewEmail(option);

    yield call(getBrokerManagerEmail, option, selectIsNewEmail);
}

export function* changeBrokerManagerFields(data) {
    const tenantId = get(data, 'tenantId', null);
    const firstName = get(data, 'firstName', null);
    const surname = get(data, 'surname', null);

    yield put(change(containerId, 'tenantId', tenantId));
    yield put(change(containerId, 'firstName', firstName));
    yield put(change(containerId, 'surname', surname));
}

export function* fetchBrokerManagerAccountsSaga({payload: email}) {
    try {
        if (email.length < 3) {
            yield put(fetchBrokerManagerAccounts.success([]));
            yield call(setIsPersonalInfoDisabledSaga, email);
            return;
        }

        yield put(setIsPersonalInfoDisabled.action(true));

        const accounts = yield call(brokerManagers.searchAd, {email: encodeURIComponent(email)});
        yield put(fetchBrokerManagerAccounts.success(uniqBy(accounts, 'email')));
        yield call(setIsPersonalInfoDisabledSaga, email);
    } catch (err) {
        yield put(fetchBrokerManagerAccounts.failure(err));
    }
}

export const brokerManagerCreateSagas = function* () {
    yield all([
        takeLatest(createBrokerManager.ACTION, createBrokerManagerSaga),
        takeLatest(fetchBrokerCompanies.REQUEST, fetchBrokerCompaniesSaga),
        takeLatest(createFetchBrokerCompanies.ACTION, createFetchBrokerCompaniesSaga),
        takeLatest(clearBrokerManager.ACTION, clearBrokerManagerSaga),
        takeLatest(fetchBrokerManagerAccounts.REQUEST, fetchBrokerManagerAccountsSaga),
        takeLatest(selectBrokerManagerEmail.ACTION, selectBrokerManagerEmailSaga),
    ]);
};
