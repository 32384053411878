import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {getProducerAccountLabel} from 'core/services/utils';

export const WithMappedProducerAccountOptions = WrappedComponent => {
    function getDisplayName() {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    const resultComponent = class extends React.Component {
        static propTypes = {
            options: PropTypes.array,
        };

        state = {
            options: [],
        };

        static getDerivedStateFromProps(props, state) {
            if (props.options === state.options) {
                return null;
            }

            const updatedOptions = props.options.map(option => ({
                label: getProducerAccountLabel(props.intl, option),
                value: option.id,
                type: option.type,
                disabled: option.disabled,
                sharedProducerAccount: option.sharedProducerAccount,
            }));

            return {
                options: updatedOptions,
            };
        }

        displayName() {
            return `WithMappedProducerAccountOptions(${getDisplayName()})`;
        }

        render() {
            const {options} = this.state;
            return <WrappedComponent {...this.props} options={options} />;
        }
    };

    return injectIntl(resultComponent);
};
