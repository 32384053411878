import React from 'react';
import PropTypes from 'prop-types';
import {Modal as BootstrapModal} from 'react-bootstrap';

import './styles.scss';

export const Modal = ({onHide, show, children, actions}) => (
    <BootstrapModal show={show} keyboard backdrop="static" onHide={onHide}>
        <BootstrapModal.Header closeButton />
        <BootstrapModal.Body>{children}</BootstrapModal.Body>
        <BootstrapModal.Footer>{actions}</BootstrapModal.Footer>
    </BootstrapModal>
);

Modal.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    children: PropTypes.node,
    actions: PropTypes.node,
};
