import React from 'react';
import PropTypes from 'prop-types';
import {PermissionsService} from './PermissionsService';

export const createWithPermissions = ({PermissionsService}) => permissions => WrappedComponent => {
    function getDisplayName() {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    return class extends React.Component {
        static get propTypes() {
            return {
                onChange: PropTypes.func,
            };
        }

        displayName() {
            return `WithPermissions(${getDisplayName()})`;
        }

        render() {
            if (!PermissionsService.isPermissionSet(permissions)) {
                return null;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
};

export const withPermissions = createWithPermissions({PermissionsService});
