import React from 'react';
import {components} from 'react-select';

import './styles.scss';

export const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <span className="dropdown-input__indicator-arrow" />
    </components.DropdownIndicator>
);
