import React from 'react';
import {reduxForm, FieldArray, Field} from 'redux-form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import {ProducerAccounts} from 'features/Agent/common/components/ProducerAccounts';
import {
    makeSelectAgentData,
    selectMobileNumberError,
    selectMobileNumberValue,
} from 'features/Agent/common';
import {containerId} from 'features/Agent/common/reducer';
import {TextField} from 'components/common/Form';
import {PhoneNumberField} from 'components/common/PhoneNumberField';
import {AgentDetails} from 'components/Agent/AgentDetails';
import {dateOfBirth, mobilePhoneNumber} from 'core/fields';
import {connectWithIntl} from 'core/services/connectWithIntl';

import './styles.scss';
import {FeatureToggling} from 'core/FeatureToggling';

export const AgentEditDentFormComponent = ({
    agentData,
    change,
    intl,
    mobileNumberError,
    mobileNumberCode,
}) => (
    <>
        <AgentDetails {...agentData} isDetailsForm={false} />
        <div className="edit-dent-form-fields">
            <Row>
                <Col xs={4}>
                    <Field
                        name="dateOfBirth"
                        label={intl.formatMessage({
                            id: 'fields.agentCreate.dateOfBirth.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'fields.agentCreate.dateOfBirth.placeholder',
                        })}
                        component={TextField}
                        disabled={FeatureToggling.crmIntegrationEnabled}
                        {...dateOfBirth}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <PhoneNumberField
                        name="mobileNumber"
                        labelId="fields.agentCreate.mobilePhoneNumber.label"
                        change={change}
                        codeValue={mobileNumberCode}
                        phoneNumberError={mobileNumberError}
                        disabled={FeatureToggling.crmIntegrationEnabled}
                        {...mobilePhoneNumber}
                    />
                </Col>
            </Row>
        </div>

        <FieldArray change={change} name="producerAccounts" component={ProducerAccounts} />
    </>
);

const AgentEditDentReduxForm = reduxForm({form: containerId})(AgentEditDentFormComponent);

const mapStateToProps = () => {
    const selectAgentData = makeSelectAgentData();

    return state => ({
        agentData: selectAgentData(state),
        mobileNumberCode: selectMobileNumberValue(state),
        mobileNumberError: selectMobileNumberError(state),
    });
};

export const AgentEditDentForm = connectWithIntl(mapStateToProps)(AgentEditDentReduxForm);
