import {AsyncAction, Action, CaughtCachedAsyncAction} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'BrokerManagerCreate';

export const fetchBrokerManagerAccounts = new AsyncAction(
    `${containerId}/BROKER_MANAGER_FETCH_ACCOUNTS`,
    []
);

export const selectBrokerManagerEmail = new Action(`${containerId}/BROKER_MANAGER_SELECT_EMAIL`);
export const setIsPersonalInfoDisabled = new Action(
    `${containerId}/BROKER_MANAGER_TRIGGER_DISABLED`,
    true
);

export const createBrokerManager = new Action(`${containerId}/BROKER_MANAGER_CREATE`);
export const saveCreatedBrokerManager = new CaughtCachedAsyncAction(
    `${containerId}/BROKER_MANAGER_CREATED_SAVE`
);

export const fetchBrokerCompanies = new AsyncAction(`${containerId}/BROKER_COMPANIES_FETCH`, []);

export const createFetchBrokerCompanies = new Action(
    `${containerId}/BROKER_COMPANIES_CREATE_FETCH`,
    []
);

export const clearBrokerManager = new Action(`${containerId}/BROKER_MANAGER_CLEAR`);

export const brokerManagerCreateActions = [
    fetchBrokerManagerAccounts,
    saveCreatedBrokerManager,
    fetchBrokerCompanies,
    setIsPersonalInfoDisabled,
];

export const BrokerManagerCreateReducer = createReducer(brokerManagerCreateActions);
