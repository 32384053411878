import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const PageContentDetailsForm = ({children}) => (
    <div className="page-content-details-form">{children} </div>
);

PageContentDetailsForm.propTypes = {
    children: PropTypes.node,
};
