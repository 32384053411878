import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {noop} from 'core/services/utils';

import {ActionButton} from 'components/common/ActionButton';

export class ProducerAccountsWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node,

        amount: PropTypes.number,
        addButtonDisabled: PropTypes.bool,

        onAddButtonClick: PropTypes.func,
    };

    static defaultProps = {
        addButtonDisabled: false,
        onAddButtonClick: noop,
    };

    render() {
        const {children, amount, addButtonDisabled, onAddButtonClick} = this.props;

        return (
            <section name="producer-account">
                <h3>
                    <FormattedMessage id="sections.producerAccount" />
                    <span>({amount})</span>
                </h3>
                {children}
                <hr />
                <ActionButton
                    className="form-action"
                    onClick={onAddButtonClick}
                    disabled={addButtonDisabled}
                >
                    <FormattedMessage id="agentCreate.assignProducerAccount" />
                </ActionButton>
            </section>
        );
    }
}
