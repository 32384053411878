import React from 'react';
import PropTypes from 'prop-types';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {PageContentDetailsFormItem} from 'components/common/PageContent/PageContentDetailsForm/PageContentDetailsFormItem';
import {PageContentDetailsForm} from 'components/common/PageContent/PageContentDetailsForm';
import {PageContentDetailsFormStatusItem} from 'components/common/PageContent/PageContentDetailsForm/PageContentDetailsFormStatusItem';
import {selectBrokerManagerData} from '../../selectors';
import {ActionButton} from 'components/common/ActionButton';
import {FormattedMessage} from 'react-intl';

const brokerFieldWidthIndex = 3;

export class BrokerManagerDetailsFormComponent extends React.Component {
    static propTypes = {
        firstName: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string,
        enterpriseNumber: PropTypes.string,
        companyName: PropTypes.string,
        isActive: PropTypes.bool,
        isAlreadyExistsInB2c: PropTypes.bool,
        isSendingInvitation: PropTypes.bool,
        sendInvitation: PropTypes.func,
    };

    render() {
        const {
            firstName,
            surname,
            email,
            enterpriseNumber,
            companyName,
            isActive,
            isAlreadyExistsInB2c,
            isSendingInvitation,
            sendInvitation,
        } = this.props;

        return (
            <PageContentDetailsForm>
                <PageContentDetailsFormStatusItem
                    titleId="brokerManagerDetails.status"
                    isActive={isActive}
                    widthIndex={brokerFieldWidthIndex}
                />

                <PageContentDetailsFormItem
                    titleId="brokerManagerDetails.firstName"
                    value={firstName}
                    widthIndex={brokerFieldWidthIndex}
                />
                <PageContentDetailsFormItem
                    titleId="brokerManagerDetails.surname"
                    value={surname}
                    widthIndex={brokerFieldWidthIndex}
                />
                <PageContentDetailsFormItem
                    titleId="brokerManagerDetails.email"
                    value={email}
                    widthIndex={brokerFieldWidthIndex}
                />
                <PageContentDetailsFormItem
                    titleId="brokerManagerDetails.enterpriseNumber"
                    value={enterpriseNumber}
                    widthIndex={brokerFieldWidthIndex}
                />
                <PageContentDetailsFormItem
                    titleId="brokerManagerDetails.companyName"
                    value={companyName}
                    widthIndex={brokerFieldWidthIndex}
                />
                {!isAlreadyExistsInB2c && (
                    <ActionButton
                        className="button action"
                        action={isSendingInvitation}
                        disabled={!isActive}
                        onClick={sendInvitation}
                    >
                        <FormattedMessage id="misc.resend" />
                    </ActionButton>
                )}
            </PageContentDetailsForm>
        );
    }
}

const mapStateToProps = () => state => ({
    ...selectBrokerManagerData(state),
});

export const BrokerManagerDetailsForm = connectWithIntl(mapStateToProps)(
    BrokerManagerDetailsFormComponent
);
