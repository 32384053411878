import {apiClient} from 'core/services/apiClient/apiClient';
import {Url} from 'core/services/url';
import {Permissions} from 'core/services/permissions/';

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/gateway';

export const ENDPOINTS = {
    brokerManagers: '/broker-managers',
    agents: '/agents',
    user: '/users/me',
    intermeds: '/intermeds',
    status: '/status',
    usersSearch: '/users/search',
    producerAccounts: '/producer-accounts',
    dent: '/dent',
    external: '/external',
    b2cUserLogin: '/b2c-user-login',
};

export const b2cUserLogin = {
    verify: () => apiClient.post(new Url(`${API_URL}${ENDPOINTS.b2cUserLogin}`)),
};

export const user = {
    get: props => apiClient.get(new Url(`${API_URL}${ENDPOINTS.user}`, props), null),
    getProducerAccounts: () =>
        // TODO: add roles support
        apiClient.get(
            new Url(
                `${API_URL}${ENDPOINTS.user}${ENDPOINTS.producerAccounts}?role=BROKER_MANAGER_ROLE`
            )
        ),
};

// TODO: disscuss with backend required permissions for each api call
export const agents = {
    create: payload =>
        apiClient.post(new Url(`${API_URL}${ENDPOINTS.agents}`), payload, {
            permissions: Permissions.agent.create,
        }),
    getAgent: id =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.agents}/${id}`), null, {
            permissions: Permissions.agent.read,
        }),
    edit: (id, payload) =>
        apiClient.patch(
            new Url(`${API_URL}${ENDPOINTS.agents}/${id}${ENDPOINTS.external}`),
            payload,
            {
                permissions: Permissions.agent.update,
            }
        ),
    status: (id, payload) =>
        apiClient.patch(
            new Url(`${API_URL}${ENDPOINTS.agents}/${id}${ENDPOINTS.status}`),
            payload,
            {
                permissions: Permissions.agent.update,
            }
        ),
    updateProducerAccounts: (id, payload) =>
        apiClient.patch(new Url(`${API_URL}${ENDPOINTS.agents}/${id}${ENDPOINTS.dent}`), payload, {
            permissions: Permissions.agent.update,
        }),
    remove: id =>
        apiClient.delete(new Url(`${API_URL}${ENDPOINTS.agents}/${id}`), null, {
            permissions: Permissions.agent.delete,
        }),
    get: props =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.agents}`, props), null, {
            permissions: Permissions.agent.list,
        }),
    invitation: id =>
        apiClient.post(new Url(`${API_URL}${ENDPOINTS.agents}/${id}/invitation`), null, {
            permissions: Permissions.agent.update,
        }),
};

export const brokerManagers = {
    post: payload =>
        apiClient.post(new Url(`${API_URL}${ENDPOINTS.brokerManagers}`), payload, {
            permissions: Permissions.brokerManager.create,
        }),
    getBrokerManager: id =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.brokerManagers}/${id}`), null, {
            permissions: Permissions.brokerManager.read,
        }),
    edit: (id, payload) =>
        apiClient.patch(new Url(`${API_URL}${ENDPOINTS.brokerManagers}/${id}`), payload, {
            permissions: Permissions.brokerManager.update,
        }),
    status: (id, payload) =>
        apiClient.patch(
            new Url(`${API_URL}${ENDPOINTS.brokerManagers}/${id}${ENDPOINTS.status}`),
            payload,
            {
                permissions: Permissions.brokerManager.update,
            }
        ),
    remove: id =>
        apiClient.delete(new Url(`${API_URL}${ENDPOINTS.brokerManagers}/${id}`), null, {
            permissions: Permissions.brokerManager.delete,
        }),
    get: props =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.brokerManagers}`, props), null, {
            permissions: Permissions.brokerManager.list,
        }),

    searchAd: payload =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.usersSearch}`, payload), null, {
            permissions: Permissions.brokerManager.create,
        }),
    getIntermedCompanies: (enterpriseNumber = '') =>
        apiClient.get(new Url(`${API_URL}${ENDPOINTS.intermeds}`, {enterpriseNumber}), null, {
            permissions: Permissions.brokerManager.create,
        }),
    invitation: id =>
        apiClient.post(new Url(`${API_URL}${ENDPOINTS.brokerManagers}/${id}/invitation`), null, {
            permissions: Permissions.brokerManager.update,
        }),
};
