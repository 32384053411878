import PropTypes from 'prop-types';

export const matchShape = PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
});
