import includes from 'lodash/includes';
import {isTrue} from 'core/services/utils';
import {SupportedLocales} from 'core/constants';

// keep as array, must be extended with pseudolocalization and identifier locales
const devOnlyLocales = [SupportedLocales.enUS];

const reactAppProductionLocales = isTrue(process.env.REACT_APP_PRODUCTION_LOCALES);

// injection of dependencies are meant for testing purposes only
export const createLocaleServiceClass = ({
    SupportedLocales,
    localStorage,
    devOnlyLocales,
    reactAppProductionLocales,
}) =>
    class LocaleServiceClass {
        static _sourceLocale = SupportedLocales.enUS;
        static _productionLocales = reactAppProductionLocales;
        static _localesToExcludeInProduction = devOnlyLocales;
        static _localStorageKeys = {
            userLocale: 'userLocale',
            // used to override `_productionLocales` flag to show all locales
            showAllLocales: '_showAllLocales',
        };

        get locale() {
            return localStorage.getItem(LocaleServiceClass._localStorageKeys.userLocale);
        }

        set locale(locale) {
            if (!includes(this.locales, locale)) {
                return;
            }

            localStorage.setItem(LocaleServiceClass._localStorageKeys.userLocale, locale);
        }

        get fallbackLocale() {
            return LocaleServiceClass._sourceLocale;
        }

        get locales() {
            return this._locales;
        }

        get _shouldExcludeProductionLocales() {
            return (
                LocaleServiceClass._productionLocales &&
                !isTrue(localStorage.getItem(LocaleServiceClass._localStorageKeys.showAllLocales))
            );
        }

        constructor() {
            this._initializeSupportedLocales();
            this._verifyExistingLocale();
        }

        _initializeSupportedLocales() {
            let locales = Object.values(SupportedLocales);

            if (this._shouldExcludeProductionLocales) {
                locales = locales.filter(
                    locale => !includes(LocaleServiceClass._localesToExcludeInProduction, locale)
                );
            }

            this._locales = locales;
        }

        _verifyExistingLocale() {
            if (!this.locale || !includes(this.locales, this.locale)) {
                this.locale = this.locales[0];
            }
        }
    };

const LocaleServiceClass = createLocaleServiceClass({
    SupportedLocales,
    localStorage,
    devOnlyLocales,
    reactAppProductionLocales,
});
export const LocaleService = new LocaleServiceClass();

// TODO: update values with localization keys
const localeToLabelMap = new Map([
    [SupportedLocales.enUS, 'EN'],
    [SupportedLocales.nlBE, 'NL'],
    [SupportedLocales.frFR, 'FR'],
]);

export const selectOptionLocales = LocaleService.locales.map(value => ({
    value,
    label: localeToLabelMap.has(value) ? localeToLabelMap.get(value) : value,
}));
