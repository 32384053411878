import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {Modal as CommonModal} from 'components/common/Modal';
import {selectShouldShowModal} from 'features/common/Modal/selectors';
import {hideModal, initializeModal} from 'features/common/Modal/reducer';
import {ActionButton} from 'components/common/ActionButton';

export class ModalComponent extends React.PureComponent {
    static propTypes = {
        cancelLocKey: PropTypes.string,
        hideCancel: PropTypes.bool,
        show: PropTypes.bool,
        children: PropTypes.node,
        actionsPanel: PropTypes.node,
        modalId: PropTypes.string.isRequired,
        onHideModal: PropTypes.func,
        initializeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hideCancel: false,
        cancelLocKey: 'misc.cancel',
    };

    componentDidMount() {
        const {initializeModal, modalId} = this.props;
        initializeModal(modalId);
    }

    componentWillUnmount() {
        this.onHideModal();
    }

    render() {
        const {show, children} = this.props;

        return (
            <CommonModal show={show} onHide={this.onHideModal} actions={this.modalActionsPanel()}>
                {children}
            </CommonModal>
        );
    }

    onHideModal = () => {
        const {onHideModal, modalId} = this.props;
        onHideModal(modalId);
    };

    modalActionsPanel = () => {
        const {actionsPanel, hideCancel, cancelLocKey} = this.props;

        return (
            <>
                {!hideCancel && (
                    <ActionButton onClick={this.onHideModal}>
                        <FormattedMessage id={cancelLocKey} />
                    </ActionButton>
                )}
                {actionsPanel}
            </>
        );
    };
}

const mapStateToProps = () => {
    return (state, ownProps) => ({
        show: selectShouldShowModal(state, ownProps.modalId),
    });
};

export const Modal = connect(mapStateToProps, {
    initializeModal: initializeModal.action,
    onHideModal: hideModal.action,
})(ModalComponent);
