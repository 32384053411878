import React from 'react';
import {FormattedMessage} from 'react-intl';

import {Tooltip} from 'components/common/Tooltip';
import {selectIsBrokerManagerRemoveDisabled} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {connectWithIntl} from 'core/services/connectWithIntl';

export class BrokerManagerRemoveTooltipComponent extends React.PureComponent {
    render() {
        const {showTooltip} = this.props;

        if (!showTooltip) {
            return null;
        }

        return (
            <Tooltip>
                <FormattedMessage id="brokerManagerEdit.removeTooltip" />
            </Tooltip>
        );
    }
}

const mapStateToProps = state => ({
    showTooltip: selectIsBrokerManagerRemoveDisabled(state),
});

export const BrokerManagerRemoveTooltip = connectWithIntl(mapStateToProps)(
    BrokerManagerRemoveTooltipComponent
);
