import get from 'lodash/get';
import {createSelector} from 'reselect';
import {getFormValues} from 'redux-form';

import {
    selectAsyncActionError,
    selectIsInProgressStatus,
    selectAsyncActionCache,
} from 'core/createReducer';
import {getStateByContainerId} from 'core/selectors';

import {containerId, saveCreatedAgent} from 'features/Agent/AgentCreate/reducer';
import {containerId as commonContainerId} from 'features/Agent/common/reducer';

const selectFormValues = getFormValues(commonContainerId);

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsSaving = state =>
    selectIsInProgressStatus(getCurrentState(state), saveCreatedAgent.type);

const selectCreateAgentError = state =>
    selectAsyncActionError(getCurrentState(state), saveCreatedAgent.type);

const selectSavedAgentEmail = state =>
    selectAsyncActionCache(getCurrentState(state), saveCreatedAgent.type, 'email');

const selectEmailValue = state => get(selectFormValues(state), 'email');

export const makeSelectEmailRegisteredError = () =>
    createSelector(
        [selectCreateAgentError, selectSavedAgentEmail, selectEmailValue],
        (error, savedAgentEmail, emailValue) => {
            const status = get(error, 'status');
            if (status !== 409) {
                return null;
            }

            if (savedAgentEmail && savedAgentEmail !== emailValue) {
                return null;
            }

            return {
                id: 'registeredEmailError',
            };
        }
    );
