import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {ButtonsGroup} from 'components/common/ButtonsGroup';
import {ActionButton} from 'components/common/ActionButton';
import {FeatureToggling} from '../../../../core/FeatureToggling';

const BrokerManagerEditActionsComponent = ({
                                               id,
                                               onSubmit,
                                               onCancel,
                                               onChangeStatus,
                                               isSaving,
                                               isActive,
                                               isChangingStatus,
                                               onRemove,
                                               isRemoving,
                                               isRemoveDisabled,
                                           }) => (
    <ButtonsGroup onCancel={() => onCancel(id)}>
        {!FeatureToggling.crmIntegrationEnabled && (
            <ActionButton
                className="button-action"
                onClick={onRemove}
                action={isRemoving}
                disabled={isRemoveDisabled}
            >
                <FormattedMessage id="misc.remove" />
            </ActionButton>
        )}
        <ActionButton
            className="button-action trigger-activate"
            onClick={onChangeStatus}
            action={isChangingStatus}
        >
            <FormattedMessage id={`brokerManagerEdit.${isActive ? 'deactivate' : 'activate'}`} />
        </ActionButton>
        <ActionButton className="action" onClick={onSubmit} action={isSaving}>
            <FormattedMessage id="misc.save" />
        </ActionButton>
    </ButtonsGroup>
);

BrokerManagerEditActionsComponent.propTypes = {
    id: PropTypes.number,
    isActive: PropTypes.bool,
    isChangingStatus: PropTypes.bool,
    isSaving: PropTypes.bool,
    isRemoving: PropTypes.bool,
    isRemoveDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onChangeStatus: PropTypes.func,
    onRemove: PropTypes.func,
};

export const BrokerManagerEditActions = memo(BrokerManagerEditActionsComponent);
