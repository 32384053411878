import {ProducerAccounts as ProducerAccountsComponent} from 'components/Agent/ProducerAccounts';
import {connectWithIntl} from 'core/services/connectWithIntl';

import {
    showRemoveProducerAccountModal,
    changeProducerAccount,
    addProducerAccount,
} from 'features/Agent/common/reducer';

import {
    makeSelectProducerAccountOptions,
    makeSelectSelectedProducerAccountsAmount,
    makeSelectIsAddProducerAccountDisabled,
    selectProducerAccountsFormErrors,
    selectChosenProducerAccounts,
} from 'features/Agent/common/selectors';

const makeMapStateToProps = () => {
    const selectProducerAccountOptions = makeSelectProducerAccountOptions();
    const selectSelectedProducerAccountsAmount = makeSelectSelectedProducerAccountsAmount();
    const selectIsAddProducerAccountDisabled = makeSelectIsAddProducerAccountDisabled();

    return state => ({
        savedProducerAccounts: selectChosenProducerAccounts(state),
        amount: selectSelectedProducerAccountsAmount(state),
        options: selectProducerAccountOptions(state),
        addButtonDisabled: selectIsAddProducerAccountDisabled(state),
        formErrors: selectProducerAccountsFormErrors(state),
    });
};

export const ProducerAccounts = connectWithIntl(makeMapStateToProps, {
    onProducerAccountChange: changeProducerAccount.action,
    onAddButtonClick: addProducerAccount.action,
    onRemoveButtonClick: showRemoveProducerAccountModal.action,
})(ProducerAccountsComponent);
