import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import './styles.scss';

export const PageWrapper = ({children}) => (
    <div className="page-wrapper">
        <Row className="page-row">
            <Col xs={12}>{children}</Col>
        </Row>
    </div>
);

PageWrapper.propTypes = {
    children: PropTypes.node,
};
