import get from 'lodash/get';
import {put, takeLatest, call, all, select} from 'redux-saga/effects';
import {LocaleService} from 'core/services/localeService';
import {PermissionsService} from 'core/services/permissions';
import {b2cUserLogin, user} from 'core/services/apiClient';

import {setGeneralError} from 'features/ErrorBoundary';
import {updateLocale, setLocale, fetchUserInformation} from './reducer';
import {selectDocumentTitle} from './selectors';

export function* setLocaleSaga({payload: locale}) {
    LocaleService.locale = locale;
    yield put(updateLocale.action(LocaleService.locale));

    document.title = yield select(selectDocumentTitle);
}

export function* fetchUserInformationSaga() {
    try {
        yield call(b2cUserLogin.verify);
        const userInformation = yield call(user.get);
        const permissions = get(userInformation, 'permissions');
        PermissionsService._add(permissions);

        yield put(fetchUserInformation.success(userInformation));
    } catch (err) {
        yield put(fetchUserInformation.failure(err));
        yield put(setGeneralError.action(err));
    }
}

export const appSagas = function* () {
    yield all([takeLatest(setLocale.ACTION, setLocaleSaga)]);
    yield all([takeLatest(fetchUserInformation.REQUEST, fetchUserInformationSaga)]);
};
