import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {Modal} from 'features/common/Modal';
import {ActionButton} from 'components/common/ActionButton';
import {removeBrokerManager} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {connectWithIntl} from 'core/services/connectWithIntl';

export const BROKER_MANAGER_REMOVE_MODAL = 'BrokerManageRemoveModal';

export class BrokerManagerRemoveModalComponent extends React.PureComponent {
    static propTypes = {
        onRemove: PropTypes.func,
    };

    render() {
        return (
            <Modal modalId={BROKER_MANAGER_REMOVE_MODAL} actionsPanel={this.resolveActionsPanel()}>
                <FormattedMessage id="brokerManagerEdit.removeQuestion" />
            </Modal>
        );
    }

    resolveActionsPanel = () => {
        const {onRemove} = this.props;

        return (
            <ActionButton onClick={onRemove} className="warning">
                <FormattedMessage id="misc.remove" />
            </ActionButton>
        );
    };
}

export const BrokerManagerRemoveModal = connectWithIntl(null, {
    onRemove: removeBrokerManager.request,
})(BrokerManagerRemoveModalComponent);
