import {call, all, takeLatest, put} from 'redux-saga/effects';
import {brokerManagers} from 'core/services/apiClient';
import {fetchBrokerManager, sendInvitation} from './reducer';
import {toastHandler} from 'core/services/toastHandler';

export function* fetchBrokerManagerSaga({payload: brokerManagerId}) {
    try {
        const brokerManagerData = yield call(brokerManagers.getBrokerManager, brokerManagerId);
        yield put(fetchBrokerManager.success(brokerManagerData));
    } catch (err) {
        yield put(fetchBrokerManager.failure(err));
    }
}

export function* sendInvitationSaga({payload: brokerManagerId}) {
    try {
        yield call(brokerManagers.invitation, brokerManagerId);
        yield put(sendInvitation.success());
        toastHandler.onSuccess(null, null, 'misc.activationEmailIsSent');
    } catch (error) {
        yield put(sendInvitation.failure(error));
    }
}

export const brokerManagerDetailsSagas = function* () {
    yield all([
        takeLatest(fetchBrokerManager.REQUEST, fetchBrokerManagerSaga),
        takeLatest(sendInvitation.REQUEST, sendInvitationSaga),
    ]);
};
