import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';

export function NotificationComponent({intl, className, toastIcon, altMessageKey, children}) {
    return (
        <div className="toast-wrapper">
            <div className={className}>
                <img
                    src={toastIcon}
                    alt={
                        altMessageKey &&
                        intl.formatMessage({
                            id: altMessageKey,
                        })
                    }
                />
            </div>
            <div className="toast-content">{children}</div>
        </div>
    );
}

NotificationComponent.propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    toastIcon: PropTypes.string,
    altMessageKey: PropTypes.string,
    children: PropTypes.node,
};

NotificationComponent.defaultProps = {
    className: '',
    altMessageKey: '',
    toastIcon: '',
    children: null,
};

export const Notification = injectIntl(NotificationComponent);
