import {createReducer} from 'core/createReducer';
import {Action} from 'core/actionHelpers';

export const containerId = 'ErrorBoundary';

export const setGeneralError = new Action(`${containerId}/GENERAL_ERROR_SET`);

const actionToHandlerMap = new Map([
    [
        setGeneralError.ACTION,
        (draft, {payload}) => {
            const {status, errors} = payload;
            draft[setGeneralError.type] = {
                status,
                errors,
                userTime: Date.now(),
                userAgent: navigator.userAgent,
            };
        },
    ],
]);
export const errorBoundaryReducer = createReducer([setGeneralError], null, actionToHandlerMap);
