import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {PageWrapper} from 'components/common/PageWrapper';
import {TableWrapper} from 'components/common/Table/TableWrapper';
import headerIcon from 'assets/images/group.svg';

import {
    BrokerManagerListButtons,
    BrokerManagerListTable,
    BrokerManagerListSearch,
    BrokerManagerListHeader,
} from './components';
import {clearBrokerManagersData, fetchBrokerManagersData} from './reducer';
import {FeatureToggling} from 'core/FeatureToggling';

export class BrokerManagerListComponent extends React.Component {
    static propTypes = {
        totalCount: PropTypes.number,
        fetchData: PropTypes.func,
        clearData: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchData();
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    render() {
        return (
            <PageWrapper>
                <BrokerManagerListHeader
                    titleId={'brokerManagerList.title'}
                    headerIcon={headerIcon}
                />
                <TableWrapper
                    headerLeft={
                        <BrokerManagerListSearch tableSearchPlaceholderId="brokerManagerList.table.searchPlaceholder" />
                    }
                    headerRight={!FeatureToggling.crmIntegrationEnabled ? <BrokerManagerListButtons /> : null}
                >
                    <BrokerManagerListTable />
                </TableWrapper>
            </PageWrapper>
        );
    }
}

export const BrokerManagerList = connect(null, {
    fetchData: fetchBrokerManagersData.action,
    clearData: clearBrokerManagersData.action,
})(BrokerManagerListComponent);
