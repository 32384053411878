import {reduxForm} from 'redux-form';

import {WithMappedDropdownOptions} from 'core/hoc/MappedDropdownOptions';
import {connectWithIntl} from 'core/services/connectWithIntl';
import {AgentCreate} from 'components/Agent/AgentCreate';
import {containerId} from 'features/Agent/common/reducer';
import {
    selectContactNumberError,
    selectContactNumberValue,
    selectMobileNumberError,
    selectMobileNumberValue,
} from 'features/Agent/common/selectors';

export const AgentReduxForm = reduxForm({
    form: containerId,
})(AgentCreate);

const mapStateToProps = () => {
    return state => ({
        contactNumberCode: selectContactNumberValue(state),
        contactNumberError: selectContactNumberError(state),
        mobileNumberCode: selectMobileNumberValue(state),
        mobileNumberError: selectMobileNumberError(state),
    });
};

export const AgentEditForm = connectWithIntl(mapStateToProps)(
    WithMappedDropdownOptions(AgentReduxForm)
);
