import get from 'lodash/get';
import {createSelector} from 'reselect';
import {getFormValues} from 'redux-form';

import {selectIsInProgressStatus, selectAsyncActionData} from 'core/createReducer';
import {
    editBrokerManager,
    containerId,
    fetchBrokerManagerData,
    changeStatusBrokerManager,
    removeBrokerManager,
} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {getStateByContainerId} from 'core/selectors';
import {entityStatus} from 'core/constants';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = getFormValues(containerId);

export const selectIsSaving = state =>
    selectIsInProgressStatus(getCurrentState(state), editBrokerManager.type);

export const selectBrokerManagerData = state =>
    selectAsyncActionData(getCurrentState(state), fetchBrokerManagerData.type);

export const selectIsFetchingBrokerManagerData = state =>
    selectIsInProgressStatus(getCurrentState(state), fetchBrokerManagerData.type);

export const selectIsChangingStatus = state =>
    selectIsInProgressStatus(getCurrentState(state), changeStatusBrokerManager.type);

export const selectIsRemovingBrokerManager = state =>
    selectIsInProgressStatus(getCurrentState(state), removeBrokerManager.type);

export const selectBrokerManagerId = state => get(selectBrokerManagerData(state), 'id', null);

export const selectIsBrokerManagerRemoveDisabled = state =>
    !get(selectBrokerManagerData(state), 'isAllowedToDelete', true);

export const makeSelectIsActive = () =>
    createSelector(selectBrokerManagerData, brokerManager =>
        brokerManager ? brokerManager.isActive : null
    );

export const makeSelectInitialValues = () =>
    createSelector(selectBrokerManagerData, brokerManager => {
        if (!brokerManager) {
            return null;
        }

        const {
            firstName,
            surname,
            email,
            intermed: {enterpriseNumber, name},
        } = brokerManager;

        return {
            firstName,
            surname,
            email,
            companyName: `${enterpriseNumber} ${name}`,
        };
    });

export const selectAndPrepareFormData = state => {
    const {firstName, surname} = selectFormValues(state);

    return {
        firstName,
        surname,
    };
};

export const selectChangeStatusRequestBody = state => {
    const isActive = makeSelectIsActive()(state);
    return {status: isActive ? entityStatus.inactive : entityStatus.active};
};
