import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {noop} from 'core/services/utils';

import {ActionButton} from 'components/common/ActionButton';
import {ButtonsGroup} from 'components/common/ButtonsGroup';

const ButtonsGroupCreateEntityComponent = ({onSubmit, onCancel, isSaving}) => (
    <ButtonsGroup onCancel={onCancel}>
        <ActionButton className="action" onClick={onSubmit} action={isSaving}>
            <FormattedMessage id="misc.create" />
        </ActionButton>
    </ButtonsGroup>
);

ButtonsGroupCreateEntityComponent.propTypes = {
    isSaving: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

ButtonsGroupCreateEntityComponent.defautProps = {
    isSaving: false,
    onSubmit: noop,
    onCancel: noop,
};

export const ButtonsGroupCreateEntity = memo(ButtonsGroupCreateEntityComponent);
