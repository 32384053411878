import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import BootstrapTooltip from 'react-bootstrap/lib/Tooltip';

import './styles.scss';

export class Tooltip extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
        type: PropTypes.oneOf(['info', 'warn']),
    };

    static defaultProps = {
        placement: 'right',
        type: 'info',
    };

    overlay = React.createRef();

    render() {
        const {placement, type} = this.props;

        return (
            <OverlayTrigger
                placement={placement}
                overlay={this.resolveTooltip()}
                rootClose={type === 'warn'}
                trigger={type === 'info' ? ['hover', 'focus'] : 'click'}
                ref={this.overlay}
            >
                <span className={`${type}-sign`}>{type === 'info' ? '?' : '!'}</span>
            </OverlayTrigger>
        );
    }

    resolveTooltip = () => {
        const {type, children} = this.props;
        return (
            <BootstrapTooltip id="calc-tooltip" className={type}>
                {type === 'warn' && (
                    <button type="button" className="close-btn" onClick={this.hide}>
                        <i className="icon icon-close" />
                    </button>
                )}
                {children}
            </BootstrapTooltip>
        );
    };

    hide = () => {
        this.overlay && this.overlay.current.hide();
    };
}
