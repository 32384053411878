import React from 'react';
import PropTypes from 'prop-types';

import {SpinnerLoadingWrapper} from 'components/common/LoadingWrapper/SpinnerLoadingWrapper';

export const PageContent = ({children, isLoading}) => (
    <SpinnerLoadingWrapper isLoading={isLoading}>{children}</SpinnerLoadingWrapper>
);

PageContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};
