import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {matchShape} from 'core/propTypes';
import {LoadingWrapper} from 'components/common/LoadingWrapper';
import {PageContent} from 'components/common/PageContent';
import {PageWrapper} from 'components/common/PageWrapper';
import {PageControls} from 'components/common/PageControls';
import {BrokerManagerDetailsActions, BrokerManagerDetailsForm} from './components';
import {fetchBrokerManager, sendInvitation} from './reducer';
import { selectIsLoading, selectIsSendingInvitation} from './selectors';

export class BrokerManagerDetailsComponent extends React.Component {
    static propTypes = {
        match: matchShape,
        isLoading: PropTypes.bool,
        isSendingInvitation: PropTypes.bool,
        fetchData: PropTypes.func.isRequired,
        sendInvitation: PropTypes.func.isRequired,
        clearData: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {
            fetchData,
            match: {
                params: {id},
            },
        } = this.props;

        fetchData(id);
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    render() {
        const {
            match: {
                params: {id},
            },
            isLoading,
            isSendingInvitation,
            sendInvitation,
        } = this.props;

        return (
            <PageWrapper>
                <PageControls title={<FormattedMessage id="brokerManagerDetails.title" />}>
                    <LoadingWrapper isLoading={isLoading}>
                        <BrokerManagerDetailsActions />
                    </LoadingWrapper>
                </PageControls>
                <PageContent isLoading={isLoading}>
                    <BrokerManagerDetailsForm
                        isSendingInvitation={isSendingInvitation}
                        sendInvitation={() => sendInvitation(id)}
                    />
                </PageContent>
            </PageWrapper>
        );
    }
}

const mapStateToProps = () => {
    return state => ({
        isLoading: selectIsLoading(state),
        isSendingInvitation: selectIsSendingInvitation(state),
    });
};

export const BrokerManagerDetails = connect(mapStateToProps, {
    fetchData: fetchBrokerManager.request,
    clearData: fetchBrokerManager.clear,
    sendInvitation: sendInvitation.request,
})(BrokerManagerDetailsComponent);
