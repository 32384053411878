import get from 'lodash/get';

import {getStateByContainerId} from 'core/selectors';
import {containerId} from 'features/common/Modal/reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectModal = (state, modalId) => get(getCurrentState(state), modalId, null);

export const selectShouldShowModal = (state, modalId) => get(selectModal(state, modalId), 'show');
