import {Action} from 'core/actionHelpers';
import {createReducer} from 'core/createReducer';

export const containerId = 'Modal';

export const initializeModal = new Action(`${containerId}/MODAL_INITIALIZE`);

export const showModal = new Action(`${containerId}/MODAL_SHOW`);
export const hideModal = new Action(`${containerId}/MODAL_HIDE`);

const actionToHandlerMap = new Map([
    [
        initializeModal.ACTION,
        (draft, {payload: modalId}) => {
            draft[modalId] = {show: false};
        },
    ],
    [
        showModal.ACTION,
        (draft, {payload: modalId}) => {
            draft[modalId].show = true;
        },
    ],
    [
        hideModal.ACTION,
        (draft, {payload: modalId}) => {
            draft[modalId].show = false;
        },
    ],
]);

export const ModalReducer = createReducer(null, null, actionToHandlerMap);
