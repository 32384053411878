import React from 'react';
import {FormattedMessage} from 'react-intl';

import {connectWithIntl} from 'core/services/connectWithIntl';
import {TableComponent} from 'components/common/Table/TableComponent';
import {TableStatusCell} from 'components/common/Table/TableStatusCell';
import {AgentListNoResultData} from './AgentListNoResultData';
import {
    makeSelectAgentShowTableHeader,
    selectAgentCurrentPage,
    selectAgentList,
    selectAgentTotalPages,
    selectIsLoading,
} from '../../selectors';
import {fetchAgentList, setAgentCurrentPage} from '../../reducer';

import {history} from 'core/services/history';

const columns = [
    {
        Header: <FormattedMessage id={'agentList.table.name'} />,
        accessor: row => `${row.surname} ${row.firstName}`,
        id: 'fullName',
    },
    {
        Header: <FormattedMessage id={'agentList.table.email'} />,
        accessor: 'email',
    },
    {
        Header: <FormattedMessage id={'agentList.table.status.title'} />,
        accessor: row => ({
            isActive: row.isActive,
            isInformationMissing: row.isInformationMissing,
        }),
        id: 'status',
        Cell: row => (
            <TableStatusCell
                isActive={row.value.isActive}
                isInformationMissing={row.value.isInformationMissing}
            />
        ),
    },
];

const mapStateToProps = () => {
    const selectShouldShowColumns = makeSelectAgentShowTableHeader();

    return state => ({
        data: selectAgentList(state),
        columns,
        page: selectAgentCurrentPage(state),
        totalPages: selectAgentTotalPages(state),
        loading: selectIsLoading(state),
        noDataComponent: AgentListNoResultData,
        onRowClick: history.goToAgentDetails,
        shouldShowColumns: selectShouldShowColumns(state),
    });
};

export const AgentListTable = connectWithIntl(mapStateToProps, {
    fetchDataHandler: fetchAgentList.request,
    onPageChange: setAgentCurrentPage.action,
})(TableComponent);
