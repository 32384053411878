import {AuthError} from './AuthError';
import {initializeMsalProvider} from './auth-utils';
import isEmpty from 'lodash/isEmpty';

const B2C_TENANT = process.env.REACT_APP_B2C_TENANT
    ? process.env.REACT_APP_B2C_TENANT
    : 'delabedevb2c';
const B2C_APP_ID = process.env.REACT_APP_B2C_APP_ID
    ? process.env.REACT_APP_B2C_APP_ID
    : 'feb1e361-6138-4bda-ab0e-65dc9c2f3d0e';
const DEFAULT_AUTHORITY = `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/`;

export const FLOW_NAMES = {
    SIGN_UP_SIGN_IN: 'B2C_1_UserManagement_SignUpSignIn',
    RESET_PASSWORD: 'B2C_1_UserManagement_ResetPassword',
    SIGN_UP_INVITATION: 'B2C_1A_LocalUser_SignUp_Invitation',
};
const B2C_SCOPES = [`openid`, 'offline_access'];
export const AUTH_REQUESTS = {
    SIGN_IN: {
        scopes: B2C_SCOPES,
        prompt: 'select_account',
    },
    RENEW: {
        scopes: [B2C_APP_ID],
        redirectUrl: window.location.origin + '/auth.html',
    },
    RESET_PASSWORD: {
        authority: DEFAULT_AUTHORITY + FLOW_NAMES.RESET_PASSWORD,
    },
};

export class AuthService {
    static STATE = {
        UNINITIALIZED: 'UNINITIALIZED',
        INITIALIZED: 'INITIALIZED',
    };

    provider;
    hashObject;
    state = AuthService.STATE.UNINITIALIZED;

    constructor(provider, hashObject) {
        this.provider = provider;
        this.hashObject = hashObject;
    }

    initialize = () => {
        if (!isEmpty(this.hashObject)) {
            this.processHashObject(this.hashObject);
        } else {
            this.state = AuthService.STATE.INITIALIZED;
        }
    };

    processHashObject = hashObject => {
        if (
            hashObject.hasOwnProperty('error_description') &&
            AuthError.isPasswordResetError(hashObject.error_description)
        ) {
            this.provider.loginRedirect(AUTH_REQUESTS.RESET_PASSWORD);
            return;
        }
        if (
            hashObject.hasOwnProperty('state') &&
            hashObject.state === FLOW_NAMES.SIGN_UP_INVITATION
        ) {
            this.signIn();
            return;
        }
        this.state = AuthService.STATE.INITIALIZED;
    };

    isAuthenticated = () => {
        return this.state === AuthService.STATE.INITIALIZED && Boolean(this.provider.getAccount());
    };

    getToken = async () => {
        try {
            return await this.provider.acquireTokenSilent(AUTH_REQUESTS.RENEW);
        } catch (error) {
            if (error && AuthError.isInteractionRequired(error)) {
                this.provider.acquireTokenRedirect(AUTH_REQUESTS.RENEW);
            } else {
                this.signIn();
            }
        }
    };

    signIn = () => {
        this.provider.loginRedirect(AUTH_REQUESTS.SIGN_IN);
    };

    signOut = () => {
        this.provider.logout();
    };
}

const {provider, hashObject} = initializeMsalProvider({
    authority: DEFAULT_AUTHORITY + FLOW_NAMES.SIGN_UP_SIGN_IN,
    clientId: B2C_APP_ID,
});

export const authService = new AuthService(provider, hashObject);
