import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';

import {history, ROUTES} from 'core/services/history';
import {userRoles} from 'core/constants';
import {selectOptionLocales} from 'core/services/localeService';
import {authService} from 'core/services/auth';
import {Permissions, PermissionsService} from 'core/services/permissions';
import {connectWithIntl} from 'core/services/connectWithIntl';

import {LocaleSwitcher} from 'components/LocaleSwitcher';
import {Header} from 'components/Header';
import {OverflowTooltip} from 'components/common/OverflowTooltip';
import logoutIcon from 'assets/images/logout.svg';
import logoIcon from 'assets/images/logo.svg';

import {setLocale} from 'features/App/reducer';
import {selectLocale, selectUserFullName, selectUserRole} from 'features/App/selectors';

import './styles.scss';

export const mapUserRoleToLocId = new Map([
    [userRoles.brokerManager, 'header.brokerManager'],
    [userRoles.dentBrokerManager, 'header.brokerManager'],
    [userRoles.productAdmin, 'header.productAdmin'],
]);

export const createApplicationHeader = ({PermissionsService}) =>
    class extends React.Component {
        static propTypes = {
            locale: PropTypes.string,
            userFullName: PropTypes.string,
            userRole: PropTypes.string,
            showUserInformation: PropTypes.bool,
            setLocale: PropTypes.func,
            signOut: PropTypes.func,
        };

        render() {
            const {locale, signOut, userFullName, userRole, intl} = this.props;

            return (
                <Header className="header-app">
                    <div className="header-app-left">
                        <NavLink className="header-app-logo" to={ROUTES.default}>
                            <img
                                src={logoIcon}
                                alt={intl.formatMessage({
                                    id: 'header.home',
                                })}
                            />
                        </NavLink>
                        {PermissionsService.isPermissionSet(Permissions.brokerManager.list) && (
                            <NavLink
                                className="header-app-link"
                                activeClassName="header-app-link-active"
                                to={ROUTES.brokerManagers}
                            >
                                <FormattedMessage id="header.brokerManagers" />
                            </NavLink>
                        )}
                        {PermissionsService.isPermissionSet(Permissions.agent.list) && (
                            <NavLink
                                className="header-app-link"
                                activeClassName="header-app-link-active"
                                to={ROUTES.agents}
                            >
                                <FormattedMessage id="header.agents" />
                            </NavLink>
                        )}
                    </div>
                    <div className="header-app-right">
                        {userRole && (
                            <div className="header-app-right-info">
                                <FormattedMessage id={mapUserRoleToLocId.get(userRole)} />
                                <div className="header-app-right-info-title">
                                    <OverflowTooltip tooltip={userFullName}>
                                        {userFullName}
                                    </OverflowTooltip>
                                </div>
                            </div>
                        )}
                        <div className="header-app-right-locale">
                            <LocaleSwitcher
                                locale={locale}
                                locales={selectOptionLocales}
                                onLocaleChange={this.handleLocaleChange}
                            />
                        </div>
                        <div onClick={signOut} className="header-app-right-logout">
                            <img
                                src={logoutIcon}
                                alt={intl.formatMessage({
                                    id: 'header.logOut',
                                })}
                            />
                        </div>
                    </div>
                </Header>
            );
        }

        handleLocaleChange = selectedOption => {
            if (!selectedOption) {
                return;
            }

            const {setLocale} = this.props;
            setLocale(selectedOption.value);
        };
    };

export const ApplicationHeaderComponent = createApplicationHeader({
    PermissionsService,
});

const mapStateToProps = state => ({
    locale: selectLocale(state),
    // we pass location here to trigger re-rendering
    location: history.browserHistory.location,
    userFullName: selectUserFullName(state),
    userRole: selectUserRole(state),
    signOut: authService.signOut,
});

export const ApplicationHeader = connectWithIntl(mapStateToProps, {setLocale: setLocale.action})(
    ApplicationHeaderComponent
);
