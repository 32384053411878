import get from 'lodash/get';
import {all, call, put, select, takeLatest, take} from 'redux-saga/effects';
import {brokerManagers} from 'core/services/apiClient';
import {DEFAULT_TABLE_SIZE} from 'core/constants';
import {
    brokerManagersListStateActions,
    fetchBrokerManagersData,
    fetchBrokerManagersList,
    setBrokerManagersPageInfo,
    setBrokerManagersTotalItemsCount,
    clearBrokerManagersData,
    searchBrokerManagersList,
    setBrokerManagersCurrentPage,
} from './reducer';

import {
    selectBrokerManagersCurrentPage,
    selectBrokerManagersSearchValue,
    selectBrokerManagersTotalItemsCount,
} from './selectors';
import {clearActionsStateSaga} from 'core/sagaHelpers';

export function* clearBrokerManagersDataSaga() {
    yield call(clearActionsStateSaga, brokerManagersListStateActions);
}

export function* fetchBrokerManagersDataSaga() {
    yield put(fetchBrokerManagersList.request());

    yield take(fetchBrokerManagersList.SUCCESS);
    const totalItemsCount = yield select(selectBrokerManagersTotalItemsCount);
    yield put(setBrokerManagersTotalItemsCount.action(totalItemsCount));
}

export function* fetchBrokerManagersListSaga() {
    try {
        const currentPage = yield select(selectBrokerManagersCurrentPage);
        const searchValue = yield select(selectBrokerManagersSearchValue);

        const result = yield call(brokerManagers.get, {
            intermedEnterpriseNumber: encodeURIComponent(searchValue),
            page: currentPage,
            size: DEFAULT_TABLE_SIZE,
        });

        const brokerManagerData = get(result, '_embedded.brokerManagers', []);
        const pageInfo = get(result, 'page');

        yield put(setBrokerManagersPageInfo.action(pageInfo));

        yield put(fetchBrokerManagersList.success(brokerManagerData));
    } catch (err) {
        yield put(fetchBrokerManagersList.failure(err));
    }
}

export function* searchBrokerManagersListSaga() {
    yield put(setBrokerManagersCurrentPage.clear());
    yield put(fetchBrokerManagersList.request());
}

export function* setBrokerManagersCurrentPageSaga() {
    yield put(fetchBrokerManagersList.request());
}

export const brokerManagerListSagas = function* () {
    yield all([takeLatest(clearBrokerManagersData.ACTION, clearBrokerManagersDataSaga)]);
    yield all([takeLatest(fetchBrokerManagersData.ACTION, fetchBrokerManagersDataSaga)]);
    yield all([takeLatest(fetchBrokerManagersList.REQUEST, fetchBrokerManagersListSaga)]);
    yield all([takeLatest(searchBrokerManagersList.ACTION, searchBrokerManagersListSaga)]);
    yield all([takeLatest(setBrokerManagersCurrentPage.ACTION, setBrokerManagersCurrentPageSaga)]);
};
