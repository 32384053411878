import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {injectIntl, intlShape} from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import {TextField} from 'components/common/Form';
import {requiredText, requiredEmail, firstNameField, surnameField} from 'core/fields';
import {OverflowTooltip} from 'components/common/OverflowTooltip';
import './styles.scss';

export class BrokerManagerEditComponent extends React.PureComponent {
    static propTypes = {
        intl: intlShape,
        initialValues: PropTypes.shape({
            firstName: PropTypes.string,
            surname: PropTypes.string,
            email: PropTypes.string,
            companyName: PropTypes.string,
        }),
    };

    render() {
        const {intl, initialValues} = this.props;

        if (!initialValues) {
            return null;
        }

        return (
            <div className="broker-manager-edit-form">
                <Row>
                    <Col xs={6}>
                        <OverflowTooltip tooltip={initialValues.email}>
                            <Field
                                name="email"
                                label={intl.formatMessage({
                                    id: 'fields.brokerManagerEdit.email',
                                })}
                                component={TextField}
                                {...requiredEmail}
                                disabled
                            />
                        </OverflowTooltip>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="firstName"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.firstName',
                            })}
                            component={TextField}
                            {...firstNameField}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Field
                            name="surname"
                            label={intl.formatMessage({
                                id: 'fields.brokerManagerEdit.surname',
                            })}
                            component={TextField}
                            {...surnameField}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <OverflowTooltip tooltip={initialValues.companyName}>
                            <Field
                                name="companyName"
                                component={TextField}
                                label={intl.formatMessage({
                                    id: 'fields.brokerManagerEdit.companyName.label',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'fields.brokerManagerEdit.companyName.placeholder',
                                })}
                                disabled
                                {...requiredText}
                            />
                        </OverflowTooltip>
                    </Col>
                </Row>
            </div>
        );
    }
}

export const BrokerManagerEdit = injectIntl(BrokerManagerEditComponent);
