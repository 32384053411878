import React from 'react';
import {toast} from 'react-toastify';
import closeIconGreen from 'assets/images/close-icon-green.svg';
import closeIconRed from 'assets/images/close-icon-red.svg';
import successToastIcon from 'assets/images/success-toast-status.svg';

import {Notification} from 'components/common/Notification';
import {NotificationMessage} from 'components/common/NotificationMessage';

export const TOAST_SUCCESS_TIME = 7000;

export const CloseButton = ({type = 'success', closeToast}) => (
    <button className="Toastify__close-button" onClick={closeToast}>
        <img src={type === 'error' ? closeIconRed : closeIconGreen} alt="X" />
    </button>
);

export class ToastHandler {
    onSuccess(link, label, messageLocalizationKey) {
        return this.getSuccessToast(
            <Notification
                className="toast-icon-success"
                altMessageKey="notifications.imageAltMessages.successStatus"
                toastIcon={successToastIcon}
            >
                <NotificationMessage
                    messageLocalizationKey={messageLocalizationKey}
                    label={label}
                    link={link}
                />
            </Notification>
        );
    }

    getSuccessToast(Component) {
        return toast.success(Component, {
            className: 'async-success',
            autoClose: TOAST_SUCCESS_TIME,
            closeButton: <CloseButton />,
        });
    }
}

export const toastHandler = new ToastHandler();
