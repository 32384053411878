import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {reducer as formReducer} from 'redux-form';
import createSagaMiddleware from 'redux-saga';

import {containerId as appContainerId, appReducer, appSagas} from 'features/App';
import {
    containerId as addBrokerManagerContainerId,
    BrokerManagerCreateReducer,
    brokerManagerCreateSagas,
} from 'features/BrokerManager/BrokerManagerCreate';
import {
    containerId as editBrokerManagerContainerId,
    BrokerManagerEditReducer,
    brokerManagerEditSagas,
} from 'features/BrokerManager/BrokerManagerEdit';
import {containerId as modalReducerContainerId, ModalReducer} from 'features/common/Modal/reducer';

import {
    containerId as brokerManagerListContainerId,
    BrokerManagerListReducer,
    brokerManagerListSagas,
} from 'features/BrokerManager/BrokerManagerList';

import {
    containerId as brokerManagerDetailsContainerId,
    BrokerManagerDetailsReducer,
    brokerManagerDetailsSagas,
} from 'features/BrokerManager/BrokerManagerDetails';

import {
    containerId as agentListContainerId,
    AgentListReducer,
    agentListSagas,
} from 'features/Agent/AgentList';

import {
    AgentCreateReducer,
    agentCreateSagas,
    containerId as addAgentContainerId,
} from 'features/Agent/AgentCreate';

import {containerId as agentContainerId, AgentReducer, agentSagas} from 'features/Agent/common';

import {
    containerId as agentDetailsContainerId,
    AgentDetailsReducer,
    agentDetailsSagas,
} from 'features/Agent/AgentDetails';

import {
    containerId as errorBoundaryContainerId,
    errorBoundaryReducer,
} from 'features/ErrorBoundary';
import {serverErrorHandlerMiddleware} from './serverErrorHandlerMiddleware';

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const rootReducer = combineReducers({
    form: formReducer,
    [modalReducerContainerId]: ModalReducer,
    [appContainerId]: appReducer,
    [addBrokerManagerContainerId]: BrokerManagerCreateReducer,
    [brokerManagerListContainerId]: BrokerManagerListReducer,
    [brokerManagerDetailsContainerId]: BrokerManagerDetailsReducer,
    [editBrokerManagerContainerId]: BrokerManagerEditReducer,
    [agentListContainerId]: AgentListReducer,
    [addAgentContainerId]: AgentCreateReducer,
    [agentContainerId]: AgentReducer,
    [agentDetailsContainerId]: AgentDetailsReducer,
    [errorBoundaryContainerId]: errorBoundaryReducer,
});

const sagaMiddleware = createSagaMiddleware();

const sagas = [
    appSagas,
    brokerManagerCreateSagas,
    brokerManagerEditSagas,
    brokerManagerListSagas,
    brokerManagerDetailsSagas,
    agentListSagas,
    agentCreateSagas,
    agentSagas,
    agentDetailsSagas,
];

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(serverErrorHandlerMiddleware, sagaMiddleware))
);

sagas.forEach(saga => sagaMiddleware.run(saga));
