import {connectWithIntl} from 'core/services/connectWithIntl';
import {TableSearch} from 'components/common/Table/TableSearch/TableSearch';

import {
    makeSelectBrokerManagersShowSearchResults,
    makeSelectShowSearchField,
    selectBrokerManagersSearchValue,
    selectBrokerManagersTotalItemsCount,
    selectShowCrossButton,
    selectIsLoading,
} from '../../selectors';
import {searchBrokerManagersList, setBrokerManagersSearchValue} from '../../reducer';

const mapStateToProps = () => {
    const selectBrokerManagersShowSearchResults = makeSelectBrokerManagersShowSearchResults();
    const selectShowSearchField = makeSelectShowSearchField();

    return state => ({
        value: selectBrokerManagersSearchValue(state),
        results: selectBrokerManagersTotalItemsCount(state),
        loading: selectIsLoading(state),
        showResults: selectBrokerManagersShowSearchResults(state),
        showSearchField: selectShowSearchField(state),
        showCrossButton: selectShowCrossButton(state),
    });
};

export const BrokerManagerListSearch = connectWithIntl(mapStateToProps, {
    onChange: setBrokerManagersSearchValue.action,
    onSubmit: searchBrokerManagersList.action,
})(TableSearch);
