import React from 'react';
import PropTypes from 'prop-types';

import {Modal} from 'features/common/Modal';
import {FormattedMessage} from 'react-intl';
import {makeSelectIsActive} from 'features/BrokerManager/BrokerManagerEdit/selectors';
import {ActionButton} from 'components/common/ActionButton';
import {changeStatusBrokerManager} from 'features/BrokerManager/BrokerManagerEdit/reducer';
import {connectWithIntl} from 'core/services/connectWithIntl';

export const BROKER_MANAGER_EDIT_STATUS_MODAL = 'BrokerManagerEditStatusModal';

export class BrokerManagerEditStatusModalComponent extends React.PureComponent {
    static propTypes = {
        isActive: PropTypes.bool,
        onChangeStatus: PropTypes.func,
    };

    render() {
        return (
            <Modal
                modalId={BROKER_MANAGER_EDIT_STATUS_MODAL}
                actionsPanel={this.resolveActionsPanel()}
            >
                <FormattedMessage id={this.resolveMessageId()} />
            </Modal>
        );
    }

    resolveMessageId = () => {
        const {isActive} = this.props;
        return `brokerManagerEdit.${isActive ? 'deactivateQuestion' : 'activateQuestion'}`;
    };

    resolveActionsPanel = () => {
        const {isActive, onChangeStatus} = this.props;

        const actionsMessageId = isActive
            ? 'brokerManagerEdit.deactivate'
            : 'brokerManagerEdit.activate';

        const activeClassName = isActive ? 'warning' : 'success';

        return (
            <ActionButton onClick={onChangeStatus} className={activeClassName}>
                <FormattedMessage id={actionsMessageId} />
            </ActionButton>
        );
    };
}

const mapStateToProps = () => {
    const selectIsActive = makeSelectIsActive();

    return state => ({
        isActive: selectIsActive(state),
    });
};

export const BrokerManagerEditStatusModal = connectWithIntl(mapStateToProps, {
    onChangeStatus: changeStatusBrokerManager.request,
})(BrokerManagerEditStatusModalComponent);
