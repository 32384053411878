import {arrayPush, arrayRemove, change, initialize, getFormSyncErrors} from 'redux-form';
import {all, takeLatest, call, put, select, take} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import {agents, user} from 'core/services/apiClient';
import {transformProducerAccountNumbers, parsePhoneNumbers} from 'core/services/utils';
import {
    createCheckFormErrorsSaga,
    clearActionsStateSaga,
    createSubmitFormIfValidSaga,
} from 'core/sagaHelpers';
import {
    fetchProducerAccounts,
    clearAgentData,
    agentsActions,
    addProducerAccount,
    removeProducerAccount,
    showRemoveProducerAccountModal,
    changeProducerAccount,
    containerId,
    fetchAgentData,
    saveAgentData,
    removeAgent,
    changeAgentStatus,
    showRemoveModal,
    showChangeStatusModal,
    initializeAgentData,
    triggerAgentIsActive,
    saveDentAgentData,
    setProducerAccountFormErrors,
    handleSaveAgent,
    handleSaveDentAgent,
} from 'features/Agent/common/reducer';

import {
    selectChosenProducerAccountIndex,
    selectAgentId,
    selectAndPrepareFormData,
    selectChangeStatusRequestBody,
    makeSelectInitialValues,
    selectAgentIsActive,
    selectAndPrepareDentFormData,
    selectProducerAccountsFormValues,
    selectProducerAccountsFormErrors,
} from 'features/Agent/common/selectors';
import {hideModal, showModal} from 'features/common/Modal';
import {history} from 'core/services/history';
import {required} from 'core/services/validators';

import {AGENT_CREATE_REMOVE_MODAL} from 'features/Agent/common/components/ProducerAccountRemoveModal';
import {
    AGENT_EDIT_REMOVE_MODAL,
    AGENT_EDIT_STATUS_MODAL,
} from 'features/Agent/AgentEdit/components';
import {selectUserIntermed} from 'features/App';

export function* fetchProducerAccountsSaga() {
    try {
        const intermed = yield select(selectUserIntermed);
        const response = yield call(user.getProducerAccounts);
        const mappedProducerAccountNumbers = transformProducerAccountNumbers(response, intermed);
        yield put(fetchProducerAccounts.success(mappedProducerAccountNumbers));
    } catch (error) {
        yield put(fetchProducerAccounts.failure(error));
    }
}

export function* clearAgentDataSaga() {
    yield call(clearActionsStateSaga, agentsActions);
}

export const createProducerAccount = () => ({producerAccountId: null, roles: []});

export function* addProducerAccountSaga() {
    const errors = yield select(selectProducerAccountsFormErrors);
    yield put(arrayPush(containerId, 'producerAccounts', createProducerAccount()));
    yield put(setProducerAccountFormErrors.action(errors));
}

export function* removeProducerAccountSaga() {
    const index = yield select(selectChosenProducerAccountIndex);
    yield put(arrayRemove(containerId, 'producerAccounts', index));
    yield call(removeProducerAccountFormErrorSaga, index);
    yield put(hideModal.action(AGENT_CREATE_REMOVE_MODAL));
}

export function* changeProducerAccountFormErrorSaga(index) {
    const errors = yield select(selectProducerAccountsFormErrors);
    const updatedErrors = errors.map((item, indexItem) => (indexItem === index ? null : item));
    yield put(setProducerAccountFormErrors.action(updatedErrors));
}

export function* removeProducerAccountFormErrorSaga(index) {
    const errors = yield select(selectProducerAccountsFormErrors);
    const updatedErrors = errors.filter((error, errorIndex) => errorIndex !== index);
    yield put(setProducerAccountFormErrors.action(updatedErrors));
}

export function* showRemoveProducerAccountModalSaga() {
    yield put(showModal.action(AGENT_CREATE_REMOVE_MODAL));
}

export function* changeProducerAccountSaga({payload: {path, index}}) {
    yield put(change(containerId, `${path}.roles`, []));
    yield call(changeProducerAccountFormErrorSaga, index);
}

export const transformAgentNumbers = response => {
    const {mobileNumber, contactNumber} = response;
    const {
        phoneNumberCode: contactNumberCode,
        phoneNumber: modifiedContactNumber,
    } = parsePhoneNumbers(contactNumber);
    const {
        phoneNumberCode: mobileNumberCode,
        phoneNumber: modifiedMobileNumber,
    } = parsePhoneNumbers(mobileNumber);

    return {
        contactNumberCode,
        contactNumber: modifiedContactNumber,
        mobileNumberCode,
        mobileNumber: modifiedMobileNumber,
    };
};

export const transformAgentProducerAccountsResponse = response => {
    const agentNumbers = transformAgentNumbers(response);
    return {
        ...response,
        ...agentNumbers,
        producerAccounts: response.producerAccounts.map(producerAccount => ({
            producerAccountId: producerAccount.producerAccount.id,
            roles: producerAccount.roles,
        })),
    };
};

function* fetchAgentDataSaga({payload: id}) {
    try {
        const response = yield call(agents.getAgent, id);
        const agentData = transformAgentProducerAccountsResponse(response);
        yield put(fetchAgentData.success(agentData));
    } catch (err) {
        yield put(fetchAgentData.failure(err));
    }
}

function* saveAgentDataErrorCondition(formValidationError) {
    const producerAccountsErrors = yield select(selectProducerAccountsFormErrors);

    const isProducerAccountsErrorExist = producerAccountsErrors.some(error => !isEmpty(error));
    return !isEmpty(formValidationError) || isProducerAccountsErrorExist;
}

export const saveAgentDataCheckErrorSaga = createCheckFormErrorsSaga(
    {getFormSyncErrors},
    saveAgentDataErrorCondition
);

export function* checkProducerAccountValidationErrorSaga() {
    const producerAccount = yield select(selectProducerAccountsFormValues);
    const errors = producerAccount.map(producerAccount => {
        return required(producerAccount.producerAccountId);
    });
    yield put(setProducerAccountFormErrors.action(errors));
}

export const submitAgentIfValidSaga = createSubmitFormIfValidSaga({
    checkFormErrorsSaga: saveAgentDataCheckErrorSaga,
});

export function* saveAgentDataSaga() {
    yield call(checkProducerAccountValidationErrorSaga);
    yield call(submitAgentIfValidSaga, containerId, sendAgentToBackendSaga);
}

export function* sendAgentToBackendSaga() {
    try {
        const agentId = yield select(selectAgentId);
        const requestBody = yield select(selectAndPrepareFormData);
        yield put(saveAgentData.request(requestBody));
        yield call(agents.edit, agentId, requestBody);
        yield put(saveAgentData.success());
        history.goToAgentDetails(agentId);
    } catch (err) {
        yield put(saveAgentData.failure(err));
    }
}

export function* sendDentAgentToBackend() {
    try {
        const agentId = yield select(selectAgentId);
        const requestBody = yield select(selectAndPrepareDentFormData);
        yield put(saveDentAgentData.request(requestBody));
        yield call(agents.updateProducerAccounts, agentId, requestBody);
        yield put(saveDentAgentData.success());
        history.goToAgentDetails(agentId);
    } catch (err) {
        yield put(saveDentAgentData.failure(err));
    }
}

export function* saveDentAgentDataSaga() {
    yield call(checkProducerAccountValidationErrorSaga);
    yield call(submitAgentIfValidSaga, containerId, sendDentAgentToBackend);
}

export function* removeAgentSaga() {
    try {
        const agentId = yield select(selectAgentId);
        yield put(hideModal.action(AGENT_EDIT_REMOVE_MODAL));
        yield call(agents.remove, agentId);
        yield put(removeAgent.success());
        history.goToAgents();
    } catch (err) {
        yield put(removeAgent.failure(err));
    }
}

export function* showRemoveModalSaga() {
    yield put(showModal.action(AGENT_EDIT_REMOVE_MODAL));
}

export function* changeAgentStatusSaga() {
    try {
        yield put(hideModal.action(AGENT_EDIT_STATUS_MODAL));

        const agentId = yield select(selectAgentId);
        const requestBody = yield select(selectChangeStatusRequestBody);
        yield call(agents.status, agentId, requestBody);

        yield put(changeAgentStatus.success());

        yield put(triggerAgentIsActive.action());

        const value = yield select(selectAgentIsActive);
        yield put(change(containerId, 'status', value));
    } catch (err) {
        yield put(changeAgentStatus.failure(err));
    }
}

export function* showChangeStatusModalSaga() {
    yield put(showModal.action(AGENT_EDIT_STATUS_MODAL));
}

export const createInitializeDataSaga = ({selectInitialValues}) =>
    function* ({payload: id}) {
        yield all([
            put(fetchAgentData.request(id)),
            put(fetchProducerAccounts.request()),
            take(fetchAgentData.SUCCESS),
            take(fetchProducerAccounts.SUCCESS),
        ]);
        const initialValues = yield select(selectInitialValues);
        yield put(initialize(containerId, initialValues));
    };

export const initializeDataSaga = createInitializeDataSaga({
    selectInitialValues: makeSelectInitialValues(),
});

export const agentSagas = function* () {
    yield all([
        takeLatest(fetchProducerAccounts.REQUEST, fetchProducerAccountsSaga),
        takeLatest(fetchAgentData.REQUEST, fetchAgentDataSaga),
        takeLatest(handleSaveAgent.ACTION, saveAgentDataSaga),
        takeLatest(removeAgent.REQUEST, removeAgentSaga),
        takeLatest(changeAgentStatus.REQUEST, changeAgentStatusSaga),
        takeLatest(handleSaveDentAgent.ACTION, saveDentAgentDataSaga),

        takeLatest(clearAgentData.ACTION, clearAgentDataSaga),
        takeLatest(addProducerAccount.ACTION, addProducerAccountSaga),
        takeLatest(removeProducerAccount.ACTION, removeProducerAccountSaga),
        takeLatest(showRemoveProducerAccountModal.ACTION, showRemoveProducerAccountModalSaga),
        takeLatest(changeProducerAccount.ACTION, changeProducerAccountSaga),
        takeLatest(showRemoveModal.ACTION, showRemoveModalSaga),
        takeLatest(showChangeStatusModal.ACTION, showChangeStatusModalSaga),

        takeLatest(initializeAgentData.ACTION, initializeDataSaga),
    ]);
};
